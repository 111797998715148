import React from 'react';
import { FormulasProvider } from '~/components/Formulas/context/FormulasContext';
import FinancialModelTable from './components/FinancialModelTable';
import FinancialModelHeader from './components/FinancialModelHeader';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';

const FinancialModelPage = (): React.ReactElement => {
  return (
    <FormulasProvider mode={IFormulaTypeEnum.ModelBuilder}>
      <div className="w-full bg-neutral-15 h-screen">
        <FinancialModelHeader />
        <FinancialModelTable />
      </div>
    </FormulasProvider>
  );
};

export default FinancialModelPage;
