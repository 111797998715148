import { IStringDate } from './types';

export const differenceInCalendarMonths = ({
  startDate,
  endDate,
}: {
  startDate: IStringDate;
  endDate: IStringDate;
}): number => {
  const startYear = Number(startDate.split('-')[0]);
  const startMonth = Number(startDate.split('-')[1]);
  const endYear = Number(endDate.split('-')[0]);
  const endMonth = Number(endDate.split('-')[1]);
  return (endYear - startYear) * 12 + (endMonth - startMonth);
};
