import { getLastDayOfMonth } from './getLastDayOfMonth';
import { subtractDays } from './subtractDays';
import { IStringDate } from './types';

export const addDays = (date: IStringDate, days: number): IStringDate => {
  let [year, month, day] = date.split('-').map(Number);
  let remainingDays = days;

  if (remainingDays < 0) {
    return subtractDays(date, Math.abs(remainingDays));
  }

  while (remainingDays > 0) {
    const lastDayOfMonth = Number(
      getLastDayOfMonth(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`),
    );
    const daysLeftInMonth = lastDayOfMonth - day;
    if (remainingDays > daysLeftInMonth) {
      remainingDays -= daysLeftInMonth + 1;
      day = 1;
      if (month === 12) {
        month = 1;
        year += 1;
      } else {
        month += 1;
      }
    } else {
      day += remainingDays;
      remainingDays = 0;
    }
  }

  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};
