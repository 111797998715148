import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import authReducer, { AuthState } from './authSlice';
import organizationReducer, { IOrganizationState } from './organizationSlice';
import userReducer, { UserState } from './userSlice';
import scenarioReducer, { ScenarioState } from './scenarioSlice';
import settingsReducer, { SettingsState } from './settingsSlice';
import hydrationReducer, { HydrationState } from './hydrationSlice';
import socketReducer, { SocketState } from './socketSlice';
import createFilter from 'redux-persist-transform-filter';
import integrationsReducer, { IIntegrationState } from './integrationsSlice';

const settingsFilter = createFilter('settings', [
  'hideSensitiveData',
  'isInteractiveSectionCollapsed',
  'collapseInteractiveSection',
]);

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['scenario', 'settings'],
  blacklist: ['_sockets', '_hydration'],
  transforms: [settingsFilter],
};

const rootReducer = combineReducers({
  _sockets: socketReducer,
  _hydration: hydrationReducer,
  auth: authReducer,
  user: userReducer,
  organization: organizationReducer,
  scenario: scenarioReducer,
  settings: settingsReducer,
  integrations: integrationsReducer,
});

const persistedReducer = persistReducer<State>(persistConfig, rootReducer);

export interface State {
  _sockets: SocketState;
  _hydration: HydrationState;
  auth: AuthState;
  user: UserState;
  organization: IOrganizationState;
  scenario: ScenarioState;
  settings: SettingsState;
  integrations: IIntegrationState;
}

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', '_socket/setPusher'],
        ignoredPaths: ['_sockets.pusher'],
      },
    }),
});

export const persistor = persistStore(store);

let previousHydratedState = store.getState()._hydration.hydrated;

store.subscribe(() => {
  const currentHydratedState = store.getState()._hydration.hydrated;
  if (!previousHydratedState && currentHydratedState) {
    window.dispatchEvent(new Event('reduxHydrated'));
  }
  previousHydratedState = currentHydratedState;
});

export type AppDispatch = typeof store.dispatch;

export default store;
