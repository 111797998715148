import { addMonths } from './addMonths';
import { endOfMonth } from './endOfMonth';
import { getLastDayOfMonth } from './getLastDayOfMonth';
import { isAfter } from './isAfter';
import { isBefore } from './isBefore';
import { subtractMonths } from './subtractMonths';
import { IStringDate } from './types';

export const createMonthArrayBetweenDates = ({
  startDate,
  endDate,
}: {
  startDate: IStringDate;
  endDate: IStringDate;
}): IStringDate[] => {
  endDate = endOfMonth(endDate);
  const goForward = isAfter({ dateToCheck: endDate, comparison: startDate });

  const startYear = startDate.split('-')[0];
  const startMonth = startDate.split('-')[1];
  const array: IStringDate[] = [`${startYear}-${startMonth}-${getLastDayOfMonth(startDate)}`];

  if (goForward) {
    while (isBefore({ dateToCheck: array[array.length - 1], comparison: endDate })) {
      const lastDate = array[array.length - 1];
      const nextMonth = addMonths(lastDate, 1);
      array.push(`${nextMonth.split('-')[0]}-${nextMonth.split('-')[1]}-${getLastDayOfMonth(nextMonth)}`);
    }
  } else {
    while (isAfter({ dateToCheck: array[array.length - 1], comparison: endDate })) {
      const lastDate = array[array.length - 1];
      const nextMonth = subtractMonths(lastDate, 1);
      array.push(`${nextMonth.split('-')[0]}-${nextMonth.split('-')[1]}-${getLastDayOfMonth(nextMonth)}`);
    }
  }

  return array;
};
