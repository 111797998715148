import { type IPositionDetails } from '~/pages/Headcount/entity/types';
import { IExpense } from '../components/types';
import * as stringDate from '~/utils/stringDate';
import { IStringDate } from '~/utils/stringDate/types';

export const getActivePositionsInMonthPerExpense = ({
  positions,
  month,
  isOnHire,
  expense,
}: {
  positions: IPositionDetails[];
  month: IStringDate;
  isOnHire?: boolean;
  expense: IExpense;
}): IPositionDetails[] => {
  const validDepartments = expense.context.departments;
  return positions.filter((position) => {
    const positionDepartment = position.departmentUuid;
    let isValidDepartment = false;
    if (typeof positionDepartment === 'string') {
      isValidDepartment = validDepartments.includes(positionDepartment) || validDepartments.includes('ALL');
    }
    if (isOnHire) {
      return (
        stringDate.isSameMonth({
          date1: month,
          date2: position.hireDate,
        }) && isValidDepartment
      );
    }

    return (
      stringDate.isSameMonthOrAfter({
        comparison: position.hireDate,
        dateToCheck: month,
      }) &&
      (!position.termDate ||
        stringDate.isBefore({
          comparison: month,
          dateToCheck: position.termDate,
        })) &&
      isValidDepartment
    );
  });
};
