import { IDataArrayDictionary, ILineProps } from '~/components/LineGraph/entity/types';
import { IDepartment } from '~/pages/Settings/Departments/entity/types';
import { IPositionDetails } from '../entity/types';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

interface GraphData {
  departments: {
    data: IDataArrayDictionary[];
  };
  total: {
    data: IDataArrayDictionary[];
  };
  departmentLines: ILineProps[];
}

const getLineStroke = (index: number): string => {
  switch (index) {
    case 0:
      return '#45A59F';
    case 1:
      return '#5A8496';
    case 2:
      return '#8A6190';
    case 3:
      return '#EBA61E';
    case 4:
      return '#C163B8';
    default:
      return '#64755C';
  }
};

export const createHeadcountGraphData = ({
  startDate,
  endDate,
  departments,
  positions,
  departmentFilter,
  search,
}: {
  startDate: IStringDate;
  endDate: IStringDate;
  departments: IDepartment[];
  positions: IPositionDetails[];
  departmentFilter: string | null;
  search: string | null;
}): GraphData => {
  const dateRangeArr = stringDate.createMonthArrayBetweenDates({ startDate, endDate });

  if (departmentFilter && departmentFilter !== 'all') {
    departments = departments.filter((department) => department.departmentUuid === departmentFilter);
  }

  const totalArray: IDataArrayDictionary[] = [];
  let departmentArray: IDataArrayDictionary[] = [];

  const departmentNames = departments.map((dept) => dept.name);

  dateRangeArr.forEach((date) => {
    let total = 0;
    const departmentDict: Record<string, number> = {
      date: new Date(date).getTime(),
    };

    departmentNames.forEach((name) => {
      departmentDict[name] = 0;
    });

    positions
      .filter(
        (position) => position.currentDepartment.departmentUuid === departmentFilter || departmentFilter === 'all',
      )
      .filter((position) => {
        if (!search) return true;
        const employeeNameMatch = position.employeeName?.toLowerCase().includes(search.toLowerCase());
        const titleMatch = position.title.toLowerCase().includes(search.toLowerCase());
        return employeeNameMatch || titleMatch;
      })
      .forEach((position) => {
        // const termDate = position.termDate ? toZonedTime(position.termDate, 'UTC') : null;
        if (
          stringDate.isSameMonthOrBefore({ dateToCheck: position.hireDate, comparison: date }) &&
          (!position.termDate || stringDate.isSameMonthOrBefore({ comparison: position.termDate, dateToCheck: date }))
        ) {
          total += 1;

          const departmentName = position.currentDepartment.name;

          if (departmentName) {
            departmentDict[departmentName] += 1;
          }
        }
      });

    totalArray.push({ date: new Date(date).getTime(), total });
    departmentArray.push(departmentDict);
  });

  let departmentLines: ILineProps[] = [];

  if (departments.length > 5) {
    const lastMonthData = departmentArray[departmentArray.length - 1];
    const topFourEndingHeadcount = Object.keys(lastMonthData)
      .filter((key) => key !== 'date')
      .sort((a, b) => {
        const numB = lastMonthData[b] ?? 0;
        const numA = lastMonthData[a] ?? 0;
        return numB - numA;
      })
      .slice(0, 4);
    departmentArray = departmentArray.map((data) => {
      const newData: IDataArrayDictionary = { date: data.date };
      let otherCount = 0;
      Object.keys(data).forEach((key) => {
        if (key !== 'date' && !topFourEndingHeadcount.includes(key)) {
          otherCount += data[key] ?? 0;
        } else {
          newData[key] = data[key];
        }
      });
      newData['Other'] = otherCount;
      return newData;
    });
    departmentLines = [
      ...topFourEndingHeadcount.map((name, index) => ({
        dataKey: name,
        stroke: getLineStroke(index),
      })),
      { dataKey: 'Other', stroke: getLineStroke(4) },
    ];
  } else {
    departmentLines = departments.map((department, index) => ({
      dataKey: department.name,
      stroke: departments.length === 1 ? '#64755C' : getLineStroke(index),
    }));
  }

  return {
    departments: {
      data: departmentArray,
    },
    total: {
      data: totalArray,
    },
    departmentLines,
  };
};
