import React, { useContext } from 'react';
import Typography from '~/components/Typography';
import { ChatContext } from '../../context/ChatContext';

const Item = ({ title, conversationUuid }: { title: string; conversationUuid: string }): React.ReactElement => {
  const { selectedConversationUuid, setSelectedConversationUuid } = useContext(ChatContext);

  return (
    <div
      className={`pl-2 pr-3 py-2 hover:bg-green-15 border border-1 border-transparent hover:border-green-50 rounded-lg cursor-pointer w-full ${
        selectedConversationUuid === conversationUuid && 'bg-green-15 !border-green-50'
      }`}
      onClick={() => setSelectedConversationUuid(conversationUuid)}
    >
      <Typography size="sm" className="truncate block w-full">
        {title}
      </Typography>
    </div>
  );
};

export default Item;
