import React, { useRef, useState, useEffect } from 'react';
import { IFormulaType } from '~/components/Formulas/context/types';
import ColumnResize from '~/components/Formulas/FormulasTable/ColumnResize';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import FormulaNode from './FormulaNode';
import {
  ICalculationTypeEnum,
  IFormulaTypeEnum,
  IRecipe,
  IRecipeVariables,
  ITimeModifierFunctionEnum,
  IVariableTypeEnum,
} from '~/services/parallel/formulas.types';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import useTableContext from '~/components/Formulas/FormulasTable/hooks/useTableContext';
import validateFormula from './utils/validateFormula';
import ErrorPopover from './ErrorPopover';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IAPIResponse } from '~/utils/types';
import { isEqual } from 'lodash';
import { Extension } from '@tiptap/core';
import Autocomplete from './Autocomplete';
import { SelectType } from '~/components/Select/Select.types';
import Typography from '~/components/Typography';

const DisableEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: (): boolean => true,
    };
  },
});

interface IProps {
  rowIndex: number;
  columnWidth: number;
  data: IFormulaType;
  columnIndex: number;
  viewOnly?: boolean;
}

interface IFormulaNodeAttributes {
  label: string;
  type: string;
  formulaUuid: string;
  timeModifier: string;
  calculationModifier: string;
  calculationModifierType: string;
}

interface IFormulaNode {
  type: 'formulaNode';
  attrs: IFormulaNodeAttributes;
}

interface ITextNode {
  type: 'text';
  text: string;
}

type IEditorContent = IFormulaNode | ITextNode;

const calculationTypeToTitleMap: Record<ICalculationTypeEnum, string> = {
  [ICalculationTypeEnum.HeadcountNumber]: 'Headcount',
  [ICalculationTypeEnum.NewHireNumber]: 'New Hires',
  [ICalculationTypeEnum.SalariesAndWages]: 'Salaries and Wages',
  [ICalculationTypeEnum.SoftwareExpenses]: 'Software Expenses',
  [ICalculationTypeEnum.OtherExpenses]: 'Other Expenses',
  [ICalculationTypeEnum.PeopleFacilities]: 'People & Facilities Expenses',
  [ICalculationTypeEnum.COGS]: 'Cost of Goods Sold Expenses',
  [ICalculationTypeEnum.Marketing]: 'Marketing Expenses',
  [ICalculationTypeEnum.DepartmentExpenses]: 'Department Expenses',
};

const ModelBuilderExpression = ({
  columnWidth,
  columnIndex,
  data,
  viewOnly: componentLevelViewOnly,
}: IProps): React.ReactElement => {
  const isPulling = useSelector((state: State) => state.integrations.isPulling);
  const { formulaDictionary, setFormulaUuidToFocus, formulaUuidToFocus, refreshData, viewOnly } = useFormulaContext();
  const isViewOnly = Boolean(viewOnly || isPulling || componentLevelViewOnly);
  const { columnWidths } = useTableContext();
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const isValid = useRef(true);
  const [errorMessage, setErrorMessage] = useState('');
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const sideMenuExpanded = useSelector((state: State) => state.user.preferences.sideMenuExpanded);
  const [searchValue, setSearchValue] = useState('');
  const [autocompletePosition, setAutocompletePosition] = useState({ top: 0, left: 0 });
  const [isAutocompleteHovered, setIsAutocompleteHovered] = useState(false);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const DEFAULT_EDITOR_HEIGHT = 48;
  const [editorContainerHeight, setEditorContainerHeight] = useState(DEFAULT_EDITOR_HEIGHT);

  const buildContentFromRecipe = (recipe: IRecipe): string => {
    const expressionWithoutSpaces = recipe.expression.replace(/\s/g, '');
    const replacedExpression = expressionWithoutSpaces.replace(/([+\-*/()=])/g, ' $1').replace(/\$\d+/g, (match) => {
      const variable = match.slice(1);
      let variableName = variable;
      const formulaUuid = recipe.variables[`$${variable}`].formulaUuid;

      const variableType = recipe.variables[`$${variable}`].type;
      switch (variableType) {
        case IVariableTypeEnum.Constant:
          variableName = recipe.variables[`$${variable}`].constantValue?.toString() ?? '';
          return ` ${variableName}`;
        case IVariableTypeEnum.Formula: {
          const formulaUuid = recipe.variables[`$${variable}`].formulaUuid;
          if (formulaUuid && formulaUuid in formulaDictionary) {
            const matchedFormula = formulaDictionary[formulaUuid];
            variableName = matchedFormula.recipe.name;
          }
          const timeModifier = recipe.variables[`$${variable}`].timeModifier;
          const timeModifierString =
            Object.keys(timeModifier).length === 0 ? 'previous-0' : `${timeModifier.function}-${timeModifier.period}`;

          return ` <formula-node data-type="${variableType}" data-label="${variableName}" data-formula-uuid="${formulaUuid}" data-time-modifier="${timeModifierString}"></formula-node>`;
        }
        case IVariableTypeEnum.Self: {
          variableName = recipe.name;
          const timeModifier = recipe.variables[`$${variable}`].timeModifier;
          const timeModifierString =
            Object.keys(timeModifier).length === 0 ? 'previous-0' : `${timeModifier.function}-${timeModifier.period}`;
          return ` <formula-node data-type="${variableType}" data-label="${variableName}" data-formula-uuid="self" data-time-modifier="${timeModifierString}"></formula-node>`;
        }
        case IVariableTypeEnum.Calculated: {
          const calculationType = recipe.variables[`$${variable}`].calculationType;
          variableName = calculationType ?? '';

          let calculationModifierString = '';
          let calculationModifierType = '';
          if (recipe.variables[`$${variable}`].calculationModifier?.jobTitle) {
            calculationModifierString = recipe.variables[`$${variable}`].calculationModifier?.jobTitle ?? '';
            calculationModifierType = 'jobTitle';
          } else if (recipe.variables[`$${variable}`].calculationModifier?.departmentUuids) {
            calculationModifierString =
              recipe.variables[`$${variable}`].calculationModifier?.departmentUuids?.join(',') ?? '';
            calculationModifierType = 'departmentUuids';
          } else if (variableName === 'newHireNumber' || variableName === 'headcountNumber') {
            calculationModifierString = 'All';
            calculationModifierType = 'jobTitle';
          }
          return ` <formula-node data-type="${variableType}" data-label="${variableName}" data-formula-uuid="${formulaUuid}" data-calculation-modifier="${calculationModifierString}" data-calculation-modifier-type="${calculationModifierType}" data-view-only="${componentLevelViewOnly}"></formula-node>`;
        }
        default:
          return ` <span>${variableName}</span>`; // Return text directly
      }
    });

    return replacedExpression;
  };

  const initialContentRef = useRef<string>(buildContentFromRecipe(data.formula.recipe));

  const generateExpressionAndVariables = (
    content?: IEditorContent[],
  ): { expression: string; variables: IRecipeVariables } => {
    let expression = '';
    let variableCount = 1;
    const variables: IRecipeVariables = {};

    const getCalculationModifier = ({
      calculationModifier,
      calculationModifierType,
    }: {
      calculationModifier: string;
      calculationModifierType: string;
    }): { jobTitle?: string; departmentUuids?: string[] } => {
      if (calculationModifier === '' || calculationModifier === 'All') {
        return {};
      } else if (calculationModifierType === 'jobTitle') {
        return { jobTitle: calculationModifier };
      } else if (calculationModifierType === 'departmentUuids') {
        return { departmentUuids: calculationModifier.split(',') };
      }
      return {};
    };

    const getTimeModifier = (timeModifier: string): { function?: ITimeModifierFunctionEnum; period?: number } => {
      if (timeModifier === '') {
        return {};
      }
      if (timeModifier.split('-')[1] === '0') {
        return {};
      } else {
        return {
          function: timeModifier.split('-')[0] as ITimeModifierFunctionEnum,
          period: parseInt(timeModifier.split('-')[1]),
        };
      }
    };

    if (!content) return { expression: '', variables: {} };

    for (const item of content) {
      if (item.type === 'formulaNode') {
        expression += `$${variableCount}`;
        if (item.attrs.type === 'calculated') {
          const variableObject = {
            type: IVariableTypeEnum.Calculated,
            formulaUuid: null,
            calculationType: item.attrs.label as ICalculationTypeEnum,
            timeModifier: {},
            calculationModifier: getCalculationModifier({
              calculationModifier: item.attrs.calculationModifier,
              calculationModifierType: item.attrs.calculationModifierType,
            }),
            constantValue: null,
          };
          variables[`$${variableCount}`] = variableObject;
        } else if (item.attrs.type === 'self') {
          const variableObject = {
            type: IVariableTypeEnum.Self,
            formulaUuid: null,
            constantValue: null,
            timeModifier: getTimeModifier(item.attrs.timeModifier),
            calculationType: null,
            calculationModifier: {},
          };
          variables[`$${variableCount}`] = variableObject;
        } else {
          const variableObject = {
            type: IVariableTypeEnum.Formula,
            formulaUuid: item.attrs.formulaUuid,
            constantValue: null,
            timeModifier: getTimeModifier(item.attrs.timeModifier),
            calculationType: null,
            calculationModifier: {},
          };
          variables[`$${variableCount}`] = variableObject;
        }
        variableCount++;
      } else if (item.text) {
        const regex = /([-+]?\d*\.?\d+|[+\-*/()=])/g;
        let lastIndex = 0;
        let match;
        while ((match = regex.exec(item.text)) !== null) {
          if (match.index > lastIndex) {
            expression += item.text.slice(lastIndex, match.index);
          }
          const token = match[0];
          if (/^[-+]?\d*\.?\d+$/.test(token)) {
            expression += `$${variableCount}`;
            const variableObject = {
              type: IVariableTypeEnum.Constant,
              formulaUuid: null,
              constantValue: parseFloat(token),
              timeModifier: {},
              calculationType: null,
              calculationModifier: {},
            };
            variables[`$${variableCount}`] = variableObject;
            variableCount++;
          } else {
            expression += token;
          }
          lastIndex = regex.lastIndex;
        }
        if (lastIndex < item.text.length) {
          expression += item.text.slice(lastIndex);
        }
      }
    }

    // Remove leading = sign if present
    if (expression.startsWith('=')) {
      expression = expression.slice(1);
    }

    return { expression: expression.replace(/\s+/g, ' '), variables };
  };

  const isEditable = !isViewOnly;

  const editor = useEditor({
    editable: isEditable,
    enableInputRules: false,
    extensions: [
      StarterKit.configure({
        bold: false,
        italic: false,
      }),
      FormulaNode,
      DisableEnter,
    ],
    content: initialContentRef.current,
    editorProps: {
      attributes: {
        class: `pl-2 rounded w-full !whitespace-nowrap py-2 focus:outline-none focus:border border h-[48px] flex items-center overflow-x-auto no-scrollbar ${
          isEditable && 'hover:border-green'
        } ${isValid.current ? 'border-transparent focus:border-green' : 'border-red-500 focus:border-red-500'} ${isFocused && 'is-focused !h-fit !min-h-[48px]'}`,
      },
      handleClick: () => {
        setSearchValue('');
      },
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onUpdate: ({ editor }): void => {
      const cursorPos = editor.state.selection.from;
      const node = editor.state.doc.nodeAt(cursorPos - 1);
      const nodeText = node?.textContent;

      if (!nodeText || ['+', '-', '*', '/', '(', ')'].includes(nodeText)) {
        setSearchValue('');
        return;
      }

      // Get the text up until an operator before cursor position
      let lastText = '';
      const operators = ['+', '-', '*', '/', '(', ')', ','];

      const lastOperatorIndex = nodeText.split('').findLastIndex((char) => operators.includes(char));
      lastText = nodeText.slice(lastOperatorIndex + 1).trim();

      if (lastText) {
        setSearchValue(lastText);

        const selection = editor.state.selection;
        const editorView = editor.view;

        // Get the DOM coordinates of the cursor position
        const coords = editorView.coordsAtPos(selection.from - 1);

        // Show autocomplete at cursor position
        setAutocompletePosition({
          top: coords.top + window.scrollY + 25,
          left: coords.left + window.scrollX,
        });
      } else {
        setSearchValue('');
      }

      const { expression, variables } = generateExpressionAndVariables(
        editor.getJSON().content?.[0].content as IEditorContent[],
      );

      const { validated } = validateFormula({
        formulaList: Object.values(formulaDictionary),
        expression,
        recipeVariables: variables,
        formulaUuid: data.formulaUuid,
      });

      editor.commands.command(({ tr, state, dispatch }) => {
        const { doc } = state;
        let modified = false;

        doc.descendants((node, pos) => {
          if (node.type.name === 'hardBreak') {
            if (dispatch) {
              tr.delete(pos, pos + node.nodeSize);
              modified = true;
            }
          }
        });

        return modified;
      });

      if (validated) {
        isValid.current = validated;
        setErrorMessage('');
      }
    },
  });

  useEffect(() => {
    if (formulaUuidToFocus === data.formulaUuid && editor) {
      editor.commands.focus();
      setFormulaUuidToFocus(null);
    }
  }, [formulaUuidToFocus, data.formulaUuid]);

  const onBlur = async (): Promise<void> => {
    const { expression, variables } = generateExpressionAndVariables(
      editor?.getJSON().content?.[0].content as IEditorContent[],
    );

    const { validated, errorMessage } = validateFormula({
      formulaList: Object.values(formulaDictionary),
      expression,
      recipeVariables: variables,
      formulaUuid: data.formulaUuid,
    });

    isValid.current = validated;
    if (!validated) {
      setErrorMessage(errorMessage);
    } else {
      setErrorMessage('');
    }

    if (
      validated &&
      (!isEqual(variables, data.formula.recipe.variables) || data.formula.recipe.expression !== expression)
    ) {
      const response = (await request({
        url: `/formulas/${data.formulaUuid}`,
        method: 'PATCH',
        body: {
          recipe: {
            expression,
            variables,
          },
        },
        headers: { 'Organization-Uuid': organizationUuid },
        params: { scenarioUuid: activeScenarioUuid ?? undefined },
      })) as IAPIResponse;

      if (response.status < 400) {
        refreshData();
      }
    }
    setIsFocused(false);
    setSearchValue('');
  };

  useEffect(() => {
    if (editor) {
      const newContent = buildContentFromRecipe(data.formula.recipe);
      if (editor.getHTML() !== newContent) {
        editor.commands.setContent(newContent);
      }
      initialContentRef.current = newContent;
    }
  }, [data.formula.recipe]);

  useEffect(() => {
    const handleRezise = (entries: ResizeObserverEntry[]): void => {
      for (const entry of entries) {
        setEditorContainerHeight(entry.contentRect.height);
      }
    };

    const resizeObserver = new ResizeObserver(handleRezise);
    if (editorContainerRef.current) {
      resizeObserver.observe(editorContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (editor) {
      const DEFAULT_HEIGHT_PLUS_PADDING = 56;
      const wrapped = editorContainerHeight > DEFAULT_HEIGHT_PLUS_PADDING;
      editor.commands.command(({ tr }) => {
        let modified = false;
        editor.state.doc.descendants((node, pos) => {
          if (node.type.name === 'formulaNode') {
            tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              isEditorWrapped: wrapped,
            });
            modified = true;
          }
        });
        return modified;
      });
    }
  }, [editor, isFocused, editorContainerHeight]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Escape') {
      editor?.commands.setContent(initialContentRef.current);
      editor?.commands.blur();
      setIsFocused(false);
    } else if (event.key === 'Enter' && !isAutocompleteHovered) {
      event.preventDefault();
      editor?.commands.blur();
      editorRef.current?.blur();
    }
  };

  const replaceTextWithFormulaName = (option: SelectType): void => {
    editor?.commands.deleteRange({
      from: editor.state.selection.from - searchValue.length,
      to: editor.state.selection.from,
    });

    if (option.value === data.formulaUuid) {
      editor?.commands.insertContentAt(editor.state.selection.from, {
        type: 'formulaNode',
        attrs: {
          label: option.label,
          type: 'self',
          formulaUuid: null,
          timeModifier: 'previous-1',
          calculationModifier: null,
        },
      });
    } else if (option.value && Object.values(ICalculationTypeEnum).includes(option.value as ICalculationTypeEnum)) {
      editor?.commands.insertContentAt(editor.state.selection.from, {
        type: 'formulaNode',
        attrs: {
          label: option.value,
          type: IVariableTypeEnum.Calculated,
          calculationType: option.value,
          formulaUuid: null,
          timeModifier: 'previous-0',
          calculationModifier: 'All',
          calculationModifierType: 'jobTitle',
        },
      });
    } else {
      editor?.commands.insertContentAt(editor.state.selection.from, {
        type: 'formulaNode',
        attrs: {
          label: option.label,
          type: 'formula',
          formulaUuid: option.value,
          timeModifier: 'previous-0',
          calculationModifier: null,
        },
      });
    }

    setSearchValue('');

    editor?.commands.focus(editor.state.selection.from);
    setIsFocused(true);
  };

  return (
    <div
      className={`${isPulling ? 'bg-neutral-25' : 'bg-white'} ${isFocused ? 'z-[15]' : 'z-10'} sticky z-10 top-0 flex justify-between border-r border-neutral-50`}
      style={{
        width: `${columnWidth}px`,
        minWidth: `${columnWidth}px`,
        boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
        left: `${columnWidths[0]}px`,
      }}
    >
      <div
        className={`flex-1 overflow-hidden absolute bg-transparent border-r border-neutral-50 ${isViewOnly ? 'cursor-default' : 'cursor-text'}`}
        ref={editorContainerRef}
      >
        <EditorContent
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          editor={editor}
          className={`${isPulling ? 'bg-neutral-25' : 'bg-white'} overflow-x-auto no-scrollbar`}
          style={{
            minWidth: isFocused
              ? `calc(100vw - ${columnWidths[0]}px - ${sideMenuExpanded ? '275px' : '87px'})`
              : `${columnWidths[1]}px`,
            width: isFocused
              ? `calc(100vw - ${columnWidths[0]}px - ${sideMenuExpanded ? '275px' : '87px'})`
              : `${columnWidths[1]}px`,
          }}
        />
        {searchValue && isFocused && (
          <Autocomplete
            editor={editor}
            searchValue={searchValue}
            position={{ top: autocompletePosition.top, left: autocompletePosition.left }}
            selectOptions={[
              ...Object.values(formulaDictionary)
                .filter(
                  (formula) =>
                    formula.type === IFormulaTypeEnum.ModelBuilder &&
                    formula.recipe.name !== 'Headcount' &&
                    formula.recipe.name !== 'New Hires',
                )
                .map((formula) => ({
                  label: formula.recipe.name,
                  value: formula.formulaUuid,
                })),
              ...Object.values(ICalculationTypeEnum)
                .filter(
                  (calcType) =>
                    calcType === ICalculationTypeEnum.HeadcountNumber ||
                    calcType === ICalculationTypeEnum.NewHireNumber,
                )
                .map((calculationType) => ({
                  label: calculationTypeToTitleMap[calculationType],
                  value: calculationType,
                })),
            ]}
            onSelect={replaceTextWithFormulaName}
            onFocus={() => setIsAutocompleteHovered(true)}
            onBlur={() => setIsAutocompleteHovered(false)}
          />
        )}
        {!isValid.current && <ErrorPopover errorMessage={errorMessage} absolutePosition="top-3 right-2" />}
        {isFocused && (
          <div className="absolute bottom-2.5 right-2 cursor-pointer" onClick={() => onBlur()}>
            <Typography color="empty" className="hover:text-neutral-500">
              &crarr;
            </Typography>
          </div>
        )}
      </div>
      <ColumnResize columnIndex={columnIndex} />
    </div>
  );
};

export default ModelBuilderExpression;
