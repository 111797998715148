import React from 'react';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { useFeatureFlag } from '~/utils/hooks/useFeatureFlag';

export enum IPrebuiltComponentType {
  Options = 'options',
  RepRamping = 'rep-ramping',
  CashCollections = 'cash-collections',
  RevenueRecognition = 'revenue-recognition',
  MarketingFunnel = 'marketing-funnel',
  LoanSchedule = 'loan-schedule',
}

const PrebuiltOptions = ({
  setPrebuiltComponentModalState,
}: {
  setPrebuiltComponentModalState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      display: IPrebuiltComponentType;
    }>
  >;
}): React.ReactNode => {
  const loanAmortization = useFeatureFlag('loanAmortization');

  return (
    <div className="flex flex-col gap-2">
      <Typography size="xs" color="secondary" className="mb-1">
        Add and customize pre-built components into your financial model
      </Typography>
      <div className="flex flex-row gap-2 items-center">
        <Button
          fill="outlineSolid"
          className="!w-fit !px-3 !py-1"
          onClick={() =>
            setPrebuiltComponentModalState({
              display: IPrebuiltComponentType.RepRamping,
              isOpen: true,
            })
          }
        >
          Add
        </Button>
        <Typography weight="semibold">Rep-Ramping</Typography>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Button
          fill="outlineSolid"
          className="!w-fit !px-3 !py-1"
          onClick={() =>
            setPrebuiltComponentModalState({
              display: IPrebuiltComponentType.RevenueRecognition,
              isOpen: true,
            })
          }
        >
          Add
        </Button>
        <Typography weight="semibold">Revenue Recognition</Typography>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Button
          fill="outlineSolid"
          className="!w-fit !px-3 !py-1"
          onClick={() =>
            setPrebuiltComponentModalState({
              display: IPrebuiltComponentType.CashCollections,
              isOpen: true,
            })
          }
        >
          Add
        </Button>
        <Typography weight="semibold">Cash Collections</Typography>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Button
          fill="outlineSolid"
          className="!w-fit !px-3 !py-1"
          onClick={() =>
            setPrebuiltComponentModalState({
              display: IPrebuiltComponentType.MarketingFunnel,
              isOpen: true,
            })
          }
        >
          Add
        </Button>
        <Typography weight="semibold">Marketing Funnel</Typography>
      </div>
      {loanAmortization && (
        <div className="flex flex-row gap-2 items-center">
          <Button
            fill="outlineSolid"
            className="!w-fit !px-3 !py-1"
            onClick={() =>
              setPrebuiltComponentModalState({
                display: IPrebuiltComponentType.LoanSchedule,
                isOpen: true,
              })
            }
          >
            Add
          </Button>
          <Typography weight="semibold">Loan Schedule</Typography>
        </div>
      )}
    </div>
  );
};

export default PrebuiltOptions;
