import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDepartment } from '~/pages/Settings/Departments/entity/types';

export interface IDashboardConfiguration {
  metrics: string[];
  graphs: string[];
  levers: string[];
}

export interface IOrganizationState {
  name: string;
  uuid: string;
  configuration: {
    companyStartDate: string;
    isModelBuilderVisibleToUser: boolean;
    dashboardConfiguration: IDashboardConfiguration;
    contractsEnabled: boolean;
    ratiosEnabled: boolean;
    monthsOutToForecast: number;
  };
  departments: IDepartment[];
}

const initialState = {
  name: '',
  uuid: '',
  configuration: {
    companyStartDate: '',
    isModelBuilderVisibleToUser: false,
    contractsEnabled: false,
    ratiosEnabled: false,
    dashboardConfiguration: {
      metrics: [],
      graphs: [],
      levers: [],
    },
    monthsOutToForecast: 36,
  },
  departments: [],
} as IOrganizationState;

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<IOrganizationState>) => action.payload,
    reset: () => initialState,
    setDepartments: (state, action: PayloadAction<IDepartment[]>) => {
      state.departments = action.payload;
    },
  },
});

export const { update, reset, setDepartments } = organizationSlice.actions;
export default organizationSlice.reducer;
