import { IStringDate } from './types';

type formattingOptions =
  | 'mm/dd/yyyy'
  | 'MMM dd'
  | 'MMM'
  | 'MMM yy'
  | 'yy'
  | 'MMM yyyy'
  | 'MMMM yyyy'
  | "MMM 'yy"
  | 'mm-dd-yyyy';

const monthToAbbreviation: Record<string, string> = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

const monthToFullName: Record<string, string> = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

const getLastTwoDigits = (year: string): string => {
  return year.slice(-2);
};

export const format = (date: IStringDate, format: formattingOptions): string => {
  const year = date.split('-')[0];
  const month = date.split('-')[1];
  const day = date.split('-')[2];

  switch (format) {
    case 'mm/dd/yyyy':
      return `${month}/${day}/${year}`;
    case 'MMM dd':
      return `${monthToAbbreviation[month]} ${day}`;
    case 'MMM':
      return `${monthToAbbreviation[month]}`;
    case 'MMM yy':
      return `${monthToAbbreviation[month]} ${getLastTwoDigits(year)}`;
    case 'MMM yyyy':
      return `${monthToAbbreviation[month]} ${year}`;
    case 'yy':
      return getLastTwoDigits(year);
    case 'MMMM yyyy':
      return `${monthToFullName[month]} ${year}`;
    case "MMM 'yy":
      return `${monthToAbbreviation[month]} '${getLastTwoDigits(year)}`;
    case 'mm-dd-yyyy':
      return `${month}-${day}-${year}`;
    default:
      return date;
  }
};
