import { IStringDate } from './types';

export const isSameMonthOrAfter = ({
  comparison,
  dateToCheck,
}: {
  comparison: IStringDate;
  dateToCheck: IStringDate;
}): boolean => {
  const comparisonYear = parseInt(comparison.split('-')[0]);
  const dateToCheckYear = parseInt(dateToCheck.split('-')[0]);

  if (comparisonYear === dateToCheckYear) {
    const comparisonMonth = parseInt(comparison.split('-')[1]);
    const dateToCheckMonth = parseInt(dateToCheck.split('-')[1]);

    return comparisonMonth <= dateToCheckMonth;
  }

  return dateToCheckYear > comparisonYear;
};
