import React from 'react';
import Typography from '~/components/Typography';

const UserMessage = ({ message }: { message: string }): React.ReactElement => {
  return (
    <div className="w-full flex justify-end">
      <div className="max-w-[75%] bg-green-25 rounded-xl px-4 py-3">
        <Typography size="sm">{message}</Typography>
      </div>
    </div>
  );
};

export default UserMessage;
