import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

export const getMonthHasScenarioOverrideOrActual = ({
  xMin,
  xMax,
  consolidatedGraphData,
}: {
  xMin: number;
  xMax: number;
  consolidatedGraphData: IConsolidatedGraphBody;
}): boolean[] => {
  const startMonth = stringDate.getStringDate(new Date(xMin));
  const endMonth = stringDate.getStringDate(new Date(xMax));
  const monthArray = stringDate.createMonthArrayBetweenDates({ startDate: startMonth, endDate: endMonth });
  let actualsArrActiveScenario: { date: IStringDate; value: number }[] = [];
  let overridesArrActiveScenario: { date: IStringDate; value: number }[] = [];
  let actualsArrWorkingModel: { date: IStringDate; value: number }[] = [];
  let overridesArrWorkingModel: { date: IStringDate; value: number }[] = [];

  if (consolidatedGraphData.dataActuals && 'activeScenario' in consolidatedGraphData.dataActuals) {
    actualsArrActiveScenario = consolidatedGraphData.dataActuals['activeScenario'];
  }
  if (consolidatedGraphData.dataOverrides && 'activeScenario' in consolidatedGraphData.dataOverrides) {
    overridesArrActiveScenario = consolidatedGraphData.dataOverrides['activeScenario'];
  }
  if (consolidatedGraphData.dataActuals && 'workingModel' in consolidatedGraphData.dataActuals) {
    actualsArrWorkingModel = consolidatedGraphData.dataActuals['workingModel'];
  }
  if (consolidatedGraphData.dataOverrides && 'workingModel' in consolidatedGraphData.dataOverrides) {
    overridesArrWorkingModel = consolidatedGraphData.dataOverrides['workingModel'];
  }
  return monthArray.map((month) => {
    const foundActualActiveScenario = actualsArrActiveScenario.find((item) =>
      stringDate.isSameMonth({ date1: item.date, date2: month }),
    );
    const foundOverrideActiveScenario = overridesArrActiveScenario.find((item) =>
      stringDate.isSameMonth({ date1: item.date, date2: month }),
    );
    const foundActualWorkingModel = actualsArrWorkingModel.find((item) =>
      stringDate.isSameMonth({ date1: item.date, date2: month }),
    );
    const foundOverrideWorkingModel = overridesArrWorkingModel.find((item) =>
      stringDate.isSameMonth({ date1: item.date, date2: month }),
    );
    const scenarioActualDiffs = Boolean(
      foundActualActiveScenario && foundActualActiveScenario.value !== foundActualWorkingModel?.value,
    );
    const scenarioOverrideDiffs = Boolean(
      foundOverrideActiveScenario && foundOverrideActiveScenario.value !== foundOverrideWorkingModel?.value,
    );
    return scenarioActualDiffs || scenarioOverrideDiffs;
  });
};
