import React, { useMemo, useContext } from 'react';
import { IFormulaType } from '~/components/Formulas/context/types';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import xeroIcon from '~/assets/logos/xero.svg';
import ColumnResize from '~/components/Formulas/FormulasTable/ColumnResize';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { IFormula } from '~/services/parallel/formulas.types';
import { ExpensesPageContext } from '../../context/ExpensesContext';

interface IProps {
  rowIndex: number;
  columnWidth: number;
  data: IFormulaType;
  columnIndex: number;
  style?: React.CSSProperties;
}

const integrationIconMapping = {
  'quickbooks-online': quickbooksIcon,
  xero: xeroIcon,
};

const Label = ({ columnWidth, data, columnIndex, style = {} }: IProps): React.ReactElement => {
  const { formulaDictionary } = useFormulaContext();
  const { setExpenseUuid, setExpenseModal } = useContext(ExpensesPageContext);

  const formula: IFormula | null = useMemo(
    () => (data.formulaUuid in formulaDictionary ? formulaDictionary[data.formulaUuid] : null),
    [formulaDictionary, data.formulaUuid],
  );

  return (
    <div
      className="bg-white sticky z-10 top-0 left-0 flex pr-2 border-r border-neutral-50 group h-[48px] items-center text-nowrap"
      style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px`, ...style }}
    >
      <button
        className="text-left flex-1 overflow-hidden hover:underline"
        onClick={() => {
          if (data.relatedResourceUuid) {
            setExpenseUuid({ uuid: data.formulaUuid, expenseUuid: data.relatedResourceUuid, type: 'edit' });
            setExpenseModal(true);
          }
        }}
      >
        {formula?.recipe.name}
      </button>
      <div className="absolute right-0 top-[14px] flex items-center gap-1 bg-white px-2">
        {data.label.integration && (
          <div key={`${data.formulaUuid}-${data.label.integration}`}>
            <img
              className="size-5"
              src={integrationIconMapping[data.label.integration as keyof typeof integrationIconMapping]}
              alt={`Integration icon for ${data.label.integration}`}
            />
          </div>
        )}
      </div>
      <ColumnResize columnIndex={columnIndex} />
    </div>
  );
};

export default Label;
