import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Typography from '../Typography';
import ScenarioInfoPopover from './ScenarioInfoPopover';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import toast from 'react-hot-toast';
import {
  update,
  updateActiveScenarioData,
  updateScenarioList,
  updateScenarioLoadingState,
} from '~/store/scenarioSlice';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { useInput } from '../Input/InputWrapper';
import { ZScenario } from '~/pages/Dashboard/entity/schemas';
import NameScenarioModal from './components/NameScenarioModal';
import { refreshDepartments } from '~/utils/refreshDepartments';
import HoverPopover from '../HoverPopover';
import AutosizeInput from 'react-input-autosize';
import { cancelScenario, deleteScenario, mergeScenarioIntoMain, restoreScenario, updateScenario } from './requests';
import { StatusCodes } from 'http-status-codes';
import ScenarioModeToast from './components/toasts/ScenarioToast';
import logger from '~/utils/logger';
import { Transition } from '@headlessui/react';
import FullPageLoading from '../FullPageLoading';
import Lottie from 'lottie-react';
import { createOrEditScenarioAnimationJson } from './animations/createOrEditScenarioAnimationJson';
import RepeatedFadeText from './animations/RepeatedFadeText';
import { mergeScenarioAnimationJson } from './animations/mergeScenarioAnimationJson';

const MINIMUM_ANIMATION_TIMES = {
  creating: 1500,
  entering: 1500,
  merging: 1500,
};

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

const ScenarioMode = (): React.ReactNode => {
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    activeScenarioUuid,
    selectedScenarioUuids,
    activeScenarioData,
    scenarioLoadingState,
    scenarioMode,
    scenarios,
  } = useSelector((state: State) => state.scenario);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [showNameScenarioModal, setShowNameScenarioModal] = useState<boolean>(false);
  const [scenarioTitle, setScenarioTitle, resetScenarioTitle] = useInput({
    value: activeScenarioData?.changeDescription ?? '',
    valid: true,
    validation: /.*/,
  });
  const [inputMaxWidth, setInputMaxWidth] = useState<number>(1000);
  const [originalTitle, setOriginalTitle] = useState<string>(activeScenarioData?.changeDescription ?? '');
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inScenarioMode, setInScenarioMode] = useState<boolean>(false);
  const [showSplash, setShowSplash] = useState<boolean>(false);
  const [animationComplete, setAnimationComplete] = useState<boolean>(false);
  const [mergeScenarioComplete, setMergeScenarioComplete] = useState<boolean>(false);
  const previousScenarioLoadingState = useRef<string | undefined>(scenarioLoadingState);

  useEffect(() => {
    if (inputRef.current && scenarioTitle.value === 'Untitled Scenario') {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [animationComplete]);

  useEffect(() => {
    if (organizationUuid) {
      refreshDepartments();

      if (activeScenarioUuid) {
        updateScenario({
          organizationUuid,
          scenarioUuid: activeScenarioUuid,
          updateEditModeStart: true,
        });
      }
    }

    return () => {
      if (organizationUuid) {
        refreshDepartments();
      }
    };
  }, [activeScenarioUuid, organizationUuid]);

  useEffect(() => {
    if (activeScenarioData) {
      setScenarioTitle({
        ...scenarioTitle,
        value: activeScenarioData.changeDescription,
        valid: scenarioTitle.validation.test(scenarioTitle.value),
      });
    }
  }, [activeScenarioData]);

  useEffect(() => {
    if (contentContainerRef.current) {
      setInputMaxWidth(contentContainerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (scenarioTitle.value !== activeScenarioData?.changeDescription && activeScenarioData) {
      dispatch(
        updateActiveScenarioData({
          ...activeScenarioData,
          changeDescription: scenarioTitle.value,
        }),
      );
      dispatch(
        updateScenarioList(
          scenarios.map((s) =>
            s.uuid === activeScenarioUuid ? { ...activeScenarioData, changeDescription: scenarioTitle.value } : s,
          ),
        ),
      );
    }
  }, [scenarioTitle.value]);

  useEffect(() => {
    if (activeScenarioData?.changeDescription !== scenarioTitle.value) {
      setScenarioTitle({
        ...scenarioTitle,
        value: activeScenarioData?.changeDescription ?? '',
      });
      setOriginalTitle(activeScenarioData?.changeDescription ?? '');
    }
  }, [activeScenarioData?.changeDescription]);

  useEffect(() => {
    if (previousScenarioLoadingState.current === scenarioLoadingState && !animationComplete) {
      return undefined;
    }

    previousScenarioLoadingState.current = scenarioLoadingState;

    if (['creating', 'entering', 'merging'].includes(scenarioLoadingState ?? '')) {
      setAnimationComplete(false);
      setShowSplash(true);
      setInScenarioMode(true);
      setTimeout(
        () => {
          setAnimationComplete(true);
        },
        MINIMUM_ANIMATION_TIMES[scenarioLoadingState as keyof typeof MINIMUM_ANIMATION_TIMES],
      );
      return undefined;
    }

    if (scenarioLoadingState === 'updating') {
      setInScenarioMode(true);
      return undefined;
    }

    if (scenarioLoadingState === 'exiting') {
      setInScenarioMode(false);
      setAnimationComplete(false);
      setShowSplash(false);
      return undefined;
    }

    if (scenarioLoadingState === 'idle' && !animationComplete) {
      return undefined;
    }

    setAnimationComplete(false);
    setShowSplash(false);
    return undefined;
  }, [scenarioLoadingState, animationComplete]);

  useEffect(() => {
    if (activeScenarioUuid) {
      setInScenarioMode(true);
    }
  }, [activeScenarioUuid]);

  useEffect(() => {
    if (animationComplete && (mergeScenarioComplete || !activeScenarioUuid)) {
      setInScenarioMode(false);
      setMergeScenarioComplete(false);
      toast.custom(() => <ScenarioModeToast message="Adjustments have been applied to the model" />, {
        position: 'bottom-center',
        duration: 4000,
      });
      revalidator.revalidate();
      dispatch(
        update({
          activeScenarioUuid: null,
          activeScenarioData: null,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids,
          scenarioMode,
          scenarioLoadingState: 'idle',
          scenarios,
        }),
      );
    }
  }, [animationComplete, mergeScenarioComplete]);

  const saveActiveScenario = (): void => {
    toast.custom(
      (t) => (
        <ScenarioModeToast
          message="Scenario has been saved"
          button={{
            text: 'View',
            callback: () => {
              if (activeScenarioUuid) {
                dispatch(
                  update({
                    activeScenarioUuid: null,
                    activeScenarioData: null,
                    activeScenarioHasChanges: false,
                    scenarioLoadingState: 'idle',
                    scenarioMode,
                    leverChanges: [],
                    cashBalanceLockedIndexes: [],
                    cashBalanceSelectedPoint: null,
                    selectedScenarioUuids: [activeScenarioUuid, ...selectedScenarioUuids],
                    scenarios,
                  }),
                );
              }
              navigate(`/dashboard`);
              toast.dismiss(t.id);
            },
          }}
        />
      ),
      {
        position: 'bottom-center',
        duration: 4000,
      },
    );
    revalidator.revalidate();
    dispatch(
      update({
        activeScenarioUuid: null,
        activeScenarioData: null,
        activeScenarioHasChanges: false,
        leverChanges: [],
        cashBalanceLockedIndexes: [],
        cashBalanceSelectedPoint: null,
        selectedScenarioUuids,
        scenarioLoadingState: 'exiting',
        scenarioMode,
        scenarios,
      }),
    );
  };

  const updateScenarioTitle = async (): Promise<boolean> => {
    try {
      if (scenarioTitle.value.trim().length && scenarioTitle.valid) {
        if (activeScenarioData?.changeDescription !== originalTitle && activeScenarioUuid) {
          const response = (await updateScenario({
            organizationUuid,
            scenarioUuid: activeScenarioUuid,
            scenarioTitle: scenarioTitle.value,
          })) as IAPIResponse;
          if (response.status === StatusCodes.OK) {
            setShowNameScenarioModal(false);
            const parsedUpdatedScenario = ZScenario.parse(response.data.data);
            dispatch(updateActiveScenarioData(parsedUpdatedScenario));
            dispatch(
              updateScenarioList(scenarios.map((s) => (s.uuid === activeScenarioUuid ? parsedUpdatedScenario : s))),
            );
          }
        }
      } else {
        setScenarioTitle({
          ...scenarioTitle,
          value: originalTitle,
        });
      }
      return true;
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('Failed to update scenario title');
      return false;
    }
  };

  const handleRestoreScenario = async ({ scenarioUuid }: { scenarioUuid: string }): Promise<void> => {
    const response = (await restoreScenario({
      organizationUuid,
      scenarioUuid,
    })) as IAPIResponse;
    if (response.status === StatusCodes.OK) {
      const scenarioData = ZScenario.parse(response.data.data);
      dispatch(updateActiveScenarioData(scenarioData));
      dispatch(
        update({
          activeScenarioUuid: scenarioUuid,
          activeScenarioData: scenarioData,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          scenarioMode,
          selectedScenarioUuids,
          scenarios,
        }),
      );
      revalidator.revalidate();
    } else {
      toast.error('Failed to restore scenario');
    }
  };

  const updateAndSaveScenario = async (): Promise<void> => {
    try {
      const updated = await updateScenarioTitle();
      if (updated) {
        saveActiveScenario();
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      toast.error('Failed to update scenario');
    } finally {
      setShowNameScenarioModal(false);
    }
  };

  const handleCompleteAction = (): void => {
    if (
      activeScenarioData &&
      (activeScenarioData.changeDescription.trim().toLowerCase() === 'untitled scenario' ||
        !activeScenarioData.changeDescription.trim())
    ) {
      setShowNameScenarioModal(true);
    } else if (activeScenarioData?.changeDescription.trim()) {
      saveActiveScenario();
    }
  };

  const handleDiscardScenario = async (): Promise<void> => {
    if (activeScenarioUuid) {
      const response = await deleteScenario({
        organizationUuid,
        scenarioUuid: activeScenarioUuid,
      });
      if (response.status === StatusCodes.NO_CONTENT) {
        setInScenarioMode(false);
        dispatch(
          update({
            activeScenarioUuid: null,
            activeScenarioData: null,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            scenarioMode,
            scenarioLoadingState: 'exiting',
            scenarios: scenarios.filter((scenario) => scenario.uuid !== activeScenarioUuid),
          }),
        );
        revalidator.revalidate();
        toast.custom(
          (t) => (
            <ScenarioModeToast
              message="Scenario has been discarded"
              button={{
                text: 'Undo',
                callback: () => {
                  handleRestoreScenario({ scenarioUuid: activeScenarioUuid });
                  toast.dismiss(t.id);
                },
              }}
            />
          ),
          {
            position: 'bottom-center',
            duration: 6000,
          },
        );
      } else {
        toast.error('Failed to delete scenario');
      }
    }
  };

  const handleCancelScenario = async (): Promise<void> => {
    if (activeScenarioUuid) {
      const response = await cancelScenario({
        organizationUuid,
        scenarioUuid: activeScenarioUuid,
      });
      if (response.status === StatusCodes.NO_CONTENT) {
        dispatch(
          update({
            activeScenarioUuid: null,
            activeScenarioData: null,
            activeScenarioHasChanges: false,
            leverChanges: [],
            cashBalanceLockedIndexes: [],
            cashBalanceSelectedPoint: null,
            selectedScenarioUuids,
            scenarioLoadingState: 'exiting',
            scenarioMode,
            scenarios,
          }),
        );
        revalidator.revalidate();
        toast.custom(() => <ScenarioModeToast message="Scenario changes have been cancelled" />, {
          position: 'bottom-center',
          duration: 6000,
        });
      } else {
        toast.error('Failed to cancel scenario changes');
      }
    }
  };

  const handleMergeScenarioIntoMain = async (): Promise<void> => {
    if (activeScenarioUuid) {
      dispatch(updateScenarioLoadingState('merging'));
      const response = (await mergeScenarioIntoMain({
        organizationUuid,
        scenarioUuid: activeScenarioUuid,
        selectedScenarioUuids,
      })) as IAPIResponse;
      if (response.status === StatusCodes.NO_CONTENT) {
        setMergeScenarioComplete(true);
        dispatch(updateScenarioList(scenarios.filter((s) => s.uuid !== activeScenarioUuid)));
      } else {
        toast.error('Failed to merge scenario');
      }
    }
  };

  useEffect(() => {
    if (showNameScenarioModal) return undefined;

    const handler = setTimeout(() => {
      if (scenarioTitle.value.trim().length && scenarioTitle.valid) {
        updateScenarioTitle();
      }
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [scenarioTitle.value]);

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    e.target.style.border = '2px solid #5A8496';
    await updateScenarioTitle();
  };

  const getAnimation = useMemo(() => {
    if (!showSplash) return null;

    switch (scenarioLoadingState) {
      case 'creating':
        return (
          <div className="flex flex-col items-center gap-4">
            <Lottie loop={false} autoplay={true} animationData={createOrEditScenarioAnimationJson()} />
            <RepeatedFadeText
              startText="Duplicating Your Data"
              endText="Duplicating Your Data"
              startTextColor="green"
              endTextColor="blue"
              msBeforeStart={750}
              fadeIntervalMS={900}
            />
          </div>
        );
      case 'entering':
        return (
          <div className="flex flex-col items-center gap-4">
            <Lottie loop={false} autoplay={true} animationData={createOrEditScenarioAnimationJson()} />
            <RepeatedFadeText
              startText="Entering Edit Mode"
              endText="Entering Edit Mode"
              startTextColor="green"
              endTextColor="blue"
              msBeforeStart={750}
              fadeIntervalMS={900}
            />
          </div>
        );
      case 'merging':
        return (
          <div className="flex flex-col items-center gap-4">
            <Lottie loop={false} autoplay={true} animationData={mergeScenarioAnimationJson()} />
            <RepeatedFadeText
              startText="Merging to Main"
              endText="Merging to Main"
              startTextColor="blue"
              endTextColor="green"
              msBeforeStart={750}
              fadeIntervalMS={900}
            />
          </div>
        );
    }
    return null;
  }, [showSplash]);

  return (
    <div className={`flex flex-col`}>
      <Transition
        show={inScenarioMode && showSplash}
        as={Fragment}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed z-[50] inset-0">
          <div className="absolute inset-0 bg-white opacity-80" />
          <div className="relative flex flex-col items-center justify-center h-full">{getAnimation}</div>
        </div>
      </Transition>

      <FullPageLoading
        text="Loading Scenario"
        isVisible={!showSplash && ['updating', 'viewing'].includes(scenarioLoadingState ?? '')}
        color="blue"
      />
      <div className="absolute top-0 left-0 w-full h-full z-60 pointer-events-none overflow-hidden">
        <Transition
          show={inScenarioMode}
          enter="transition duration-[1000ms] ease-in-out"
          enterFrom="scale-150 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="transition-all duration-500 ease-in-out"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-150 opacity-0"
        >
          <div
            data-testid="scenario-mode-page-outline"
            className="absolute top-0 left-0 w-[calc(100%+10px)] h-[calc(100%-40px)] -m-[5px] border-[12px] border-blue-400 pointer-events-none box-border rounded-2xl"
          />
        </Transition>
        <Transition
          show={inScenarioMode}
          as={Fragment}
          enter="transition duration-1000 delay-250 ease-in-out"
          enterFrom="transform translate-y-full"
          enterTo="transform translate-y-0"
          leave="transition-all duration-100 ease-in-out"
          leaveFrom="transform translate-y-0"
          leaveTo="transform translate-y-full"
        >
          <div className="absolute bottom-0 left-0 w-full h-14 bg-blue-400 flex justify-between items-center px-4">
            <div className="flex items-center gap-2 justify-start w-full">
              <div className="flex items-center gap-2 min-w-[145px]">
                <Typography className="text-white" weight="semibold">
                  Scenario Mode
                </Typography>
                <ScenarioInfoPopover id="scenario-mode-info" />
              </div>
              <div className="flex items-center justify-start h-full w-[80%]" ref={contentContainerRef}>
                <AutosizeInput
                  name="scenario-title"
                  // @ts-expect-error - ref is not typed correctly on AutosizeInput
                  ref={inputRef}
                  value={scenarioTitle.value}
                  autoComplete="off"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setScenarioTitle({
                      ...scenarioTitle,
                      value: e.target.value,
                      valid: scenarioTitle.validation.test(e.target.value),
                      pristine: false,
                      touched: true,
                    });
                  }}
                  onBlur={handleBlur}
                  inputStyle={{
                    pointerEvents: 'auto',
                    textAlign: 'left',
                    padding: '0.25rem 0.5rem',
                    backgroundColor: '#5A8496',
                    color: '#D6E1E6',
                    border: '2px solid #5A8496',
                    borderRadius: '0.25rem',
                    maxWidth: inputMaxWidth,
                    minWidth: '7rem',
                    fontFamily: 'inter',
                    outline: 'none !important',
                    boxShadow: 'none !important',
                  }}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    e.target.style.border = '2px solid #95AFBB';
                    e.target.style.setProperty('--tw-ring-color', '#5A8496');
                  }}
                  className="focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2"
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-2 min-w-[400px]">
              {['editing'].includes(scenarioMode) ? (
                <Button
                  fill="solidBlue"
                  className={`!w-fit !px-2 !py-1 ease-in-out duration-300 ${
                    showSplash ? 'pointer-events-none' : 'pointer-events-auto'
                  }`}
                  onClick={handleCancelScenario}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  fill="solidBlue"
                  className={`!w-fit !px-2 !py-1 ease-in-out duration-300 ${
                    showSplash ? 'pointer-events-none' : 'pointer-events-auto'
                  }`}
                  onClick={handleDiscardScenario}
                >
                  Discard
                </Button>
              )}
              <Button
                fill="darkBlueDefault"
                className={`!w-fit !px-2 !py-1 ease-in-out duration-300 ${
                  showSplash ? 'pointer-events-none' : 'pointer-events-auto'
                }`}
                onClick={handleCompleteAction}
              >
                Save Scenario
              </Button>
              <Typography color="lightBlue" size="xl">
                |
              </Typography>
              <HoverPopover
                buttonContent={
                  <Button
                    fill="blueDestructive"
                    className={`!w-fit !px-2 !py-1 ease-in-out duration-300 ${
                      showSplash ? 'pointer-events-none' : 'pointer-events-auto'
                    }`}
                    onClick={handleMergeScenarioIntoMain}
                  >
                    Apply To Model
                  </Button>
                }
                panelContent={
                  <div className="bg-white rounded px-4 py-2 text-center w-[300px]">
                    <Typography>Merge your scenario into the main model, applying all adjustments</Typography>
                  </div>
                }
                panelClassName="shadow-lg z-[100]"
                anchor="top"
              />
            </div>
          </div>
        </Transition>
        <NameScenarioModal
          isOpen={showNameScenarioModal}
          cancel={() => {
            resetScenarioTitle();
            setShowNameScenarioModal(false);
          }}
          confirm={updateAndSaveScenario}
          scenarioTitle={scenarioTitle}
          setScenarioTitle={setScenarioTitle}
          scenarioType={'dynamic'}
        />
      </div>
    </div>
  );
};

export default ScenarioMode;
