import { IStringDate } from './types';

const monthToLastDay: Record<string, '30' | '31' | '28' | '29'> = {
  '01': '31',
  '02': '28',
  '03': '31',
  '04': '30',
  '05': '31',
  '06': '30',
  '07': '31',
  '08': '31',
  '09': '30',
  '10': '31',
  '11': '30',
  '12': '31',
};

export const getLastDayOfMonth = (date: IStringDate): '30' | '31' | '28' | '29' => {
  const month = date.split('-')[1];
  const year = date.split('-')[0];
  if (month !== '02') return monthToLastDay[month];
  if (Number(year) % 4 === 0) return '29';
  return '28';
};
