import React, { useRef, useState, useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import BasicFormulaNode from './BasicFormulaNode';
import { IFormula, IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { Extension } from '@tiptap/core';
import { SelectType } from '~/components/Select/Select.types';
import Autocomplete from '../ExpressionBuilder/Autocomplete';
import Typography from '~/components/Typography';

const DisableEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: (): boolean => true,
    };
  },
});

type IEditorContent = IBasicFormulaNode | ITextNode;

interface IProps {
  minMaxValue: string | null;
  setMinMaxValue: React.Dispatch<React.SetStateAction<string | null>>;
  onValidationChange?: (isValid: boolean) => void;
}

interface IBasicFormulaNodeAttributes {
  label: string;
  type: string;
  formulaUuid: string;
}

interface IBasicFormulaNode {
  type: 'basicFormulaNode';
  attrs: IBasicFormulaNodeAttributes;
}

interface ITextNode {
  type: 'text';
  text: string;
}

const MinMaxInput = ({ minMaxValue, setMinMaxValue, onValidationChange }: IProps): React.ReactElement => {
  const { formulaDictionary } = useFormulaContext();

  const [isAutocompleteHovered, setIsAutocompleteHovered] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [autocompletePosition, setAutocompletePosition] = useState({ top: 0, left: 0 });
  const [errorMessage, setErrorMessage] = useState('');
  const editorRef = useRef<HTMLDivElement>(null);

  const isValid = useRef(true);

  const buildContentFromRecipe = (value?: string | null): string => {
    const foundFormula = formulaDictionary[value ?? ''] as IFormula | undefined;

    if (foundFormula) {
      return ` <basic-formula-node data-label="${foundFormula.recipe.name}" data-formula-uuid="${foundFormula.formulaUuid}"></basic-formula-node>`;
    } else if (value) {
      return ` <span>${value}</span>`;
    } else {
      return '';
    }
  };

  const initialContentRef = useRef<string>(buildContentFromRecipe(minMaxValue));

  const validateTextValue = (text: string): boolean => {
    const regex = /^[-]?(\d+\.?\d*|\.\d+)$/;
    return regex.test(text);
  };

  const validateFormulaNodeContent = (editorContent: IEditorContent[] | undefined): boolean => {
    if (editorContent && editorContent.length === 1 && editorContent[0].type === 'basicFormulaNode') {
      return true;
    } else if (!editorContent) {
      return true;
    }
    return false;
  };

  const editor = useEditor({
    editable: true,
    enableInputRules: false,
    extensions: [
      StarterKit.configure({
        bold: false,
        italic: false,
      }),
      BasicFormulaNode,
      DisableEnter,
    ],
    editorProps: {
      attributes: {
        class: `pl-2 rounded w-full !whitespace-nowrap py-2 focus:outline-none focus:border border h-[42px] flex items-center overflow-x-auto no-scrollbar hover:border-green ${isValid.current ? 'border-transparent focus:border-green' : 'border-red-500 focus:border-red-500'} ${isFocused && 'is-focused !h-fit !min-h-[42px] !max-h-[42px]'}`,
      },
      handleClick: () => {
        setSearchValue('');
      },
    },
    onFocus: () => {
      setIsFocused(true);
    },
    onUpdate: ({ editor }): void => {
      const cursorPos = editor.state.selection.from;
      const node = editor.state.doc.nodeAt(cursorPos - 1);
      const nodeText = node?.textContent;

      // Need to see if formula is valid if there is no current node text
      const editorContent: undefined | IEditorContent[] = editor.getJSON().content?.[0].content as
        | IEditorContent[]
        | undefined;
      const validFormulaNode = validateFormulaNodeContent(editorContent);

      if (editorContent && editorContent.length > 1) {
        onValidationChange?.(false);
        return;
      }

      if (!nodeText && !validFormulaNode) {
        onValidationChange?.(false);
      } else if (!nodeText && validFormulaNode) {
        onValidationChange?.(true);
        isValid.current = true;
        setErrorMessage('');
      }

      // If there is no node text, we have already validated the formula node
      if (!nodeText) {
        setSearchValue('');
        return;
      } else if (['+', '-', '*', '/', '(', ')'].includes(nodeText)) {
        // If there is a node text, but it is an operator, it is not valid
        onValidationChange?.(false);
        setSearchValue('');
        return;
      }

      // If there is a node text, but it is not a valid number, it is not valid
      const validText = validateTextValue(nodeText);

      if (!validText) {
        onValidationChange?.(false);
      } else {
        onValidationChange?.(true);
        isValid.current = true;
        setErrorMessage('');
      }

      // Get the text up until an operator before cursor position
      let lastText = '';
      const operators = ['+', '-', '*', '/', '(', ')', ','];

      const lastOperatorIndex = nodeText.split('').findLastIndex((char) => operators.includes(char));
      lastText = nodeText.slice(lastOperatorIndex + 1).trim();

      if (lastText) {
        setSearchValue(lastText);

        const selection = editor.state.selection;
        const editorView = editor.view;

        // Get the DOM coordinates of the cursor position
        const coords = editorView.coordsAtPos(selection.from - 1);

        // Show autocomplete at cursor position
        setAutocompletePosition({
          top: coords.top + window.scrollY + 25,
          left: coords.left + window.scrollX,
        });
      } else {
        setSearchValue('');
      }

      editor.commands.command(({ tr, state, dispatch }) => {
        const { doc } = state;
        let modified = false;

        doc.descendants((node, pos) => {
          if (node.type.name === 'hardBreak') {
            if (dispatch) {
              tr.delete(pos, pos + node.nodeSize);
              modified = true;
            }
          }
        });

        return modified;
      });
    },
  });

  useEffect(() => {
    if (editor) {
      const newContent = buildContentFromRecipe(minMaxValue);
      if (editor.getHTML() !== newContent) {
        editor.commands.setContent(newContent);
      }
      initialContentRef.current = newContent;
    }
  }, [minMaxValue]);

  const onBlur = (): void => {
    const editorContent: undefined | IEditorContent[] = editor?.getJSON().content?.[0].content as
      | IEditorContent[]
      | undefined;

    if (editorContent && editorContent.length > 1) {
      isValid.current = false;
      setErrorMessage('Only one value allowed');
      onValidationChange?.(false);
    } else if (editorContent && editorContent.length === 1) {
      if (editorContent[0].type === 'text') {
        const textVal = editorContent[0].text.trim();
        const isValidValue = validateTextValue(textVal);

        if (!isValidValue) {
          isValid.current = false;
          setErrorMessage('Please enter a valid number');
          onValidationChange?.(false);
        } else {
          const minMaxValueToUpdate = textVal;
          setMinMaxValue(minMaxValueToUpdate);
          onValidationChange?.(true);
        }
      } else {
        const minMaxValueToUpdate = editorContent[0].attrs.formulaUuid;
        setMinMaxValue(minMaxValueToUpdate);
        onValidationChange?.(true);
      }
    } else {
      setMinMaxValue(null);
      onValidationChange?.(true);
    }
    setIsFocused(false);
    setSearchValue('');
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Escape') {
      editor?.commands.setContent(initialContentRef.current);
      editor?.commands.blur();
      setIsFocused(false);
    } else if (event.key === 'Enter' && !isAutocompleteHovered) {
      event.preventDefault();
      editor?.commands.blur();
      editorRef.current?.blur();
    }
  };

  const replaceTextWithFormulaName = (option: SelectType): void => {
    editor?.commands.deleteRange({
      from: editor.state.selection.from - searchValue.length,
      to: editor.state.selection.from,
    });

    editor?.commands.insertContentAt(editor.state.selection.from, {
      type: 'basicFormulaNode',
      attrs: {
        label: option.label,
        formulaUuid: option.value,
      },
    });

    const editorContent: undefined | IEditorContent[] = editor?.getJSON().content?.[0].content as
      | IEditorContent[]
      | undefined;
    if (editorContent && editorContent.length === 1) {
      isValid.current = true;
      setErrorMessage('');
      onValidationChange?.(true);
    } else if (editorContent && editorContent.length > 1) {
      isValid.current = false;
      setErrorMessage('Only one value allowed');
      onValidationChange?.(false);
    }

    setSearchValue('');

    editor?.commands.focus(editor.state.selection.from);
    setIsFocused(true);
  };

  return (
    <div className="flex flex-col gap-1">
      <div className={`bg-white sticky z-10 top-0 rounded flex justify-between border border-neutral-50`}>
        <div className={`flex-1 overflow-hidden bg-transparent cursor-text`}>
          <EditorContent
            placeholder="hi"
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            editor={editor}
            className="bg-white rounded overflow-x-auto no-scrollbar"
          />
          {searchValue && isFocused && (
            <Autocomplete
              editor={editor}
              searchValue={searchValue}
              position={{ top: autocompletePosition.top, left: autocompletePosition.left }}
              selectOptions={[
                ...Object.values(formulaDictionary)
                  .filter((formula) => formula.type === IFormulaTypeEnum.ModelBuilder)
                  .map((formula) => ({
                    label: formula.recipe.name,
                    value: formula.formulaUuid,
                  })),
              ]}
              onSelect={replaceTextWithFormulaName}
              onFocus={() => setIsAutocompleteHovered(true)}
              onBlur={() => setIsAutocompleteHovered(false)}
            />
          )}
        </div>
      </div>
      {!isValid.current && (
        <Typography size="xs" color="warning" className="ml-1">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default MinMaxInput;
