import React, { useEffect } from 'react';
import PeriodPicker from '~/components/PeriodPicker';
import Typography from '~/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import usePeriodPicker from '../PeriodPicker/usePeriodPicker';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';
import { setPreferences } from '~/store/userSlice';
import * as stringDate from '~/utils/stringDate';

const UserDateRange = ({ pickerAlignment = 'left' }: { pickerAlignment?: 'left' | 'right' }): React.ReactElement => {
  const dispatch = useDispatch();
  const { preferences, uuid } = useSelector((state: State) => state.user);
  const { companyStartDate, monthsOutToForecast } = useSelector((state: State) => state.organization.configuration);

  const [startDate, setStartDate] = usePeriodPicker({
    startDate: preferences.defaultGraphStartDate,
    endDate: stringDate.getStringDate(),
    mode: 'month',
  });

  const [endDate, setEndDate] = usePeriodPicker({
    startDate: preferences.defaultGraphEndDate,
    endDate: stringDate.getStringDate(),
    mode: 'month',
  });

  useEffect(() => {
    const updateDates = async (): Promise<void> => {
      const formattedStartDate = startDate.startDate ?? stringDate.getStringDate();
      const formattedEndDate = endDate.startDate ?? stringDate.getStringDate();

      if (
        (!stringDate.isSameMonth({ date1: formattedStartDate, date2: preferences.defaultGraphStartDate }) ||
          !stringDate.isSameMonth({ date1: formattedEndDate, date2: preferences.defaultGraphEndDate })) &&
        uuid
      ) {
        const response = await request({
          url: `/users/${uuid}/preferences`,
          method: 'PATCH',
          body: {
            defaultGraphStartDate: formattedStartDate,
            defaultGraphEndDate: formattedEndDate,
          },
        });

        if (response.status === StatusCodes.OK) {
          dispatch(
            setPreferences({
              ...preferences,
              defaultGraphStartDate: formattedStartDate,
              defaultGraphEndDate: formattedEndDate,
            }),
          );
        }
      } else if (
        formattedStartDate !== preferences.defaultGraphStartDate ||
        formattedEndDate !== preferences.defaultGraphEndDate
      ) {
        dispatch(
          setPreferences({
            ...preferences,
            defaultGraphStartDate: formattedStartDate,
            defaultGraphEndDate: formattedEndDate,
          }),
        );
      }
    };

    updateDates();
  }, [startDate.startDate, endDate.startDate, dispatch]);

  useEffect(() => {
    if (
      startDate.startDate &&
      !stringDate.isSameMonth({ date1: preferences.defaultGraphStartDate, date2: startDate.startDate })
    ) {
      setStartDate({
        startDate: preferences.defaultGraphStartDate,
        endDate: stringDate.getStringDate(),
        mode: 'month',
      });
    }
    if (
      endDate.startDate &&
      !stringDate.isSameMonth({ date1: preferences.defaultGraphEndDate, date2: endDate.startDate })
    ) {
      setEndDate({
        startDate: preferences.defaultGraphEndDate,
        endDate: stringDate.getStringDate(),
        mode: 'month',
      });
    }
  }, [preferences.defaultGraphStartDate, preferences.defaultGraphEndDate]);

  return (
    <div className="flex items-center gap-2">
      <PeriodPicker
        id="user-date-range-start-date"
        state={startDate}
        setState={setStartDate}
        border={'solid'}
        beBefore={endDate.startDate}
        minYear={stringDate.getYear(companyStartDate)}
        maxYear={stringDate.getYear(stringDate.addMonths(stringDate.getStringDate(), monthsOutToForecast))}
        pickerAlignment={pickerAlignment}
      />
      <Typography size="xl" color="empty" className="pb-[1px]">
        -
      </Typography>
      <PeriodPicker
        id="user-date-range-end-date"
        state={endDate}
        setState={setEndDate}
        border={'solid'}
        beAfter={startDate.startDate}
        beBefore={stringDate.addMonths(stringDate.getStringDate(), monthsOutToForecast)}
        minYear={stringDate.getYear(companyStartDate)}
        maxYear={stringDate.getYear(stringDate.addMonths(stringDate.getStringDate(), monthsOutToForecast))}
        pickerAlignment={pickerAlignment}
      />
    </div>
  );
};

export default UserDateRange;
