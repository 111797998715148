import * as api from '~/services/parallel/index';

export const updateScenario = async ({
  scenarioUuid,
  organizationUuid,
  formulaUuid,
  overrides,
  actuals,
}: {
  scenarioUuid: string | null;
  organizationUuid: string;
  formulaUuid: string;
  overrides: {
    value: number;
    date: string;
  }[];
  actuals: {
    value: number;
    date: string;
  }[];
}): Promise<void> => {
  await api.formulas.update({
    organizationUuid,
    formulaUuid,
    overrides,
    actuals,
    scenarioUuid: scenarioUuid ?? undefined,
    createScenario: scenarioUuid ? undefined : true,
  });
};
