import { ExpenseFrequencyEnum } from '~/pages/Expenses/components/types';
import * as stringDate from '~/utils/stringDate';

export const formatEndDateForTable = ({
  endDate,
  frequency,
}: {
  endDate: string | null;
  frequency: ExpenseFrequencyEnum;
}): string => {
  if (endDate) {
    return stringDate.format(endDate, 'MMMM yyyy');
  }

  return frequency === ExpenseFrequencyEnum.Monthly ||
    frequency === ExpenseFrequencyEnum.Annually ||
    frequency === ExpenseFrequencyEnum.Quarterly
    ? '∞'
    : '';
};
