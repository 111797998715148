import React from 'react';
import Typography from '~/components/Typography';
import logoNeutral from '~/assets/logo-neutral.svg';
import { getProposedCardAcronym } from '../../utils/getProposedCardAcronym';

const ProposedCardLoadingState = ({ name }: { name: string }): React.ReactNode => {
  return (
    <div className="flex flex-col border border-neutral-50 rounded-lg bg-neutral-15">
      <div className="flex flex-row w-full px-6 py-4 items-center">
        <div className="max-h-[46px] max-w-[46px] min-h-[46px] min-w-[46px] text-[16.5px] text-neutral-200 bg-neutral-15 border border-neutral-50 rounded-full flex items-center justify-center mr-4">
          {getProposedCardAcronym(name)}
        </div>
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-col w-1/3">
            <Typography weight="semibold">{name}</Typography>
            <Typography color="secondary">Rebuilding Forecast...</Typography>
          </div>
          <img src={logoNeutral} className="size-6" />
        </div>
      </div>
    </div>
  );
};

export default ProposedCardLoadingState;
