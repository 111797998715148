import React from 'react';
import useFormulaContext from '../context/useFormulaContext';
import Input from '~/components/Input/InputWrapper';

const SearchInput = (): React.ReactElement => {
  const { searchFilter, setSearchFilter } = useFormulaContext();

  return (
    <div className="w-[230px] py-2 ml-2">
      <Input id="search-formulas" state={searchFilter} setState={setSearchFilter} type="search" placeholder="Search" />
    </div>
  );
};

export default SearchInput;
