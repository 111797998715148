import Typography from '~/components/Typography';
import React from 'react';

export const ReadableFormulaDisplay = ({ formula }: { formula: string }): React.ReactNode => {
  const parseFormula = (readableFormulaExpression: string): React.ReactNode[] => {
    const operators = ['+', '-', '*', '/', '(', ')'];
    const parts = readableFormulaExpression.split(' ');
    const result = [];
    let currentVariable: string[] = [];

    parts.forEach((part) => {
      const isOperator = operators.includes(part);
      const isNumber = !isNaN(Number(part));

      if (!isOperator && !isNumber) {
        currentVariable.push(part);
      } else {
        if (currentVariable.length > 0) {
          result.push(
            <div className="bg-neutral-500 border border-neutral-500 py-0.5 rounded-full mx-1">
              <Typography color="white" size="sm">
                {currentVariable.join(' ')}
              </Typography>
            </div>,
          );
          currentVariable = [];
        }
        if (isOperator) {
          result.push(
            <Typography color="white" size="sm" className="px-1">
              {part}
            </Typography>,
          );
        } else {
          result.push(
            <Typography color="white" size="sm">
              {part}
            </Typography>,
          );
        }
      }
    });

    if (currentVariable.length > 0) {
      result.push(
        <div className="bg-neutral-500 border border-neutral-500 px-2 py-0.5 rounded-full mx-1">
          <Typography color="white" size="sm">
            {currentVariable.join(' ')}
          </Typography>
        </div>,
      );
    }

    return result;
  };

  return <div className="flex flex-row items-center flex-nowrap whitespace-nowrap">{parseFormula(formula)}</div>;
};
