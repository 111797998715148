import { ZConversation } from '~/pages/Dashboard/entity/schemas';
import { IConversation } from '~/pages/Dashboard/entity/types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export const getConversations = async ({
  organizationUuid,
}: {
  organizationUuid: string;
}): Promise<IConversation[]> => {
  const response = (await request({
    url: `/chats/conversations`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;
  return ZConversation.array().parse(response.data.data);
};

export const getConversation = async ({
  organizationUuid,
  conversationUuid,
}: {
  organizationUuid: string;
  conversationUuid: string;
}): Promise<IConversation> => {
  const response = (await request({
    url: `/chats/conversations/${conversationUuid}`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;
  return ZConversation.parse(response.data.data);
};

export const sendMessage = async ({
  organizationUuid,
  conversationUuid,
  message,
}: {
  organizationUuid: string;
  conversationUuid: string | null;
  message: string;
}): Promise<IConversation> => {
  const response = (await request({
    url: `/chats/conversations${conversationUuid ? `/${conversationUuid}` : ''}`,
    method: 'POST',
    headers: { 'Organization-Uuid': organizationUuid },
    body: { message },
  })) as IAPIResponse;
  return ZConversation.parse(response.data.data);
};
