import React from 'react';
import Lottie from 'lottie-react';
import greenLoadingDots from './greenLoadingDots.json';
import { SparklesIcon } from '@heroicons/react/24/outline';

const ResponseLoading = (): React.ReactElement => {
  return (
    <div className="w-full flex justify-start items-center gap-4">
      <div className="size-8 rounded-full bg-green-25 flex items-center justify-center">
        <SparklesIcon className="size-5 text-green-500" />
      </div>
      <Lottie animationData={greenLoadingDots} />
    </div>
  );
};

export default ResponseLoading;
