import React, { useContext } from 'react';
import ParallelLogo from '~/assets/parallelLogoIcon.svg';
import Typography from '~/components/Typography';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ChatContext } from '../../context/ChatContext';

const ErrorMessage = ({
  message,
  previousMessage,
  conversationUuid,
}: {
  message: string;
  previousMessage: string;
  conversationUuid: string | null;
}): React.ReactElement => {
  const { sendMessage } = useContext(ChatContext);

  return (
    <div className="w-full flex justify-start items-center gap-4">
      <div className="size-8 rounded-full bg-green-25 flex items-center justify-center">
        <img src={ParallelLogo} alt="parallel-logo" className="size-5" />
      </div>
      <div className="flex items-center gap-2">
        <div className="max-w-full w-fit bg-neutral-25 border border-neutral-50 rounded-xl px-4 py-3">
          <Typography size="sm" color="secondary">
            {message}
          </Typography>
        </div>
        <ArrowPathIcon
          className="size-5 text-green-400 cursor-pointer"
          onClick={() => sendMessage({ conversationUuid, message: previousMessage })}
        />
      </div>
    </div>
  );
};

export default ErrorMessage;
