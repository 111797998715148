import React from 'react';
import emptyConversationChatIcon from '~/assets/emptyConversationChatIcon.svg';
import Typography from '~/components/Typography';

const EmptyState = (): React.ReactElement => {
  return (
    <div className="flex flex-col items-center justify-center h-full pb-10 gap-4">
      <img src={emptyConversationChatIcon} alt="empty conversation chat icon" />
      <div className="flex flex-col">
        <Typography size="sm" weight="semibold" className="text-center">
          Welcome to Parallel Copilot
        </Typography>
        {/* <Typography size="sm" color="secondary" className="text-center">
          Explore your model, create scenarios, update your data, or handle any other finance-related tasks
        </Typography> */}
      </div>
    </div>
  );
};

export default EmptyState;
