import { IContract, IColumnIdEnum } from '../entity/types';
import * as stringDate from '~/utils/stringDate';

export const sortContracts = ({
  contracts,
  sortingState,
}: {
  contracts: IContract[];
  sortingState: { columnId: IColumnIdEnum; desc: boolean };
}): IContract[] => {
  const { columnId, desc } = sortingState;

  const sortOrder = desc ? -1 : 1;

  switch (columnId) {
    case IColumnIdEnum.Customer:
      return contracts.sort((a, b) => {
        return sortOrder * a.contractTitle.localeCompare(b.contractTitle);
      });

    case IColumnIdEnum.BillingSchedule:
      return contracts.sort((a, b) => {
        return sortOrder * a.billingSchedule.localeCompare(b.billingSchedule);
      });

    case IColumnIdEnum.AnnualValue:
      return contracts.sort((a, b) => {
        return sortOrder * (a.annualValue - b.annualValue);
      });

    case IColumnIdEnum.SetupFee:
      return contracts.sort((a, b) => {
        const aSetupFee = a.setupFee ?? 0;
        const bSetupFee = b.setupFee ?? 0;
        return sortOrder * (aSetupFee - bSetupFee);
      });

    case IColumnIdEnum.InvoiceDate:
      return contracts.sort((a, b) => {
        return sortOrder * stringDate.sortAsc(a.invoiceDate, b.invoiceDate);
      });

    case IColumnIdEnum.StartDate:
      return contracts.sort((a, b) => {
        return sortOrder * stringDate.sortAsc(a.startDate, b.startDate);
      });

    case IColumnIdEnum.EndDate:
      return contracts.sort((a, b) => {
        if (a.endDate && b.endDate) {
          return sortOrder * stringDate.sortAsc(a.endDate, b.endDate);
        } else if (a.endDate) {
          return sortOrder * -1;
        } else if (b.endDate) {
          return sortOrder * 1;
        }
        return 0;
      });

    default:
      return contracts;
  }
};
