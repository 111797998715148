import React, { useEffect, useRef, useState } from 'react';
import Typography from '~/components/Typography';
import { formatYValue } from '../utils/formatYValue';
import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import { createPortal } from 'react-dom';
import * as stringDate from '~/utils/stringDate';

const DraggableGraphTooltip = ({
  label,
  values,
  x,
  y,
  formatType,
  yMax,
  yMin,
}: {
  label: number;
  values: (number | null)[];
  x: number;
  y: number;
  formatType: IFormattingEnum;
  yMax: number;
  yMin: number;
}): React.ReactElement => {
  const [tooltipWidth, setTooltipWidth] = useState<number>(0);
  const [tooltipHeight, setTooltipHeight] = useState<number>(0);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipWidth(tooltipRef.current.clientWidth);
      setTooltipHeight(tooltipRef.current.clientHeight);
    }
  }, [tooltipRef, values]);

  return createPortal(
    <div
      className="bg-white ml-2 rounded-lg border border-neutral-75 py-1 px-4 fixed z-40"
      style={{
        left: x - 20 - tooltipWidth,
        top: y + tooltipHeight / 4,
      }}
      ref={tooltipRef}
    >
      <Typography color="secondary" size="xs">
        {stringDate.format(stringDate.getStringDate(new Date(label)), "MMM 'yy")}
      </Typography>
      <div className="flex items-center justify-between">
        {values.map((value: number | null, index: number) => {
          if (typeof value === 'number') {
            return (
              <React.Fragment key={index.toString() + ':' + value.toString()}>
                <Typography size="sm" weight="bold" color={index === 0 ? undefined : 'blue'} className="mr-1">
                  {formatYValue({ value, formatType, yMax, yMin })}
                </Typography>
                {index !== values.length - 1 && typeof values[index + 1] === 'number' && (
                  <Typography size="sm" color="disabled" className="mr-1">
                    |
                  </Typography>
                )}
              </React.Fragment>
            );
          }
          return null;
        })}
      </div>
    </div>,
    document.body,
    'draggable-graph-tooltip',
  );
};

export default DraggableGraphTooltip;
