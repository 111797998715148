import React, { useEffect, useMemo, useRef, useState } from 'react';
import Typography from '~/components/Typography';
import TodayIndicator from './TodayIndicator';
import { ISelection, ISelectionMode, TimelineContext } from './TimelineContext';
import { SPACE_PER_MONTH } from './utils/constants';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

interface IProps {
  startDateBoundary: IStringDate;
  endDateBoundary: IStringDate;
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
  availableSelections?: string[];
  onChange: ({ positionIds, daysToAdd }: { positionIds: string[]; daysToAdd: number }) => void;
  onSave: ({ positionIds }: { positionIds: string[] }) => void;
}

const TimelineContainer = React.forwardRef(
  (
    {
      startDateBoundary,
      endDateBoundary,
      children,
      className = '',
      availableSelections = [],
      onChange,
      onSave,
    }: IProps,
    forwardedRef: React.Ref<HTMLDivElement>,
  ): React.ReactElement => {
    const PIXELS_PER_MONTH = SPACE_PER_MONTH * 16;
    const [daysToAdd, setDaysToAdd] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(
      (stringDate.differenceInCalendarMonths({
        startDate: startDateBoundary,
        endDate: stringDate.getStringDate(),
      }) -
        1) *
        PIXELS_PER_MONTH,
    );
    const [scrollPositionOnStartDrag, setScrollPositionOnStartDrag] = useState(scrollPosition);
    const [dragStartLocation, setDragStartLocation] = useState(0);
    const lastDragCalculation = useRef(0);

    const [selection, setSelection] = useState<ISelection>({
      selected: [],
      available: availableSelections,
      lastSelected: null,
    });
    const containerRef = forwardedRef as React.MutableRefObject<HTMLDivElement>;

    const monthsToRender = useMemo(() => {
      const monthCount =
        stringDate.differenceInCalendarMonths({ startDate: startDateBoundary, endDate: endDateBoundary }) + 1;
      const dates = Array.from({ length: monthCount }).map((_, index) => {
        return stringDate.addMonths(stringDate.startOfMonth(startDateBoundary), index);
      });

      return dates;
    }, [startDateBoundary, endDateBoundary]);

    useEffect(() => {
      if (forwardedRef) {
        (forwardedRef as React.MutableRefObject<HTMLDivElement>).current.scrollTo({
          left: scrollPosition,
        });
      }
    }, []);

    useEffect(() => {
      setSelection((prevState) => ({
        ...prevState,
        available: availableSelections,
      }));
    }, [availableSelections]);

    // Create a cusion on the edge of the container so that it's easier to scroll while dragging
    useEffect(() => {
      const handleDrag = (event: MouseEvent): void => {
        const { clientX } = event;
        const { left, right } = containerRef.current.getBoundingClientRect();
        const scrollSpeed = 2; // Adjust scroll speed as needed

        if (clientX - left < 100) {
          containerRef.current.scrollLeft -= scrollSpeed;
        } else if (right - clientX < 100) {
          containerRef.current.scrollLeft += scrollSpeed;
        }
      };

      document.addEventListener('dragover', handleDrag);

      return () => {
        document.removeEventListener('dragover', handleDrag);
      };
    }, [containerRef]);

    // Click detection to detect clicks outside of the timeline nodes
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent): void => {
        const target = event.target as HTMLElement;

        // Check if the click happened inside any list item
        if (!target.closest('.timeline-node')) {
          setSelection((prevState) => ({
            ...prevState,
            selected: [],
            lastSelected: null,
          }));
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
          setSelection((prevState) => ({
            ...prevState,
            selected: [],
            lastSelected: null,
          }));
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
      const newScrollPosition = event.currentTarget.scrollLeft;
      setScrollPosition(newScrollPosition);
    };

    const toggleSelectedNode = ({ id, mode }: { id: string; mode: ISelectionMode }): void => {
      if (mode === ISelectionMode.SINGLE) {
        if (selection.selected.includes(id)) {
          setSelection((prevState) => ({
            ...prevState,
            selected: [],
            lastSelected: null,
          }));
        } else {
          setSelection((prevState) => ({
            ...prevState,
            selected: [id],
            lastSelected: id,
          }));
        }
      } else if (mode === ISelectionMode.CONTIGUOUS) {
        setSelection((prevState) => {
          const previousSelectedIndex = prevState.available.findIndex((nodeId) => nodeId === prevState.lastSelected);
          const newSelectedIndex = prevState.available.findIndex((nodeId) => nodeId === id);

          const [start, end] = [previousSelectedIndex, newSelectedIndex].sort((a, b) => a - b);
          const newSelected = prevState.available.slice(start, end + 1);
          const mergedSelected = Array.from(new Set([...prevState.selected, ...newSelected]));
          return {
            ...prevState,
            selected: mergedSelected,
            lastSelected: id,
          };
        });
      } else {
        setSelection((prevState) => {
          if (selection.selected.includes(id)) {
            return {
              ...prevState,
              selected: prevState.selected.filter((nodeId) => nodeId !== id),
              lastSelected: null,
            };
          } else {
            return {
              ...prevState,
              selected: [...prevState.selected, id],
              lastSelected: id,
            };
          }
        });
      }
    };

    /**
     * Cross browser drag image
     */
    const img = document.createElement('img');
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

    const handleDragStart = ({ event }: { event: React.DragEvent<HTMLButtonElement> }): void => {
      event.dataTransfer.setDragImage(img, 0, 0);
      lastDragCalculation.current = 0;
      setDragStartLocation(event.clientX);
      setScrollPositionOnStartDrag(scrollPosition);
    };

    const handleDrag = ({
      event,
      startDate,
    }: {
      event: React.DragEvent<HTMLButtonElement>;
      startDate: IStringDate;
    }): void => {
      // The last drag event returns a clientX of 0, so we need to skip that
      if (event.clientX === 0) return;

      lastDragCalculation.current = event.clientX;
      const REM_BASE = 16;
      const amountScrolled = scrollPosition - scrollPositionOnStartDrag;
      const daysPerMonth = 30;
      const pixelsPerDay = (SPACE_PER_MONTH / daysPerMonth) * REM_BASE;
      const daysDelta = Math.round((event.clientX - dragStartLocation + amountScrolled) / pixelsPerDay);

      // Don't allow the user to drag outside of the timeline
      if (stringDate.isBefore({ dateToCheck: startDate, comparison: startDateBoundary }) && daysDelta < 0) return;
      if (stringDate.isAfter({ dateToCheck: startDate, comparison: endDateBoundary }) && daysDelta > 0) return;

      onChange({ positionIds: selection.selected, daysToAdd: daysDelta });
    };

    const handleDragEnd = (): void => {
      setScrollPositionOnStartDrag(scrollPosition);
      onSave({ positionIds: selection.selected });

      setDaysToAdd(0);

      // Clear select if there's only one node selected
      if (selection.selected.length === 1) {
        setSelection((prevState) => ({
          ...prevState,
          selected: [],
          lastSelected: null,
        }));
      }
    };

    return (
      <TimelineContext.Provider
        value={{
          containerStartDate: startDateBoundary,
          containerEndDate: endDateBoundary,
          scrollPosition,
          selection,
          toggleSelectedNode,
          handleDragStart,
          handleDrag,
          handleDragEnd,
          draggingDaysToAdd: daysToAdd,
        }}
      >
        <div
          data-testid="timeline-container"
          className={`h-full border rounded-lg overflow-auto flex flex-col bg-neutral-15  ${className}`}
          ref={containerRef}
          onScroll={handleScroll}
        >
          <header className="flex flex-nowrap border-b w-fit flex-shrink-0 h-14 sticky top-0 bg-white z-20">
            {monthsToRender.map((month: IStringDate, index: number) => (
              <div
                key={stringDate.format(month, 'mm/dd/yyyy')}
                className="relative flex justify-center items-center h-[3.25rem]"
                style={{
                  minWidth: `${SPACE_PER_MONTH}rem`,
                }}
              >
                {/* MONTH LABEL: short named */}
                <Typography size="xs">{stringDate.format(month, 'MMM')}</Typography>
                {/* YEAR LABEL: keyed off of January and moved to precede it */}
                {stringDate.getMonth(month) === 1 && index !== 0 && (
                  <div className="absolute -left-2">
                    <Typography size="xs" color="disabled">
                      {`'${stringDate.format(month, 'yy')}`}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </header>
          <section className="flex-grow relative bg-neutral-15 py-8 w-fit">
            {/* MONTH DIVIDERS */}
            {monthsToRender.map((month: IStringDate, index: number) => (
              <div
                key={`${stringDate.format(month, 'mm/dd/yyyy')}-divider`}
                className={`absolute z-0 top-0 h-full w-[1px] bg-neutral-50`}
                style={{
                  left: `${SPACE_PER_MONTH + SPACE_PER_MONTH * index}rem`,
                }}
              />
            ))}
            {/* TODAY INDICATOR */}
            <TodayIndicator startDate={startDateBoundary} />
            <div className="flex flex-col gap-1 relative min-w-full" data-testid="timeline-nodes">
              {/* NODES */}
              {children}
            </div>
          </section>
        </div>
      </TimelineContext.Provider>
    );
  },
);

TimelineContainer.displayName = 'TimelineContainer';

export default TimelineContainer;
