import { IStringDate } from './types';

export const isAfter = ({
  comparison,
  dateToCheck,
}: {
  comparison: IStringDate;
  dateToCheck: IStringDate;
}): boolean => {
  const comparisionYear = parseInt(comparison.split('-')[0]);
  const dateToCheckYear = parseInt(dateToCheck.split('-')[0]);

  if (comparisionYear === dateToCheckYear) {
    const comparisionMonth = parseInt(comparison.split('-')[1]);
    const dateToCheckMonth = parseInt(dateToCheck.split('-')[1]);

    if (comparisionMonth === dateToCheckMonth) {
      const comparisionDay = parseInt(comparison.split('-')[2]);
      const dateToCheckDay = parseInt(dateToCheck.split('-')[2]);

      return dateToCheckDay > comparisionDay;
    }

    return dateToCheckMonth > comparisionMonth;
  }

  return dateToCheckYear > comparisionYear;
};
