import React, { useEffect } from 'react';
import Input, { useInput } from '~/components/Input/InputWrapper';
import Modal from '~/components/Modal';
import Select, { useSelect } from '~/components/Select';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { addMonths, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import PeriodPicker from '~/components/PeriodPicker';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';
import { IShareLink } from '~/pages/ShareLink/models/types';
import { IAPIResponse } from '~/utils/types';

const ModelSelectLabel = ({ label, type }: { label: string; type: 'Scenario' | 'Base' | 'Plan' }): React.ReactNode => {
  return (
    <div className="flex items-center gap-2">
      <div
        className={`py-1 px-2 rounded ${
          type === 'Scenario' ? 'bg-blue-15' : type === 'Plan' ? 'bg-green-15' : 'bg-orange-25'
        }`}
      >
        <Typography color={type === 'Scenario' ? 'blue' : type === 'Plan' ? 'green' : 'orange'} size="xs">
          {type}
        </Typography>
      </div>
      <Typography>{label}</Typography>
    </div>
  );
};

const CreateShareLinkModal = ({
  scenarios,
  isOpen,
  setIsOpen,
  setShareLinks,
  shareLink,
  shareLinks,
}: {
  scenarios: IScenario[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setShareLinks: React.Dispatch<React.SetStateAction<IShareLink[]>>;
  shareLink?: IShareLink;
  shareLinks: IShareLink[];
}): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const organizationName = useSelector((state: State) => state.organization.name);
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const filteredScenarios = scenarios.filter((scenario) => scenario.type !== 'temporaryStatic');
  const [title, setTitle, resetTitle] = useInput({ errorMessage: 'Description must be included and be unique' });
  const [model, setModel, resetModel] = useSelect({
    options: [
      { label: null, node: <ModelSelectLabel label="Base" type="Base" />, value: null },
      ...filteredScenarios.map((scenario) => ({
        label: null,
        node: (
          <ModelSelectLabel
            label={scenario.changeDescription}
            type={scenario.type === 'dynamic' ? 'Scenario' : 'Plan'}
          />
        ),
        value: scenario.uuid,
      })),
    ],
  });
  const [expiration, setExpiration, resetExpiration] = useSelect({
    options: [{ label: '1 Month', value: format(addMonths(new Date(), 1), 'yyyy-MM-dd') }],
    selected: { label: '1 Month', value: format(addMonths(new Date(), 1), 'yyyy-MM-dd') },
  });
  const [startDate, setStartDate] = usePeriodPicker({
    startDate: defaultGraphStartDate,
    endDate: null,
    mode: 'month',
  });
  const [endDate, setEndDate] = usePeriodPicker({
    startDate: defaultGraphEndDate,
    endDate: null,
    mode: 'month',
  });

  const resetForm = (): void => {
    resetTitle();
    resetModel();
    resetExpiration();
    setStartDate((prev) => ({ ...prev, startDate: defaultGraphStartDate }));
    setEndDate((prev) => ({ ...prev, startDate: defaultGraphEndDate }));
  };

  const handleCreate = async (): Promise<void> => {
    try {
      let valid = true;

      if (!title.value) {
        valid = false;
        setTitle((prev) => ({ ...prev, valid: false, pristine: false, touched: true }));
      }

      if (shareLinks.some((link) => link.title === title.value && link.uuid !== shareLink?.uuid)) {
        valid = false;
        setTitle((prev) => ({
          ...prev,
          valid: false,
          pristine: false,
          touched: true,
        }));
      }

      if (!model.selected?.value && !model.selected?.node) {
        valid = false;
        setModel((prev) => ({ ...prev, valid: false, pristine: false, touched: true }));
      }

      if (!valid) return;

      const response = (await request({
        url: `/share-link`,
        method: 'POST',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
        body: {
          title: title.value,
          scenarioUuid: model.selected?.value ?? null,
          allowedResources: {
            startDate: startDate.startDate,
            endDate: endDate.startDate,
          },
        },
      })) as IAPIResponse<IShareLink>;

      if (response.status === StatusCodes.CREATED) {
        toast.success('Share Link Created and Copied to Clipboard');
        setIsOpen(false);
        setShareLinks((prev) => [...prev, response.data.data]);
        resetForm();
      } else {
        toast.error('Failed to create link');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to create link');
    }
  };

  const handleEdit = async (): Promise<void> => {
    try {
      if (!shareLink) {
        logger.error(new Error('Share link was undefined when trying to edit'));
        return;
      }

      const response = (await request({
        url: `/share-link/${shareLink.uuid}`,
        method: 'PATCH',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
        body: {
          title: title.value,
          scenarioUuid: model.selected?.value,
          allowedResources: {
            startDate: startDate.startDate,
            endDate: endDate.startDate,
          },
        },
      })) as IAPIResponse<IShareLink>;

      if (response.status === StatusCodes.OK) {
        toast.success('Share Link Updated');
        setIsOpen(false);
        setShareLinks((prev) => prev.map((link) => (link.uuid === shareLink.uuid ? response.data.data : link)));
        resetForm();
      } else {
        toast.error('Failed to update link');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to update link');
    }
  };

  const handleSubmit = (): void => {
    shareLink ? handleEdit() : handleCreate();
  };

  useEffect(() => {
    if (shareLink) {
      setTitle((prev) => ({ ...prev, value: shareLink.title }));
      setModel((prev) => ({
        ...prev,
        selected: prev.options.find((option) => option.value === shareLink.scenarioUuid),
      }));
      setStartDate((prev) => ({
        ...prev,
        startDate: shareLink.allowedResources.startDate,
      }));
      setEndDate((prev) => ({
        ...prev,
        startDate: shareLink.allowedResources.endDate,
      }));
    } else {
      resetForm();
    }
  }, [shareLink]);

  const handleClickPreview = (): void => {
    let valid = true;

    if (!title.value) {
      valid = false;
      setTitle((prev) => ({ ...prev, valid: false, pristine: false, touched: true }));
    }

    if (!model.selected?.value && !model.selected?.node) {
      valid = false;
      setModel((prev) => ({ ...prev, valid: false, pristine: false, touched: true }));
    }
    if (!startDate.startDate || !endDate.startDate) return;

    if (!valid) return;

    const link = `${window.location.origin}/preview?startDate=${startDate.startDate}&endDate=${endDate.startDate}&organizationName=${organizationName}${model.selected?.value ? `&scenarioUuid=${model.selected.value}` : ''}`;
    window.open(link, '_blank');
  };

  return (
    <Modal isOpen={isOpen} onClose={resetForm} title={shareLink ? 'Edit Link' : 'Create New Link'} size="xs">
      <div className="flex flex-col gap-4 w-full mt-4">
        <Input
          id="title"
          state={title}
          setState={setTitle}
          placeholder="eg, Raise Plan, Q4 All-hands Meeting"
          label="Description"
        />
        <Select
          id="model"
          state={model}
          setState={setModel}
          checkmark={false}
          placeholder="Please Select"
          activeBackgroundColor="bg-neutral-15"
          label="Model"
        />
        <Select id="expiration" state={expiration} setState={setExpiration} disabled={true} label="Link Expiration" />
        <div className="flex flex-col gap-1">
          <Typography size="xs">Date Range</Typography>
          <div className="flex gap-2 items-center">
            <PeriodPicker id="startDate" state={startDate} setState={setStartDate} beBefore={endDate.startDate} />
            <Typography color="secondary">-</Typography>
            <PeriodPicker id="endDate" state={endDate} setState={setEndDate} beAfter={startDate.startDate} />
          </div>
        </div>
        <div className="flex w-full items-center justify-between mt-4">
          <Button className="!w-fit !px-0" fill="clear" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <div className="flex gap-2">
            <Button className="!w-fit" fill="outline" onClick={handleClickPreview}>
              Preview
            </Button>
            <Button className="!w-fit" onClick={handleSubmit}>
              {shareLink ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateShareLinkModal;
