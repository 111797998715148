import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import React, { ReactElement, useMemo } from 'react';
import FormulasTable from '~/components/Formulas/FormulasTable';
import Typography from '~/components/Typography';
import CollapsableGroup from '~/components/Formulas/CollapsableGroup/CollapsableGroup';
import ModelBuilderExpression from '~/components/Formulas/ExpressionBuilder';
import MonthCell from '~/components/Formulas/MonthValueEditor/MonthCell';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';
import { createMonthArrayBetweenDates } from '~/utils/dates/createMonthArrayBetweenDates';
import { getFilteredFormulaGroups } from '../../utils/getFilteredFormulaGroups';
import { IStringDate } from '~/utils/stringDate/types';
import { IColumn } from '~/components/Formulas/FormulasTable/types';
import DraggableLabel from '~/components/Formulas/DraggableLabel';

const ShareLinkFormulasTable = ({
  permissionedFormulaUuids,
  startDate,
  endDate,
}: {
  permissionedFormulaUuids: string[];
  startDate: IStringDate;
  endDate: IStringDate;
}): React.ReactElement => {
  const { allFormulasData } = useFormulaContext();
  const monthsArray = createMonthArrayBetweenDates(toZonedTime(startDate, 'UTC'), toZonedTime(endDate, 'UTC'));
  const columns = useMemo<IColumn[]>(
    () => [
      {
        key: 'label',
        Header: ({ columnWidth }: { columnWidth: number; columnIndex: number }): React.ReactElement => (
          <div
            className={`flex sticky top-0 left-0 border-b bg-neutral-15 border-neutral-50 pl-4 pt-2 z-20 border-r`}
            style={{
              width: `${columnWidth}px`,
              minWidth: `${columnWidth}px`,
              boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
            }}
          >
            <div className="flex w-fit gap-2 py-1"></div>
          </div>
        ),
        Cell: DraggableLabel,
      },
      {
        key: 'formula',
        Cell: ModelBuilderExpression,
      },
      ...monthsArray.map((month) => ({
        key: `month-${month}`,
        Header: ({ columnWidth, columnIndex }: { columnWidth: number; columnIndex: number }): ReactElement => {
          const month = format(monthsArray[columnIndex - 2], 'MMM yyyy');
          const showYearDivider = month.includes('Dec');
          return (
            <div
              className={`flex justify-end items-end py-2 bg-neutral-15 relative border-b border-neutral-50 px-4${showYearDivider && ' border-r'}`}
              style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
            >
              <Typography size="xs" color="lightGray">
                {month.toUpperCase()}
              </Typography>
            </div>
          );
        },
        Cell: MonthCell,
      })),
    ],
    [monthsArray],
  );

  const tableData = useMemo(
    () => getFilteredFormulaGroups({ formulaGroups: allFormulasData, permissionedFormulaUuids }),
    [allFormulasData, permissionedFormulaUuids],
  );

  return (
    <div className="relative max-w-full overflow-auto border border-neutral-50 rounded-lg bg-neutral-15">
      <FormulasTable
        columns={columns}
        data={tableData}
        groupComponent={CollapsableGroup}
        onDragEnd={() => {}}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
};

export default ShareLinkFormulasTable;
