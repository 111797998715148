import { useCallback, useEffect, useState, useRef } from 'react';
import pageData from './pageData';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const useDashboardData = (): {
  loading: boolean;
  data: unknown | null;
  revalidate: () => Promise<void>;
} => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<unknown | null>(null);
  const [hasCompletedInitialFetch, setHasCompletedInitialFetch] = useState(false);
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector((state: State) => state.scenario);
  const { dashboardConfiguration } = useSelector((state: State) => state.organization.configuration);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchData = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const result = await pageData(abortController.signal);
      if (!abortController.signal.aborted) {
        setHasCompletedInitialFetch(true);
        setData(result);
      }
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!hasCompletedInitialFetch) {
      fetchData();
    }
  }, [hasCompletedInitialFetch, fetchData]);

  useEffect(() => {
    if (hasCompletedInitialFetch) {
      fetchData();
    }
  }, [
    hasCompletedInitialFetch,
    fetchData,
    defaultGraphStartDate,
    defaultGraphEndDate,
    activeScenarioUuid,
    selectedScenarioUuids,
    dashboardConfiguration,
  ]);

  return { loading, data, revalidate: fetchData };
};

export default useDashboardData;
