import React, { ReactElement, useMemo } from 'react';
import FormulasTable from '~/components/Formulas/FormulasTable';
import useFormulaContext from '../../../components/Formulas/context/useFormulaContext';
import GroupRow from '../../../components/Formulas/CollapsableGroup/CollapsableGroup';
import { IColumn } from '~/components/Formulas/FormulasTable/types';
import ModelBuilderExpression from '../../../components/Formulas/ExpressionBuilder';
import MonthCell from '../../../components/Formulas/MonthValueEditor/MonthCell';
import Typography from '~/components/Typography';
import ColumnResize from '~/components/Formulas/FormulasTable/ColumnResize';
import SearchInput from '~/components/Formulas/SearchInput/SearchInput';
import DisplayAttributes from './DisplayAttributes';
import HandleCollapseButton from './HandleCollapseButton';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import DraggableLabel from '~/components/Formulas/DraggableLabel';
import ManageGroupsFooter from './ManageGroupsFooter';

const FinancialModelTable = (): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { filteredFormulasData, onDragEnd, selectedMonths, searchFilter, scrollEnabled } = useFormulaContext();

  const columns = useMemo<IColumn[]>(() => {
    return [
      {
        key: 'label',
        Header: ({ columnWidth }: { columnWidth: number }): React.ReactElement => (
          <div
            className={`flex sticky top-0 left-0 border-b bg-white border-neutral-50 pl-4 pt-2 z-20 border-r`}
            style={{
              width: `${columnWidth}px`,
              minWidth: `${columnWidth}px`,
              boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
            }}
          >
            <div className="flex w-fit gap-2 py-1">
              <SearchInput />
              <HandleCollapseButton />
            </div>
            <ColumnResize columnIndex={0} />
          </div>
        ),
        Cell: DraggableLabel,
      },
      {
        key: 'formula',
        Cell: ModelBuilderExpression,
      },
      ...selectedMonths.map((month) => ({
        key: `month-${month}`,
        Header: ({ columnWidth, columnIndex }: { columnWidth: number; columnIndex: number }): ReactElement => {
          const LEADING_COLUMN_COUNT = 2;
          const month = selectedMonths[columnIndex - LEADING_COLUMN_COUNT];
          const showYearDivider = month.includes('Dec');
          return (
            <div
              className={`flex justify-end items-end py-2 bg-white relative border-b border-neutral-50 px-4${showYearDivider ? ' border-r' : ''}`}
              style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
            >
              <Typography size="xs" color="lightGray">
                {month.toUpperCase()}
              </Typography>
            </div>
          );
        },
        Cell: MonthCell,
      })),
    ];
  }, [selectedMonths]);

  return (
    <div
      className={`relative max-w-full flex flex-col justify-between h-[calc(100vh-64px)] ${scrollEnabled.x ? 'overflow-x-auto' : 'overflow-x-hidden overscroll-x-none'} ${scrollEnabled.y ? 'overflow-y-auto' : 'overflow-y-hidden overscroll-y-none'}`}
    >
      <FormulasTable
        columns={columns}
        data={filteredFormulasData}
        groupComponent={GroupRow}
        onDragEnd={onDragEnd}
        footerComponent={ManageGroupsFooter}
      />
      {searchFilter.value && (
        <div
          className={`sticky left-0 z-10 w-full h-[500px] min-h-[500px] ${activeScenarioUuid ? 'bottom-10' : 'bottom-0'}`}
        >
          <DisplayAttributes />
        </div>
      )}
    </div>
  );
};

export default FinancialModelTable;
