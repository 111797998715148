import { createContext, useContext } from 'react';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

export enum ISelectionMode {
  SINGLE = 'single',
  CONTIGUOUS = 'contiguous',
  NONCONTIGUOUS = 'non-contiguous',
}

export interface ISelection {
  selected: string[];
  available: string[];
  lastSelected: string | null;
}

export interface ITimelineContext {
  containerStartDate: IStringDate;
  containerEndDate: IStringDate;
  scrollPosition: number;
  setScrollPosition?: (scrollPosition: number) => void;
  selection: ISelection;
  toggleSelectedNode: ({ id, mode }: { id: string; mode: ISelectionMode }) => void;
  handleDragStart: ({ event }: { event: React.DragEvent<HTMLButtonElement> }) => void;
  handleDrag: ({ event, startDate }: { event: React.DragEvent<HTMLButtonElement>; startDate: IStringDate }) => void;
  handleDragEnd: () => void;
  draggingDaysToAdd: number;
}

export const TimelineContext = createContext<ITimelineContext>({
  containerStartDate: stringDate.getStringDate(),
  containerEndDate: stringDate.getStringDate(),
  scrollPosition: 0,
  setScrollPosition: () => {},
  selection: {
    selected: [],
    available: [],
    lastSelected: null,
  },
  toggleSelectedNode: () => {},
  handleDragStart: () => {},
  handleDrag: () => {},
  handleDragEnd: () => {},
  draggingDaysToAdd: 0,
});

export const useTimelineContext = (): ITimelineContext => useContext(TimelineContext);
