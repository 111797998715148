import React, { useState, useEffect, useRef } from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, AreaChart, Area } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import Typography from '../Typography';
import Card from '../Card';
import LineGraphTooltip from './LineGraphTooltip';
import LineGraphDot from './LineGraphDot';
import { format as formatDate, isAfter, isBefore, isSameMonth } from 'date-fns';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';
import generateTickValues from './utils/generateTickValues';
import calculateYAxisWidth from './utils/calculateYAxisWidth';
import { IDataArrayDictionary, ILockedIndex, ILineProps } from './entity/types';
import useIsMobile from '~/utils/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import date from '~/utils/dates/date';
import './styles.css';
import TimeboundGoalDot from './TimeboundGoalDot';
import { getEndOfUtcMonth } from '~/utils/dates/getEndOfUtcMonth';
import NonTimeboundGoalLabel from './NonTimeboundGoalLabel';
import { v4 } from 'uuid';

interface IProps {
  id?: string;
  xFormatter?: (value: number | null) => string;
  yFormatter?: (value: number | null) => string;
  setExternalActiveIndex?: (value: number) => void; // used to be able to sync a single active index across multiple graphs
  data?: IDataArrayDictionary[];
  dataKeys?: string[]; // Array of all keys that may appear in a data object, should be ordered XAxis, first line, second line, etc.
  lines?: ILineProps[];
  externalActiveIndex?: number; // leave undefined for standard tooltip behavior, setting it allows the tooltip to be displayed and synced across multiple graphs
  card?: {
    title: string | React.ReactElement;
    month?: string;
    figure?: string;
  };
  height?: string;
  lockedIndexes?: ILockedIndex[];
  onClick?: (index: number) => void;
  hoverCursorIcon?: React.ReactElement;
  hoverLockedIndexIcon?: React.ReactElement;
  isDashboardGraph?: boolean;
  roundTicksToMoney?: boolean;
  customTooltip?: React.ReactElement;
  showGradient?: boolean;
  cardClassName?: string;
  timeboundGoals?: { targetValue: number; targetDate: Date }[];
  nonTimeboundGoals?: { targetValue: number }[];
}

/**
 * Height and width of component are determined by the parent div
 */
const LineGraph = ({
  id,
  xFormatter = (value: number | null): string => {
    if (!value) return '';
    return formatDate(value, "MMM ''yy").toUpperCase();
  },
  yFormatter = (value: number | null): string => {
    return formatToLetterAbbreviatedNumber({ value: value ?? 0, decimal: !value || value < 10000 ? 0 : 1 });
  },
  setExternalActiveIndex,
  data = [],
  dataKeys = ['date', 'report0', 'report1'],
  lines = [
    { dataKey: 'report0', stroke: 'black', isDashed: false },
    { dataKey: 'report1', stroke: '#406F83', isDashed: true },
  ],
  card,
  lockedIndexes = [],
  onClick,
  hoverCursorIcon,
  hoverLockedIndexIcon,
  isDashboardGraph,
  roundTicksToMoney = true,
  customTooltip,
  showGradient = true,
  externalActiveIndex,
  cardClassName,
  timeboundGoals,
  nonTimeboundGoals,
}: IProps): React.ReactElement => {
  const [internalActiveIndex, setInternalActiveIndex] = React.useState<number>(-1);
  const [ticks, setTicks] = React.useState<number[]>([]);
  const [yAxisWidth, setYAxisWidth] = React.useState<number>(60);
  const [opacity, setOpacity] = useState(0);
  const [isMouseOnRight, setIsMouseOnRight] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const sideMenuExpanded = useSelector((state: State) => state.user.preferences.sideMenuExpanded);
  const graphRef = useRef<HTMLDivElement>(null);
  const [numOfIndexesTakenByLabels, setNumOfIndexesTakenByLabels] = useState<number>(0);
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(-1);

  const xKey = dataKeys[0];

  const xKeyDataWithoutNulls: number[] = data.filter((item) => item[xKey] !== null).map((item) => item[xKey] as number);
  const minX = Math.min(...(xKeyDataWithoutNulls.length ? xKeyDataWithoutNulls : [0]));
  const maxX = Math.max(...(xKeyDataWithoutNulls.length ? xKeyDataWithoutNulls : [0]));
  const yMax = data.reduce((largestValue, item) => {
    const filteredKeys = Object.keys(item).filter((key) => key !== dataKeys[0]);
    let filteredValues = filteredKeys.map((key) => item[key]).filter((value): value is number => value !== null);
    if (timeboundGoals) {
      filteredValues = [...filteredValues, ...timeboundGoals.map((goal) => goal.targetValue)];
    }
    if (nonTimeboundGoals) {
      filteredValues = [...filteredValues, ...nonTimeboundGoals.map((goal) => goal.targetValue)];
    }
    const maxValue = Math.max(...filteredValues);
    if (maxValue === 0 && largestValue === 0) {
      return 1;
    }
    return Math.max(largestValue, maxValue);
  }, 0);
  const yMin = data.reduce((smallestValue, item) => {
    const filteredKeys = Object.keys(item).filter((key) => key !== dataKeys[0]);
    let filteredValues = filteredKeys.map((key) => item[key]).filter((value): value is number => value !== null);
    if (timeboundGoals) {
      filteredValues = [...filteredValues, ...timeboundGoals.map((goal) => goal.targetValue)];
    }
    if (nonTimeboundGoals) {
      filteredValues = [...filteredValues, ...nonTimeboundGoals.map((goal) => goal.targetValue)];
    }
    const minValue = Math.min(...filteredValues);
    return Math.min(smallestValue, minValue);
  }, 0);
  const [timeboundGoalHovered, setTimeboundGoalHovered] = useState<{
    x: number;
    y: number;
    goal: number;
  } | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (opacity < 1) {
        setOpacity(opacity + 0.1);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [opacity]);

  useEffect(() => {
    setTicks(
      generateTickValues({
        yMin,
        yMax,
        tickCount: 3,
        isDashboardGraph,
        roundToMoney: roundTicksToMoney,
      }),
    );
  }, [data, yMin, yMax, isDashboardGraph]);

  useEffect(() => {
    setYAxisWidth(calculateYAxisWidth({ ticks, yFormatter }));
  }, [data, ticks, yFormatter]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent): void => {
      const WIDTH_OF_EXPANDED_SIDE_MENU = 250;
      const WIDTH_OF_COLLAPSED_SIDE_MENU = 72;
      const amountToAdd = sideMenuExpanded ? WIDTH_OF_EXPANDED_SIDE_MENU : WIDTH_OF_COLLAPSED_SIDE_MENU;
      const isOnRight = event.clientX > (window.innerWidth + amountToAdd) / 2;
      setIsMouseOnRight(isOnRight);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [sideMenuExpanded]);

  const handleMouseChange = (value: CategoricalChartState): void => {
    if (value.activeLabel) {
      const activeLabelInt = parseInt(value.activeLabel);
      const activeLabelIndex = data.map((item) => item[xKey]).indexOf(activeLabelInt);
      if (setExternalActiveIndex) setExternalActiveIndex(activeLabelIndex);
      setInternalActiveIndex(activeLabelIndex);
    } else {
      if (setExternalActiveIndex) setExternalActiveIndex(-1);
      setInternalActiveIndex(-1);
    }
  };

  const handleMouseLeave = (): void => {
    if (setExternalActiveIndex) setExternalActiveIndex(-1);
    setInternalActiveIndex(-1);
  };

  const handleClick = (): void => {
    onClick && onClick(internalActiveIndex);
  };

  const getReferenceLineColor = (index: number, dateAsNumber: number, currentActiveIndex: number): string => {
    if (index === currentActiveIndex) return '#FFFFFF';
    return new Date(dateAsNumber).getMonth() === 11 ? '#E6E6E6' : '#F4F4F4';
  };

  useEffect(() => {
    const updateWidth = (): void => {
      if (graphRef.current) {
        const WIDTH_OF_LABELS = 100;
        const graphWidth = graphRef.current.offsetWidth;
        const singleIndexWidth = graphWidth / data.length;
        const numOfIndexesTakenByLabels = Math.floor(WIDTH_OF_LABELS / singleIndexWidth);
        setNumOfIndexesTakenByLabels(numOfIndexesTakenByLabels);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (graphRef.current) {
      resizeObserver.observe(graphRef.current);
    }

    updateWidth();

    return () => {
      if (graphRef.current) {
        resizeObserver.unobserve(graphRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const today = date();
    const currentMonthIndex = data.findIndex((item) => {
      const itemDate = new Date(item[xKey] ?? 0);
      return isSameMonth(today, itemDate);
    });

    setCurrentMonthIndex(currentMonthIndex);
  }, [data, xKey]);

  const linesToRender = lines.filter((line) => data.some((item) => line.dataKey in item));

  const getGradientOffsetPercentage = (): string => {
    if (!data.length) return '0%';

    if (currentMonthIndex === -1 && isBefore(new Date(data[0][xKey] ?? 0), new Date())) return '100%';

    if (currentMonthIndex === -1 && isAfter(new Date(data[0][xKey] ?? 0), new Date())) return '0%';

    let emptyIndexStartCount = 0;
    let emptyIndexEndCount = 0;

    const filteredKeys = Object.keys(data[0]).filter((key) => key !== xKey);

    const firstNonNull = data.findIndex((item) => filteredKeys.some((key) => item[key] !== null));
    const lastNonNull =
      data.length - 1 - [...data].reverse().findIndex((item) => filteredKeys.some((key) => item[key] !== null));
    emptyIndexStartCount = firstNonNull === -1 ? data.length : firstNonNull;
    emptyIndexEndCount = lastNonNull === -1 ? data.length : data.length - 1 - lastNonNull;

    const totalValidDataCount = data.length - emptyIndexStartCount - emptyIndexEndCount - 1;
    const singleIndexPercentage = totalValidDataCount > 0 ? (1 / totalValidDataCount) * 100 : 0;

    const percentage = singleIndexPercentage * (currentMonthIndex - emptyIndexStartCount - emptyIndexEndCount);

    return `${percentage}%`;
  };

  const offsetPercentage = getGradientOffsetPercentage();
  const gradientId = v4();

  const allEqual = ({ data, key }: { data: IDataArrayDictionary[]; key: string }): boolean => {
    return data.every((dict) => dict[key] === data[0][key]);
  };

  const getLineColor = ({ index, line }: { index: number; line: ILineProps }): string => {
    if (index === 0 && line.stroke === '#64755C' && !allEqual({ data, key: line.dataKey }))
      return `url(#${gradientId})`;
    return line.stroke;
  };

  const graph = (
    <div className={`w-full flex flex-col flex-grow relative`} data-testid={`${id}-chart`} ref={graphRef}>
      <ResponsiveContainer minHeight={1} minWidth={1}>
        <AreaChart
          margin={{ top: 8, right: 8 }}
          onMouseMove={handleMouseChange}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          // @ts-expect-error - recharts types are incorrect
          cursor={onClick ? 'pointer' : 'default'}
        >
          <defs>
            <linearGradient id={gradientId} x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset={offsetPercentage} stopColor="#64755C" stopOpacity={1} />
              <stop offset={offsetPercentage} stopColor="#AAB7A4" stopOpacity={1} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="-13.11%" stopColor="rgba(100, 117, 92, 0.18)" />
              <stop offset="100%" stopColor="rgba(100, 117, 92, 0.00)" />
            </linearGradient>
          </defs>
          {isDashboardGraph && <ReferenceLine y={0} stroke="#F4F4F4" />}
          {data.map((item, index) => (
            <ReferenceLine
              key={item[xKey] ?? Math.random()}
              x={item[xKey] ?? 0}
              stroke={getReferenceLineColor(index, item[xKey] ?? 0, internalActiveIndex)}
              label={
                index > numOfIndexesTakenByLabels &&
                index < data.length - numOfIndexesTakenByLabels &&
                index !== data.length - 1 &&
                new Date(item.date ?? 0).getMonth() === 11
                  ? {
                      value: xFormatter(item[xKey] ?? 0),
                      position: 'bottom',
                      fill: '#999999',
                      fontFamily: 'inter',
                      fontSize: '14px',
                      style: { letterSpacing: '0.125px' },
                      dy: 13,
                    }
                  : false
              }
            />
          ))}
          <YAxis
            axisLine={false}
            domain={[ticks[0], ticks[ticks.length - 1]]}
            ticks={ticks}
            tickFormatter={(value) => yFormatter(value)}
            tickLine={false}
            tickMargin={0}
            tick={{
              fill: '#999999',
              fontFamily: 'inter',
              fontSize: '14px',
              fontWeight: 400,
            }}
            opacity={opacity}
            width={yAxisWidth ? yAxisWidth : 150}
          />
          <XAxis axisLine={false} domain={[minX, maxX]} dataKey={xKey} type={'number'} tick={false} />
          <Tooltip
            content={
              customTooltip ?? (
                <LineGraphTooltip
                  xFormatter={xFormatter}
                  yFormatter={yFormatter}
                  data={data}
                  activeIndex={internalActiveIndex}
                  timeboundGoalHovered={timeboundGoalHovered}
                />
              )
            }
            allowEscapeViewBox={{ x: !isMobile, y: !isMobile }}
            reverseDirection={{ x: isMouseOnRight, y: false }}
            wrapperStyle={{ zIndex: 2 }}
            cursor={false}
            position={
              timeboundGoalHovered
                ? {
                    x: timeboundGoalHovered.x - 70, // offset the tooltip width
                    y: timeboundGoalHovered.y - 125, // offset the tooltip height
                  }
                : undefined
            }
          />
          {linesToRender.map((line, index) => (
            <Area
              key={line.dataKey}
              type="linear"
              data={data}
              dataKey={line.dataKey}
              baseValue={ticks[0]}
              stroke={getLineColor({ index, line })}
              isAnimationActive={false}
              opacity={opacity}
              strokeWidth={1.5}
              dot={
                <LineGraphDot
                  activeIndex={internalActiveIndex}
                  lockedIndexes={lockedIndexes}
                  hoverIcon={hoverCursorIcon}
                  stroke={line.stroke}
                  isPrimaryLine={!index}
                  hoverLockedIndexIcon={hoverLockedIndexIcon}
                  currentMonthIndex={currentMonthIndex}
                  externalActiveIndex={externalActiveIndex}
                />
              }
              strokeDasharray={line.isDashed ? '8 4' : ''}
              activeDot={false}
              fillOpacity={lines.length <= 1 ? 1 : 0}
              fill={lines.length <= 1 && showGradient ? 'url(#gradient)' : 'rgba(255, 255, 255, 0)'}
            />
          ))}
          {nonTimeboundGoals?.map((goal) => (
            <Area
              key={goal.targetValue}
              stroke="#AAB7A4"
              strokeDasharray="10 10"
              dataKey="targetValue"
              fill="none"
              data={data.map((item) => ({
                targetValue: goal.targetValue,
                date: item.date,
              }))}
              dot={<NonTimeboundGoalLabel targetValue={goal.targetValue} yFormatter={yFormatter} xMin={minX} />}
              activeDot={false}
            />
          ))}
          {timeboundGoals && (
            <Area
              dataKey="targetValue"
              stroke="none"
              fill="none"
              data={timeboundGoals
                .filter((goal) => {
                  const utcTargetDate = getEndOfUtcMonth({
                    date: goal.targetDate,
                  });
                  return utcTargetDate >= minX && utcTargetDate <= maxX;
                })
                .map((goal) => {
                  const endOfMonthUtc = getEndOfUtcMonth({
                    date: goal.targetDate,
                  });
                  return {
                    targetValue: goal.targetValue,
                    date: endOfMonthUtc,
                  };
                })}
              dot={<TimeboundGoalDot setTimeboundGoalHovered={setTimeboundGoalHovered} />}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <div className="flex justify-between items-center pl-14 w-full mt-[-19px]">
        <Typography size="xs" color="empty" id={`${card?.title ?? 'empty-title'}-${xFormatter(minX)}-min`}>
          {xFormatter(minX)}
        </Typography>
        <Typography size="xs" color="empty" id={`${card?.title ?? 'empty-title'}-${xFormatter(maxX)}-max`}>
          {xFormatter(maxX)}
        </Typography>
      </div>
    </div>
  );

  if (card) {
    return (
      <Card className={`!px-5 !md:px-5 !py-4 !md:py-4 h-full ${cardClassName}`}>
        <div className="flex items-center justify-between mb-3 w-full">
          <Typography weight="medium" className="w-full">
            {card.title}
          </Typography>
          {card.month && card.figure && lines.length === 1 && (
            <div className="flex items-center" data-testid={`${card.title}-month-value-chart`}>
              <Typography size="xs" color="disabled" weight="thin" className="mr-2">
                {card.month}
              </Typography>
              <Typography size="sm" weight="semibold" color="green">
                {yFormatter(Number(card.figure))}
              </Typography>
            </div>
          )}
        </div>
        {graph}
      </Card>
    );
  }

  return graph;
};

export default LineGraph;
