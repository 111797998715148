import { type IPositionDetails } from '~/pages/Headcount/entity/types';
import { ExpenseDriverEnum, ExpenseFrequencyEnum, IExpense } from '../components/types';
import { getActivePositionsInMonthPerExpense } from './getActivePositionsInMonthPerExpense';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

export const calculateExpenseAmount = ({
  expense,
  positions,
  month,
}: {
  expense: IExpense;
  positions: IPositionDetails[];
  month: IStringDate;
}): number => {
  const amount = expense.context.amount ?? 0;
  switch (expense.context.driver) {
    case ExpenseDriverEnum.SetCost:
      return amount;
    case ExpenseDriverEnum.HeadcountFixed:
      return (
        amount *
        getActivePositionsInMonthPerExpense({
          positions,
          month,
          expense,
          isOnHire: expense.context.frequency === ExpenseFrequencyEnum.OnHire,
        }).length
      );
    case ExpenseDriverEnum.HeadcountPercentCompensation: {
      let totalMonthlyWages = 0;
      getActivePositionsInMonthPerExpense({
        positions,
        month,
        expense,
        isOnHire: expense.context.frequency === ExpenseFrequencyEnum.OnHire,
      }).forEach((position) => {
        const currentPayRate = position.currentPayRate;
        let payRate = 0;
        if (
          stringDate.isBefore({
            comparison: month,
            dateToCheck: currentPayRate.effectiveAt,
          })
        ) {
          payRate = currentPayRate.value as number;
        } else {
          const mostRecentHistory = position.payRates.reduce((mostRecent, current) => {
            return new Date(mostRecent.effectiveAt) > new Date(current.effectiveAt) ? mostRecent : current;
          });
          payRate = mostRecentHistory.value as number;
        }
        const MONTHS = 12;
        const PAY_RATE_TO_DOLLARS = 100;
        const PERCENT_TO_DECIMAL = 100000;
        payRate = (payRate / MONTHS / PAY_RATE_TO_DOLLARS) * (amount / PERCENT_TO_DECIMAL);
        if (
          stringDate.isSameMonth({
            date1: month,
            date2: position.hireDate,
          })
        ) {
          const daysInMonth = Number(stringDate.getLastDayOfMonth(month));
          const hireDate = position.hireDate;
          const daysActive = daysInMonth - stringDate.getDay(hireDate);
          const percentOfMonthActive = daysActive / daysInMonth;
          payRate = payRate * percentOfMonthActive;
        }
        totalMonthlyWages += payRate;
      });
      return totalMonthlyWages * amount;
    }
    default:
      return 0;
  }
};
