import { IStringDate } from './types';

export const isBefore = ({
  comparison,
  dateToCheck,
}: {
  comparison: IStringDate;
  dateToCheck: IStringDate;
}): boolean => {
  const comparisonYear = parseInt(comparison.split('-')[0]);
  const dateToCheckYear = parseInt(dateToCheck.split('-')[0]);

  if (comparisonYear === dateToCheckYear) {
    const comparisonMonth = parseInt(comparison.split('-')[1]);
    const dateToCheckMonth = parseInt(dateToCheck.split('-')[1]);

    if (comparisonMonth === dateToCheckMonth) {
      const comparisonDay = parseInt(comparison.split('-')[2]);
      const dateToCheckDay = parseInt(dateToCheck.split('-')[2]);

      return dateToCheckDay < comparisonDay;
    }

    return dateToCheckMonth < comparisonMonth;
  }

  return dateToCheckYear < comparisonYear;
};
