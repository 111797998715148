import React, { useState } from 'react';
import Typography from '~/components/Typography';
import { IFormulaNodeData } from './index';
import { Handle, Position } from '@xyflow/react';

const FormulaNode = ({ data }: { data: IFormulaNodeData }): React.ReactElement => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (): void => {
    setIsHovered(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovered(false);
  };

  return (
    <div
      className={`border ${data.dataType === 'selected' ? 'bg-white border-neutral-100' : 'bg-neutral-15 border-neutral-75 hover:bg-green-15 hover:border-green-200'} rounded-3xl py-1 px-3 hover:cursor-pointer`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography size="sm" color={data.dataType === 'selected' ? 'primary' : isHovered ? 'primaryGreen' : 'empty'}>
        {data.label}
      </Typography>
      {(data.dataType === 'flowsToSelected' || data.dataType === 'selected') && (
        <Handle
          type="source"
          position={Position.Right}
          id={data.formula.formulaUuid}
          style={{ background: 'transparent', border: 'none' }}
        />
      )}
      {(data.dataType === 'flowsFromSelected' || data.dataType === 'selected') && (
        <Handle
          type="target"
          position={Position.Left}
          id={data.formula.formulaUuid}
          style={{ background: 'transparent', border: 'none' }}
        />
      )}
    </div>
  );
};

export default FormulaNode;
