import React from 'react';
import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import Typography from '~/components/Typography';
import Button from '~/components/Button';

const NewExpenseMappingsTable = ({
  unusedExpenseMappings,
}: {
  unusedExpenseMappings: IIntegrationMapping[];
}): React.ReactNode => {
  return (
    <div className="flex flex-col bg-white">
      <div className="sticky top-0 z-10 bg-white rounded-t-lg py-2 px-4 border-b border-neutral-50">
        <div className="flex justify-between items-center">
          <Typography size="xs" color="empty">
            GL
          </Typography>
          <Typography size="xs" color="empty">
            Actions
          </Typography>
        </div>
      </div>
      <div className="flex flex-col">
        {unusedExpenseMappings.map((mapping) => {
          return (
            <div className="flex justify-between items-center py-2 px-4 border-t border-neutral-50" key={mapping.uuid}>
              <Typography>{mapping.name}</Typography>
              <div className="flex gap-4">
                <Button className="!w-fit !px-5 !py-2" fill="outline">
                  Link with Existing
                </Button>
                <Button className="!w-fit !px-5 !py-2">Create Expense</Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewExpenseMappingsTable;
