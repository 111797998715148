import React, { ReactElement, useContext, useMemo } from 'react';
import BaseTable from '~/components/Table/Base/BaseTable';
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Typography from '~/components/Typography';
import { IContract, IContractTableRow, IContractTablularTableRow } from '../entity/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ContractsTabularTableTitleCell from './ContractsTabularTableTitleCell';
import ContractsTabularTableMonthCell from './ContractTabularTableMonthCell';
import { ContractsContext } from '../context/ContractsContext';
import expenseModelEmptyState from '~/assets/expenseModelEmptyState.svg';
import * as stringDate from '~/utils/stringDate';
import { IStringDate } from '~/utils/stringDate/types';

const ContractTabularTable = ({ mode }: { mode: 'collections' | 'revenue' }): ReactElement => {
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const { filteredContracts, cashCollectionFormulas, revenueRecognitionFormulas } = useContext(ContractsContext);

  const monthArrayBetweenDates = stringDate.createMonthArrayBetweenDates({
    startDate: defaultGraphStartDate,
    endDate: defaultGraphEndDate,
  });

  const { tableData, tableColumns } = useMemo(() => {
    const columns = [
      { id: 'name', label: 'NAME' },
      ...monthArrayBetweenDates.map((month) => ({
        id: stringDate.format(month, 'MMM yyyy'),
        label: stringDate.format(month, 'MMM yyyy'),
      })),
    ];

    const columnHelper = createColumnHelper<IContractTablularTableRow>();

    const tableColumns = columns.map((col, index) =>
      // @ts-expect-error - columnHelper wants a specific string as id
      columnHelper.accessor(col.id, {
        header: () =>
          index === 0 ? (
            <div className="text-left w-full">{col.label}</div>
          ) : (
            <div className="text-right w-[125px]">{col.label}</div>
          ),
        cell: (info) =>
          index === 0 ? (
            <ContractsTabularTableTitleCell info={info as CellContext<IContractTablularTableRow, IContract>} />
          ) : (
            <ContractsTabularTableMonthCell
              info={
                info as CellContext<
                  IContractTablularTableRow,
                  {
                    value: number | null;
                    contractStartDate: Date;
                    contractEndDate: Date | null;
                    contractInvoiceDate: Date;
                    mode: 'collections' | 'revenue';
                  }
                >
              }
            />
          ),
        enableSorting: false,
        enablePinning: index === 0,
        enableResizing: false,
        size: index === 0 ? 300 : 125,
      }),
    );

    const tableData = filteredContracts.map((contract) => {
      const formulasToUse = mode === 'collections' ? cashCollectionFormulas : revenueRecognitionFormulas;
      const formula = formulasToUse.find((formula) => formula.recipe.relatedResourceUuid === contract.contractUuid);
      if (!formula) throw new Error(`${mode} formula not found for contract ${contract.contractUuid}`);
      const calculationsObject = formula.calculations.reduce(
        (acc, curr) => {
          acc[stringDate.format(stringDate.getStringDate(new Date(curr.date)), 'MMM yyyy')] = {
            value: curr.value,
            contractStartDate: contract.startDate,
            contractEndDate: contract.endDate,
            contractInvoiceDate: contract.invoiceDate,
            mode,
          };
          return acc;
        },
        {} as Record<
          string,
          {
            value: number | null;
            contractStartDate: IStringDate;
            contractEndDate: IStringDate | null;
            contractInvoiceDate: IStringDate;
            mode: 'collections' | 'revenue';
          }
        >,
      );

      return {
        name: contract,
        ...calculationsObject,
      };
    });

    return { tableData, tableColumns };
  }, [
    filteredContracts,
    mode,
    cashCollectionFormulas,
    revenueRecognitionFormulas,
    defaultGraphStartDate,
    defaultGraphEndDate,
  ]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData as unknown as IContractTableRow[],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const tableEmptyState = (
    <div className="flex flex-col items-center gap-2 py-16">
      <img src={expenseModelEmptyState} alt="Empty Tasks Illustration" className="w-64 h-auto" />
      <div className="flex flex-col items-center gap-4 max-w-[512px] text-center">
        <Typography color="empty" size="sm">
          This will assist your organization in managing contracts effectively, highlight the total value of contracts,
          and facilitate informed decision-making regarding your financial resources.
        </Typography>
        <Typography color="empty" size="sm">
          Click Add Contract to get started.
        </Typography>
      </div>
    </div>
  );

  if (!tableData.length) return tableEmptyState;

  return (
    <div className="w-[calc(100%-40px)] ml-10 max-h-[100%] overflow-x-scroll overflow-y-scroll hide-scrollbar">
      <BaseTable
        id="contracts-table"
        // @ts-expect-error - BaseTable doesn't like typed rows
        table={table}
        styles={{
          th: 'text-xs font-normal text-neutral-200 text-nowrap sticky top-0 !shadow-sm bg-white px-4 py-2',
          td: 'h-14 border-t border-b border-gray-200 text-nowrap z-[-10]',
        }}
        emptyState={tableEmptyState}
      />
    </div>
  );
};

export default ContractTabularTable;
