import z from 'zod';
import { ZConsolidatedReportCollection } from '~/pages/Dashboard/entity/schemas';
import { DEFAULT_TAGS, ExpenseDriverEnum, ExpenseFrequencyEnum } from '~/pages/Expenses/components/types';
import { ZPositionDetails } from '~/pages/Headcount/entity/schemas';
import { ZFormula } from '~/services/parallel/formulas.types';
import { ZIntegration } from '~/services/parallel/integrations.types';
import { ZStringDate } from '~/utils/stringDate/types';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';

export const ZExpenseContext = z.object({
  startDate: ZStringDate,
  endDate: ZStringDate.nullable(),
  tag: z.enum(DEFAULT_TAGS),
  amount: z.number().optional(),
  frequency: z.nativeEnum(ExpenseFrequencyEnum),
  driver: z.nativeEnum(ExpenseDriverEnum),
  departments: z.array(z.string()),
  forecastReasoning: z.string().nullable().optional(),
});

export const ZDatasourceMetadata = z.object({
  lastMonthValue: z.number(),
  threeMonthAverageValue: z.number(),
  twelveMonthAverageValue: z.number(),
  percentOfTotalExpense: z.number(),
  datasourceUuid: z.string(),
});

export const ZExpense = z.object({
  uuid: z.string(),
  expenseUuid: z.string(),
  organizationUuid: z.string(),
  scenarioUuid: z.string().nullable(),
  createdBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  createdAt: iso8601Validator,
  deletedAt: iso8601Validator.nullable(),
  name: z.string(),
  context: ZExpenseContext,
  creationStatus: z.string(),
  isGrayedOut: z.boolean().optional(),
  dataSourceUuids: z.array(z.string()).optional(),
  readableFormulaExpression: z.string().optional(),
  datasourcesMetadata: z.array(ZDatasourceMetadata).optional(),
});

export const ZExpensesPageRequest = z.object({
  expenses: z.array(ZExpense),
  reports: ZConsolidatedReportCollection,
  positions: z.array(ZPositionDetails),
  formulaList: z.array(ZFormula),
  connectedIntegrations: z.array(ZIntegration).optional(),
});
