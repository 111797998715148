import { z } from 'zod';
import { ZStringDate } from '~/utils/stringDate/types';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';

export const ZTemporalValue = z.object({
  value: z.union([z.number(), z.string()]),
  effectiveAt: z.union([ZStringDate, z.literal('onHire')]),
});

export const ZPositionSchema = z.object({
  uuid: z.string(),
  organizationUuid: z.string().uuid(),
  scenarioUuid: z.string().nullable(),
  createdBy: z.string().nullable(),
  deletedBy: z.string().nullable(),
  updatedBy: z.string().nullable(),
  createdAt: iso8601Validator,
  deletedAt: iso8601Validator.nullable(),
  updatedAt: iso8601Validator,
  hireDate: ZStringDate,
  termDate: ZStringDate.nullable(),
  isActive: z.boolean(),
  employeeName: z.string().nullable(),
  title: z.string().nullable(),
  departmentUuid: z.string().nullable(),
  payRate: z.array(ZTemporalValue).nullable(),
  positionUuid: z.string(),
  planDate: iso8601Validator,
});

export const ZPositionDetails = z.object({
  positionUuid: z.string(),
  hireDate: ZStringDate,
  termDate: ZStringDate.nullable(),
  departmentUuid: z.string(),
  currentDepartment: z.object({
    uuid: z.string(),
    departmentUuid: z.string(),
    name: z.string(),
  }),
  title: z.string(),
  employeeName: z.string().nullable(),
  currentPayRate: ZTemporalValue,
  payRates: z.array(ZTemporalValue),
  isActive: z.boolean(),
  fullyBurdenedCost: z.number().nullable().optional(),
});

export const ZPositionDetailsWithOrderedDates = ZPositionDetails.extend({
  orderedDate: z.string(),
});

export const headcountLoaderSchema = z.object({
  positions: z.array(ZPositionDetails).optional(),
});
