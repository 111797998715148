import React, { useContext, useEffect, useRef, useState } from 'react';
import { IConversation, IMessageRoleEnum } from '~/pages/Dashboard/entity/types';
import AssistantMessage from './AssistantMessage';
import UserMessage from './UserMessage';
import { useInput } from '~/components/Input';
import Input from '~/components/Input/InputWrapper';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { ChatContext } from '../../context/ChatContext';
import ParallelLogo from '~/assets/parallelLogoIcon.svg';
import ResponseLoading from './ResponseLoading';
import ErrorMessage from './ErrorMessage';

const Conversation = (): React.ReactElement | null => {
  const { selectedConversationUuid, allConversationsDict, sendMessage, isSendingMessage, focusTextInput } =
    useContext(ChatContext);
  const [newMessage, setNewMessage] = useInput({ validation: /.*/ });
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [selectedConversation, setSelectedConversation] = useState<IConversation | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedConversationUuid && selectedConversationUuid in allConversationsDict) {
      setSelectedConversation(allConversationsDict[selectedConversationUuid]);
    } else {
      setSelectedConversation(null);
    }
  }, [selectedConversationUuid, allConversationsDict]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusTextInput]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [selectedConversation, isSendingMessage]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = '48px';
      const minHeight = 48; // Default height of the input
      const maxHeight = 850; // Arbitrary max height based on when it feels like it's too tall
      const height = Math.min(Math.max(minHeight, inputRef.current.scrollHeight), maxHeight);
      inputRef.current.style.height = `${height}px`;
    }
  }, [newMessage.value]);

  const handleSendMessage = async (): Promise<void> => {
    if (newMessage.value.trim()) {
      const message = newMessage.value;
      setNewMessage((prev) => ({ ...prev, value: '' }));
      await sendMessage({ conversationUuid: selectedConversationUuid, message });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="flex flex-col items-center flex-1 pt-10 px-10 pb-5 max-h-screen">
      <div
        className="w-full flex flex-col gap-8 flex-1 overflow-y-auto no-scrollbar pb-10 max-w-[780px]"
        ref={messagesContainerRef}
      >
        {selectedConversation ? (
          <>
            {selectedConversation.messages.map((message, index) => {
              const key = `${index}-${message.role}-${message.content}`;
              if (message.isError) {
                return (
                  <ErrorMessage
                    message={message.content}
                    previousMessage={selectedConversation.messages[index - 1]?.content ?? ''}
                    conversationUuid={selectedConversationUuid}
                    key={key}
                  />
                );
              }
              if (message.role === IMessageRoleEnum.User) {
                return <UserMessage message={message.content} key={key} />;
              }
              return <AssistantMessage message={message.content} key={key} />;
            })}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full gap-3">
            <>
              <img src={ParallelLogo} alt="Parallel Logo" className="size-10" />
              <div className="flex flex-col items-center justify-center">
                <Typography size="lg" weight="bold">
                  Parallel Copilot
                </Typography>
                <Typography size="sm" color="secondary">
                  How can we help?
                </Typography>
              </div>
            </>
          </div>
        )}
        {isSendingMessage && <ResponseLoading />}
      </div>
      <div className="w-full h-fit shrink-0 max-w-[780px]">
        <div
          className={`flex items-end justify-between w-full h-fit py-1 border rounded-xl px-3 ${
            isFocused
              ? 'border-green-500 ring-2 ring-white ring-opacity-75 ring-offset-2 ring-offset-green-300'
              : 'border-neutral-100'
          }`}
        >
          <Input
            id="new-message"
            type="textarea"
            ref={inputRef}
            state={newMessage}
            setState={setNewMessage}
            className="h-[48px] resize-none !border-none !border-0 !ring-0 !ring-offset-0 !outline-0 !shadow-none !no-scrollbar !rounded-xl leading-[28px]"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={isSendingMessage}
            onKeyDown={handleKeyDown}
            placeholder="Ask a question, model a scenario, update model, etc."
          />
          <Button
            className="mb-1.5 !p-1 !h-fit !w-fit rounded-lg"
            disabled={!newMessage.value || isSendingMessage}
            onClick={handleSendMessage}
          >
            <PaperAirplaneIcon className="size-6" />
          </Button>
        </div>
        <Typography size="xs" color="disabled">
          The accuracy of information may be subjective.
        </Typography>
      </div>
    </div>
  );
};

export default Conversation;
