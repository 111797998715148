import React, { useMemo } from 'react';
import Button from '~/components/Button';
import useFormulaContext from '../../../components/Formulas/context/useFormulaContext';
import { IGroupType } from '~/components/Formulas/context/types';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';

const HandleCollapseButton = (): React.ReactElement | null => {
  const { allFormulasData, toggleGroupCollapse, searchFilter } = useFormulaContext();

  if (!allFormulasData.every((group): group is IGroupType => group.type === IRecordTypeEnum.Group)) {
    throw new Error('allFormulasData must only contain groups');
  }

  const isFullyCollapsed = useMemo(
    () => allFormulasData.filter((group) => group.formulas.length).every((group) => group.isCollapsed),
    [allFormulasData],
  );

  if (searchFilter.value) return null;

  return (
    <Button fill="clear" className="!w-auto !px-2" onClick={() => toggleGroupCollapse(!isFullyCollapsed)}>
      {isFullyCollapsed ? 'Open All' : 'Collapse All'}
    </Button>
  );
};

export default HandleCollapseButton;
