import { Node, mergeAttributes } from '@tiptap/core';
import BasicFormulaNodeView from './BasicFormulaNodeView';
import { ReactNodeViewRenderer } from '@tiptap/react';

const BasicFormulaNode = Node.create({
  name: 'basicFormulaNode',
  group: 'inline',
  inline: true,
  selectable: true,

  addAttributes() {
    return {
      label: { default: null },
      formulaUuid: { default: null },
      isEditorWrapped: { default: false },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'basic-formula-node',
        getAttrs: (element): Record<string, unknown> => {
          if (typeof element === 'string') return {};
          const label = element.getAttribute('data-label');
          const formulaUuid = element.getAttribute('data-formula-uuid');
          return { label, formulaUuid, isEditorWrapped: false };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['basic-formula-node', mergeAttributes({ ...HTMLAttributes, class: 'px-8' })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(BasicFormulaNodeView);
  },
});

export default BasicFormulaNode;
