import { IFormulaData } from '~/components/Formulas/context/types';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';

export const getFilteredFormulaGroups = ({
  formulaGroups,
  permissionedFormulaUuids,
}: {
  formulaGroups: IFormulaData[];
  permissionedFormulaUuids: string[];
}): IFormulaData[] => {
  if (permissionedFormulaUuids.includes('*')) return formulaGroups;
  return formulaGroups
    .filter((group) => {
      if (group.type === IRecordTypeEnum.Group) {
        return group.formulas.some((formula) =>
          permissionedFormulaUuids.find((pFormulaUuid) => pFormulaUuid === formula.formulaUuid),
        );
      }
      return permissionedFormulaUuids.find((pFormulaUuid) => pFormulaUuid === group.formulaUuid);
    })
    .map((group) => {
      if (group.type === IRecordTypeEnum.Group) {
        return {
          ...group,
          formulas: group.formulas.filter((formula) =>
            permissionedFormulaUuids.find((pFormulaUuid) => pFormulaUuid === formula.formulaUuid),
          ),
        };
      }
      return group;
    });
};
