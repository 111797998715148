import { getLastDayOfMonth } from './getLastDayOfMonth';
import { subtractMonths } from './subtractMonths';
import { IStringDate } from './types';

export const addMonths = (date: IStringDate, months: number): IStringDate => {
  let [year, month, day] = date.split('-').map(Number);
  let remainingMonths = months;

  if (remainingMonths < 0) return subtractMonths(date, Math.abs(remainingMonths));

  while (remainingMonths > 0) {
    const increment = Math.min(remainingMonths, 12 - month + 1);
    month += increment;
    remainingMonths -= increment;

    if (month > 12) {
      year += Math.floor((month - 1) / 12);
      month = ((month - 1) % 12) + 1;
    }
  }

  const lastDayOfTargetMonth = Number(getLastDayOfMonth(`${year}-${String(month).padStart(2, '0')}-01`));

  if (day > lastDayOfTargetMonth) {
    day = lastDayOfTargetMonth;
  }

  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};
