import { Node, mergeAttributes } from '@tiptap/core';
import FormulaNodeView from './FormulaNodeView';
import { ReactNodeViewRenderer } from '@tiptap/react';

const FormulaNode = Node.create({
  name: 'formulaNode',
  group: 'inline',
  inline: true,
  selectable: true,

  addAttributes() {
    return {
      label: { default: null },
      formulaUuid: { default: null },
      timeModifier: { default: null },
      calculationModifier: { default: null },
      calculationModifierType: { default: null },
      type: { default: null },
      isEditorWrapped: { default: false },
      viewOnly: { default: false },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'formula-node',
        getAttrs: (element): Record<string, unknown> => {
          if (typeof element === 'string') return {};
          const label = element.getAttribute('data-label');
          const formulaUuid = element.getAttribute('data-formula-uuid');
          const timeModifier = element.getAttribute('data-time-modifier');
          const calculationModifier = element.getAttribute('data-calculation-modifier');
          const calculationModifierType = element.getAttribute('data-calculation-modifier-type');
          const type = element.getAttribute('data-type');
          const viewOnly = element.getAttribute('data-view-only');
          return {
            label,
            formulaUuid,
            timeModifier,
            calculationModifier,
            calculationModifierType,
            type,
            isEditorWrapped: false,
            viewOnly,
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['formula-node', mergeAttributes({ ...HTMLAttributes, class: 'px-8' })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FormulaNodeView);
  },
});

export default FormulaNode;
