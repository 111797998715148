import React, { useContext, useEffect, useRef } from 'react';
import ExportData from '~/components/ExportData';
import Header from '~/components/Header';
import HeadcountProvider, { HeadcountContext } from './context/HeadcountContext';
import Button from '~/components/Button';
import CreatePosition from './components/CreatePosition';
import * as HeadcountTable from './components/HeadcountTable';
import SegmentedControl from '~/components/SegmentedControl';
import { ChartBarIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { useExitScenarioListener } from '~/utils/hooks/useExitScenarioListener';
import UserDateRange from '~/components/UserDateRange';
import SelectDepartment from '~/components/SelectDepartment';
import { useSelect } from '~/components/Select';
import Checkbox from '~/components/Checkbox';
import * as HeadcountTimeline from './components/HeadcountTimeline';
import Input from '~/components/Input/InputWrapper';
import { useFeatureFlag } from '~/utils/hooks/useFeatureFlag';
import Typography from '~/components/Typography';
import emptyHeadcount from '~/assets/emptyHeadcount.svg';
import Skeleton from 'react-loading-skeleton';
import ScenarioDropdown from '~/components/ScenarioDropdown';
import * as stringDate from '~/utils/stringDate';

const HeadcountPageContainer = (): React.ReactNode => {
  const COUNT_OF_POSITIONS_TO_SHOW_SEARCH = 10;
  const [queryParams, setQueryParams] = useQueryParams();
  const headcountView = queryParams.get('view') ?? 'table';
  const {
    csvToExport,
    createPositionModalIsOpen,
    setCreatePositionModalIsOpen,
    reload,
    pageLoading,
    positionFormState,
    showTermedPositions,
    setShowTermedPositions,
    setHasReloaded,
    positions,
    search,
    setSearch,
    clearInlineCreateForm,
    renderedPositions,
    setInlineCreate,
    inlineCreate,
  } = useContext(HeadcountContext);
  const inlineHeadcount = useFeatureFlag('inlineHeadcount');

  const [departments, setDepartments] = useSelect({
    options: [],
    selected: { label: 'All Departments', value: null },
  });

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (departments.selected) {
      if (!departments.selected.value) {
        currentParams.departments = 'all';
      } else {
        currentParams.departments = departments.selected.value;
      }
    }
    setQueryParams(currentParams);
  }, [departments]);

  useExitScenarioListener(reload);

  const hasPastEmployees = positions?.some(
    (position) =>
      position.termDate &&
      stringDate.isBefore({
        dateToCheck: position.termDate,
        comparison: stringDate.getStringDate(),
      }),
  );

  const hasPositions = Boolean(renderedPositions.length);

  return (
    <div className="max-sm:min-h-screen max-sm:pb-32">
      <CreatePosition
        isOpen={createPositionModalIsOpen}
        setModal={setCreatePositionModalIsOpen}
        positionFormState={positionFormState}
        reload={reload}
        setHasReloaded={setHasReloaded}
      />
      <Header
        title="Headcount"
        zIndex="z-30"
        startChildren={
          <div className="flex flex-row gap-2">
            <ScenarioDropdown reload={reload} />
          </div>
        }
        endChildren={
          <div className="flex w-full justify-end items-center gap-2">
            {!pageLoading && <UserDateRange pickerAlignment="right" />}
          </div>
        }
      />
      <div className="flex flex-col max-w-full h-full items-end justify-center w-full pt-8 max-sm:px-0">
        <div className="flex w-full px-10 justify-between items-center ">
          <div className="max-sm:hidden flex items-center gap-2">
            <SegmentedControl
              name="headcount-view"
              value={headcountView ?? 'table'}
              setValue={(value) => {
                const currentParams = Object.fromEntries(queryParams.entries());
                currentParams.view = value;
                setQueryParams(currentParams);
              }}
              segments={[
                {
                  label: (
                    <div className="px-2 py-1">
                      <ViewColumnsIcon className="size-5" />
                    </div>
                  ),
                  value: 'table',
                },
                {
                  label: (
                    <div className="px-2 py-1">
                      <ChartBarIcon className="size-5 -rotate-90" />
                    </div>
                  ),
                  value: 'timeline',
                },
              ]}
            />
            <div className="min-w-[200px] z-[29]">
              <SelectDepartment
                id="department-filter"
                departmentOptions={departments}
                setDepartmentOptions={setDepartments}
                allowSelectAll
                onFocus={clearInlineCreateForm}
              />
            </div>
            {hasPastEmployees && (
              <div className="flex text-nowrap items-center gap-2">
                <Checkbox
                  checked={showTermedPositions}
                  toggleValue={() => setShowTermedPositions(!showTermedPositions)}
                  label="Show Past Employees"
                  id="show-past-employees"
                />
              </div>
            )}
          </div>
          <div className="gap-4 flex max-sm:hidden">
            {positions && positions.length > COUNT_OF_POSITIONS_TO_SHOW_SEARCH && (
              <Input
                id="search-headcount-input"
                type="search"
                placeholder="Search"
                state={search}
                setState={setSearch}
                className="!w-[250px] mt-[1px]"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    (e.target as HTMLInputElement).blur();
                  }
                }}
                ref={searchInputRef}
              />
            )}
            <Button
              id="create-position-button"
              onClick={() => {
                setCreatePositionModalIsOpen(true);
                if (inlineHeadcount) {
                  clearInlineCreateForm();
                }
              }}
              className={positions && positions.length > 0 ? '' : 'hidden'}
            >
              Add Position
            </Button>
            <ExportData
              id={`download-csv`}
              data={csvToExport}
              filename={`positions-${stringDate.format(stringDate.getStringDate(), 'mm-dd-yyyy')}.csv`}
              className="max-sm:hidden"
            />
          </div>
        </div>
        <div className={`w-full h-full mt-10 px-10`}>
          {pageLoading && (
            <div
              className="max-w-full overflow-scroll hide-scrollbar h-full px-10 pb-96"
              data-testid="headcount-page-loading"
            >
              <div className="flex items-center justify-between gap-4 mb-8 h-[243px] min-w-[1200px]">
                <Skeleton height={243} width={1000} count={1} baseColor="#F8F9F6" />
                <Skeleton height={243} width={500} count={1} baseColor="#F8F9F6" />
              </div>
              <Skeleton height={40} count={20} className="mb-4" baseColor="#F8F9F6" />
            </div>
          )}
          {!hasPositions && !pageLoading && !inlineCreate && (
            <div className="flex flex-col items-center justify-center h-full mt-[15vh]">
              <img src={emptyHeadcount} alt="Empty State" className="size-36" />
              <Typography weight="semibold" className="py-1">
                Headcount
              </Typography>
              <Typography className="max-w-[400px] text-center" color="secondary">
                {`Manage your team's current and future headcount forecasting salaries & wages and driving growth`}
              </Typography>
              <Button
                onClick={() => {
                  setInlineCreate(true);
                  const currentParams = Object.fromEntries(queryParams.entries());
                  currentParams.view = 'table';
                  setQueryParams(currentParams);
                }}
                className="!w-fit !px-4 !py-2 mt-3"
                id="create-position-button-inline"
              >
                Add Position
              </Button>
            </div>
          )}
          {(hasPositions || inlineCreate) && (headcountView === 'table' || !headcountView) && (
            <HeadcountTable.component />
          )}
          {hasPositions && headcountView !== 'table' && <HeadcountTimeline.component />}
        </div>
      </div>
    </div>
  );
};

const HeadcountPage = (): React.ReactNode => {
  return (
    <HeadcountProvider>
      <HeadcountPageContainer />
    </HeadcountProvider>
  );
};

export default HeadcountPage;
