import React from 'react';
import Typography from '~/components/Typography';

const getDataKeyColor = (dataKey: string): 'green' | 'orange' | 'turquoise' | 'purple' | 'blue' | 'disabled' => {
  switch (dataKey) {
    case 'workingModel':
      return 'green';
    case 'activeScenario':
      return 'blue';
    case 'scenario1':
      return 'orange';
    case 'scenario2':
      return 'purple';
    case 'scenario3':
      return 'turquoise';
    case 'temporaryStaticScenario':
      return 'disabled';
    default:
      return 'green';
  }
};

const GraphTitle = ({
  title,
  month,
  indexData,
  yFormatter,
}: {
  title: string;
  month?: string;
  indexData: Record<string, number>;
  yFormatter: (value: number) => string;
}): React.ReactElement => {
  const orderedKeys = [
    'workingModel',
    'activeScenario',
    'scenario1',
    'scenario2',
    'scenario3',
    'temporaryStaticScenario',
  ];
  const figures = orderedKeys.filter((key) => key in indexData).map((key) => ({ value: indexData[key], dataKey: key }));

  return (
    <div className="flex items-center justify-between w-full">
      <Typography weight="medium" className="w-full">
        {title}
      </Typography>
      {month && figures.length > 0 && (
        <div className="flex items-center" data-testid={`${title}-month-value-chart`}>
          <Typography size="xs" color="disabled" weight="thin" className="mr-2">
            {month}
          </Typography>
          {figures.map(({ value, dataKey }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="flex items-center" key={`${index}-${dataKey}`}>
              <Typography size="sm" weight="semibold" color={getDataKeyColor(dataKey)}>
                {yFormatter(value)}
              </Typography>
              {index < figures.length - 1 && (
                <Typography size="xs" color="disabled" weight="thin" className="mx-2">
                  |
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GraphTitle;
