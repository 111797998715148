import React from 'react';
import History from './components/History';
import Conversation from './components/Conversation';
import { ChatContextProvider } from './context/ChatContext';

const Chat = (): React.ReactElement => {
  return (
    <ChatContextProvider>
      <div className="flex w-full">
        <History />
        <Conversation />
      </div>
    </ChatContextProvider>
  );
};

export default Chat;
