import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate/index';

export const MONTH_SHORT_NAMES: Record<string, number> = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
};

export const QUARTER_SHORT_NAMES = ['Q1', 'Q2', 'Q3', 'Q4'];

export const getStartAndEndDatesFromSelection = (
  mode: string,
  selection: string | number,
  pendingYearDate: number,
): {
  startDate: IStringDate;
  endDate: IStringDate;
} => {
  const QUARTER_MODIFIER = 3;

  let startDate;
  let endDate;

  switch (mode) {
    case 'month': {
      startDate = `${pendingYearDate}-${typeof selection === 'string' ? stringDate.monthAbrToNumber(selection) : selection}-01`;
      endDate = stringDate.endOfMonth(startDate);
      break;
    }
    case 'quarter': {
      if (typeof selection === 'number') {
        throw Error('Quarter selection must be a string');
      }
      const selectedQuarter = Number(selection.slice(-1)) - 1;
      startDate = `${pendingYearDate}-${selectedQuarter * QUARTER_MODIFIER}-01`;
      endDate = stringDate.endOfMonth(startDate);
      break;
    }
    case 'year':
      startDate = `${selection}-01-01`;
      endDate = stringDate.endOfMonth(startDate);
      break;
    default:
      throw Error('Invalid mode');
  }

  return { startDate, endDate };
};

const gridDisplaysForYear = (middleYear: number, minYear: number, maxYear: number): number[] => {
  const displayYears = [
    middleYear - 1 >= minYear ? middleYear - 1 : null,
    middleYear,
    middleYear + 1 <= maxYear ? middleYear + 1 : null,
  ].filter((year) => year !== null);

  return displayYears as number[];
};

export const getGridDisplayValues = (
  mode: string,
  pendingYearDate: number,
  minYear: number,
  maxYear: number,
): (string | number)[] => {
  switch (mode) {
    case 'month':
      return Object.keys(MONTH_SHORT_NAMES);
    case 'quarter':
      return QUARTER_SHORT_NAMES;
    case 'year':
      return gridDisplaysForYear(pendingYearDate, minYear, maxYear);
    default:
      return [];
  }
};

export const getInputDisplayValue = (
  mode: string,
  startDate: IStringDate | null,
  defaultInputDisplay: string,
): string => {
  if (!startDate) {
    return defaultInputDisplay;
  }
  switch (mode) {
    case 'month':
      return stringDate.format(startDate, 'MMM yyyy');
    case 'quarter':
      return `Q${stringDate.getQuarter(startDate)} ${stringDate.getYear(startDate)}`;
    case 'year':
      return `${stringDate.getYear(startDate)}`;
    default:
      return 'No';
  }
};
