import React, { useEffect, useState } from 'react';
import Input from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import SegmentedControl from '~/components/SegmentedControl';
import { ExpenseType, IExpenseFormState } from '~/pages/Expenses/components/CreateExpense/useExpenseFormState';
import PeriodPicker from '~/components/PeriodPicker';
import SelectMultipleDepartments from '~/components/SelectMultipleDepartments';
import Divider from '~/components/Divider';
import RadioInputTile from '~/components/RadioInputTile';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import Select from '~/components/Select';
import { useFeatureFlag } from '~/utils/hooks/useFeatureFlag';
import SelectMultiple from '~/components/SelectMultiple';
import { IFormula } from '~/services/parallel/formulas.types';
import { IRecipeVariables } from '~/services/parallel/formulas.types';
import { IUpdatedFormula } from '../../context/ExpensesContext';
import FormulaBuilderInput from '~/pages/FinancialModelDeprecated/components/FormulaBuilder/FormulaBuilderInput';

const EXPENSE_TYPE_OPTIONS = [
  {
    label: 'Set Cost',
    value: 'setCost',
    segmentClassName: '!max-w-[120px]',
    labelClassName: '!max-w-[112px]',
  },
  {
    label: 'Headcount Driven',
    value: 'headcountDriven',
  },
  {
    label: 'Custom',
    value: 'custom',
    segmentClassName: '!max-w-[120px]',
    labelClassName: '!max-w-[112px]',
  },
];

const EXPENSE_TYPE_OPTIONS_DEPRECATED = [
  {
    label: 'Set Cost',
    value: 'setCost',
  },
  {
    label: 'Headcount Driven',
    value: 'headcountDriven',
  },
];

interface Props {
  onClose: () => void;
  formState: IExpenseFormState;
  createExpense: () => Promise<void>;
  variables: IRecipeVariables;
  setVariables: React.Dispatch<React.SetStateAction<IRecipeVariables>>;
  displayFormulaError: {
    isDisplayed: boolean;
    message: string;
  };
  setDisplayFormulaError: React.Dispatch<
    React.SetStateAction<{
      isDisplayed: boolean;
      message: string;
    }>
  >;
  formula: IUpdatedFormula[];
  setFormula: React.Dispatch<React.SetStateAction<IUpdatedFormula[]>>;
  updatedFormula: IUpdatedFormula[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IUpdatedFormula[]>>;
  formulaValue: string;
  setFormulaValue: React.Dispatch<React.SetStateAction<string>>;
  formulaList: IFormula[];
}

const CreateExpenseForm = ({
  onClose,
  formState: {
    errorMessage,
    type,
    setType,
    name,
    setName,
    category,
    setCategory,
    frequency,
    setFrequency,
    headcountDriverType,
    setHeadcountDriverType,
    amount,
    setAmount,
    percentage,
    setPercentage,
    departments,
    setDepartments,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    formulaInputState,
    setFormulaInputState,
    integrationMappings,
    setIntegrationMappings,
  },
  variables,
  setVariables,
  displayFormulaError,
  setDisplayFormulaError,
  formulaValue,
  setFormulaValue,
  formula,
  setFormula,
  updatedFormula,
  setUpdatedFormula,
  formulaList,
  createExpense,
}: Props): React.ReactNode => {
  const customExpenses = useFeatureFlag('customExpenses');
  const generateExpenses = useFeatureFlag('generateExpenses');
  const getCurrencyLabel = ({ type, frequency }: { type: string; frequency?: string }): string => {
    if (frequency === 'oneTime' && type === 'setCost') return 'One Time Cost';
    if (frequency === 'oneTime') return 'Cost Per Employee';
    if (frequency === 'annually') return 'Annual Cost';
    if (frequency === 'quarterly') return 'Quarterly Cost';
    if (frequency === 'onHire') return 'Cost Per Hire';
    if (type !== 'setCost') return 'Monthly Cost Per Employee';

    return 'Monthly Cost';
  };
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);

  const handleSave = async (): Promise<void> => {
    setSaveIsLoading(true);
    await createExpense();
    setSaveIsLoading(false);
  };

  useEffect(() => {
    if (category.selected?.value === 'Cost of Goods Sold') {
      setDepartments((prev) => ({ ...prev, selected: [], disabled: true }));
    } else {
      setDepartments((prev) => ({ ...prev, disabled: false }));
    }
  }, [category.selected]);

  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          <Input id="expenseName" label="Expense Name" state={name} setState={setName} />
          <Select id="expenseCategory" label="Category" state={category} setState={setCategory} />
          <SelectMultipleDepartments
            label="Department Allocation"
            departmentOptions={departments}
            setDepartmentOptions={setDepartments}
            placeholder="Select departments"
            allowSelectAll
          />
          {generateExpenses && (
            <SelectMultiple
              label="Sync with Actuals"
              id="expense-sync-with-actuals"
              state={integrationMappings}
              setState={setIntegrationMappings}
              includeSearch
            />
          )}
          <Divider />
          <SegmentedControl
            name="type-control"
            value={type}
            setValue={(val) => {
              setType(val as ExpenseType);
              if (val === 'custom') {
                setFormulaInputState({
                  ...formulaInputState,
                  isCustom: true,
                });
              } else if (formulaInputState.isCustom) {
                setFormulaInputState((prevState) => ({
                  ...prevState,
                  isCustom: false,
                }));
              }
            }}
            segments={customExpenses ? EXPENSE_TYPE_OPTIONS : EXPENSE_TYPE_OPTIONS_DEPRECATED}
          />
          {type === 'headcountDriven' && (
            <Select
              label="Type"
              id={'expenseHeadcountDriver'}
              state={headcountDriverType}
              setState={setHeadcountDriverType}
            />
          )}
          {type !== 'custom' &&
            (type === 'headcountDriven' && headcountDriverType.selected?.value === 'headcountPercentCompensation' ? (
              <Input
                id="expenseAmount"
                type="percentage"
                label="Percentage"
                state={percentage}
                setState={setPercentage}
              />
            ) : (
              <CurrencyInput
                id="expenseAmount"
                label={`${getCurrencyLabel({
                  type: type,
                  frequency: frequency.selected?.value,
                })}`}
                state={amount}
                setState={setAmount}
              />
            ))}
          {type !== 'custom' && (
            <RadioInputTile
              id="expenseFrequency"
              label="Frequency"
              state={frequency}
              setState={(val) => {
                setFrequency((prevState) => ({
                  ...prevState,
                  selected: val,
                  touched: true,
                  valid: true,
                }));
                if (val.value === 'oneTime') {
                  setEndDate((prevState) => {
                    return {
                      ...prevState,
                      startDate: null,
                      endDate: null,
                      valid: true,
                      touched: false,
                      pristine: true,
                    };
                  });
                }
                if (val.value === 'onHire') {
                  setHeadcountDriverType({
                    ...headcountDriverType,
                    selected: {
                      label: 'Dollar Amount per Employee',
                      value: 'headcountFixed',
                    },
                    disabled: true,
                  });
                } else {
                  setHeadcountDriverType({
                    ...headcountDriverType,
                    disabled: false,
                  });
                }
              }}
              required
              disabled={false}
            />
          )}
          {type === 'custom' && (
            <FormulaBuilderInput
              formulaState={formulaInputState.formulaState}
              variablesState={formulaInputState.variables}
              formulaUuid={formulaInputState.formulaUuid}
              formulaTitle={formulaInputState.formulaTitle}
              variables={variables}
              setVariables={setVariables}
              formula={formula}
              setFormula={setFormula}
              updatedFormula={updatedFormula}
              setUpdatedFormula={setUpdatedFormula}
              isOpen={formulaInputState.isCustom}
              value={formulaValue}
              setValue={setFormulaValue}
              formulaList={formulaList}
              inputAttributeTitle={name.value}
              displayFormulaError={displayFormulaError}
              setDisplayFormulaError={setDisplayFormulaError}
            />
          )}
          <div className="flex flex-row gap-5">
            <div className="relative w-44" data-testid="create-expense-period-picker">
              <PeriodPicker
                id="expense-start-date-picker"
                label="Start Date"
                state={startDate}
                setState={setStartDate}
                beBefore={endDate.startDate}
              />
            </div>
            <div className={`relative w-44 ${frequency.selected?.value === 'oneTime' ? 'hidden' : ''}`}>
              <PeriodPicker
                id="expense-end-date-picker"
                label="End Date"
                clearable
                optional
                state={endDate}
                setState={setEndDate}
                beAfter={startDate.endDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}
        <div className="flex justify-between gap-5">
          <Button className="!w-auto !px-0" id="cancel-create-expense" fill="clear" onClick={onClose}>
            Cancel
          </Button>
          <Button id="save-expense" onClick={handleSave} className="!w-auto" loading={saveIsLoading}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateExpenseForm;
