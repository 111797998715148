import { useContext } from 'react';
import { IFormulasContext, FormulasContext } from './FormulasContext';

const useFormulaContext = (): IFormulasContext => {
  const formulasContext = useContext(FormulasContext);

  if (formulasContext) {
    return formulasContext;
  } else {
    throw new Error('No table context available. Make sure to wrap the component in a context provider.');
  }
};

export default useFormulaContext;
