import { store } from '~/store';
import serverRequest from '~/utils/request';
import { ZReportCollection } from '~/pages/Dashboard/entity/schemas';
import combineReportsIntoOne from '~/pages/Dashboard/utils/combineReportsIntoOne';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';

interface IAPIResponse {
  data: {
    data: [];
  };
  status: number;
}

export default async ({
  frequencyFilter,
  tagFilter,
}: {
  frequencyFilter: string;
  tagFilter: string;
}): Promise<unknown> => {
  const scenarioUuid = store.getState().scenario.activeScenarioUuid;
  const { defaultGraphStartDate, defaultGraphEndDate } = store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;

  const expenseFrequencyFilters = [frequencyFilter];
  const expenseTagFilters = [tagFilter];
  const expenseDriverFilters = ['all'];

  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  // Wrap requests in Promise.all
  const [expenseListResponse, filteredExpensesReport, positions, formulaList, connectedIntegrations] =
    await Promise.all([
      serverRequest({
        url: `/expenses/list`,
        method: 'GET',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
        params: {
          scenarioUuid: scenarioUuid ?? undefined,
          startDate: startDate,
          endDate: endDate,
        },
      }) as unknown as IAPIResponse,

      serverRequest({
        url: `/reports`,
        method: 'GET',
        params: {
          include: ['filteredExpenses'],
          startDate: startDate,
          endDate: endDate,
          scenarioUuid: scenarioUuid ?? undefined,
          compareData: false,
          expenseFrequencyFilters,
          expenseTagFilters,
          expenseDriverFilters,
        },
        headers: { 'Organization-Uuid': organizationUuid },
      }) as unknown as IAPIResponse,

      serverRequest({
        url: `/organizations/${organizationUuid}/positions`,
        method: 'GET',
        params: {
          scenarioUuid: scenarioUuid ?? undefined,
        },
      }) as unknown as IAPIResponse,

      serverRequest({
        url: '/formulas',
        method: 'GET',
        params: {
          startDate,
          endDate,
          includes: ['calculations'],
          scenarioUuid: scenarioUuid ?? undefined,
          types: [IFormulaTypeEnum.ModelBuilder, IFormulaTypeEnum.Expense],
          includeProposed: true,
        },
        headers: { 'Organization-Uuid': organizationUuid },
      }) as unknown as IAPIResponse,

      serverRequest({
        url: `/integrations`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Organization-Uuid': organizationUuid,
        },
      }) as unknown as IAPIResponse,
    ]);

  const parsedReport = ZReportCollection.parse(filteredExpensesReport.data.data);
  const consolidatedReports = combineReportsIntoOne({
    reports: [parsedReport],
  });

  return {
    expenses: expenseListResponse.data.data,
    reports: consolidatedReports,
    positions: positions.data.data,
    formulaList: formulaList.data.data,
    connectedIntegrations: connectedIntegrations.data.data,
  };
};
