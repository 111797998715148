import { z } from 'zod';
import { IExpense } from '~/pages/Expenses/components/types';
import { ZExpense } from '~/pages/Expenses/entity/schemas';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export const splitOutDataSourceFromExpense = async ({
  organizationUuid,
  expenseUuid,
  dataSourceUuidToSplit,
  remainingDataSourceUuids,
}: {
  organizationUuid: string;
  expenseUuid: string;
  dataSourceUuidToSplit: string;
  remainingDataSourceUuids: string[];
}): Promise<{ originalExpense: IExpense; newExpense: IExpense }> => {
  const response = (await request({
    url: `/expenses/${expenseUuid}/split`,
    method: 'POST',
    headers: { 'Organization-Uuid': organizationUuid },
    body: { dataSourceUuidToSplit, remainingDataSourceUuids },
  })) as IAPIResponse;
  return z
    .object({
      originalExpense: ZExpense,
      newExpense: ZExpense,
    })
    .parse(response.data.data);
};

export const doNotForecastDatasource = async ({
  organizationUuid,
  expenseUuid,
  dataSourceUuid,
}: {
  organizationUuid: string;
  expenseUuid: string;
  dataSourceUuid: string;
}): Promise<IExpense | null> => {
  const response = (await request({
    url: `/expenses/${expenseUuid}/remove-datasource`,
    method: 'PATCH',
    headers: { 'Organization-Uuid': organizationUuid },
    body: { datasourceUuidToRemove: dataSourceUuid },
  })) as IAPIResponse;
  const parsedResponse = z
    .object({
      updatedExpense: z.union([ZExpense, z.null()]),
    })
    .parse(response.data.data);
  return parsedResponse.updatedExpense;
};

export const moveDatasourceToDifferentExpense = async ({
  organizationUuid,
  expenseUuidToMoveTo,
  expenseUuidToMoveFrom,
  datasourceUuidToMove,
}: {
  organizationUuid: string;
  expenseUuidToMoveTo: string;
  expenseUuidToMoveFrom: string;
  datasourceUuidToMove: string;
}): Promise<{
  movedFromExpense: IExpense | null;
  movedToExpense: IExpense;
}> => {
  const response = (await request({
    url: `/expenses/move-datasource`,
    method: 'PATCH',
    headers: { 'Organization-Uuid': organizationUuid },
    body: { expenseUuidToMoveFrom, expenseUuidToMoveTo, datasourceUuidToMove },
  })) as IAPIResponse;
  return z
    .object({
      movedFromExpense: z.union([ZExpense, z.null()]),
      movedToExpense: ZExpense,
    })
    .parse(response.data.data);
};
