import React, { ReactElement, forwardRef } from 'react';
import Typography from '~/components/Typography';
import { NumericFormat } from 'react-number-format';
import BaseCurrencyInput from './currencyInput/BaseCurrencyInput';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface Props {
  id: string;
  state: Types.InputState;
  type: 'text' | 'password' | 'currency' | 'percentage' | 'textarea' | 'chat' | 'search';
  placeholder: string;
  label: string;
  subLabel: string;
  sideLabel?: string;
  className: string;
  onKeyDown: (event: React.KeyboardEvent) => void;
  onChange: (event: string) => void;
  onBlur?: () => void;
  showError: boolean;
  prepend?: React.ReactNode;
  required?: boolean;
  optional?: boolean;
  chatBoxRef?: React.RefObject<HTMLTextAreaElement>;
  textRef?: React.RefObject<HTMLInputElement>;
  borderOnFocus?: boolean;
  decimalsLimit?: number;
  includeDollarSign?: boolean;
  append?: React.ReactNode;
  onFocus?: () => void;
}

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      id,
      label,
      subLabel,
      sideLabel,
      showError,
      className,
      type,
      placeholder,
      state,
      onChange,
      onBlur = (): void => {},
      onKeyDown,
      prepend,
      required,
      optional,
      chatBoxRef,
      textRef,
      borderOnFocus = false,
      decimalsLimit,
      includeDollarSign,
      append,
      onFocus,
    },
    ref,
  ): ReactElement => (
    <div className="flex flex-col justify-start w-full">
      <label htmlFor={`${id}-input`} className="inline-flex flex-col">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <Typography
              tag="span"
              size="xs"
              className={`${state.disabled ? ' !text-neutral-75' : ''}${label && ' mb-1'}`}
              id={`${id}-label`}
            >
              {label}
            </Typography>
            {required && (
              <Typography tag="span" size="2xs" className={`${state.disabled ? ' !text-neutral-75' : ''}`}>
                *
              </Typography>
            )}
            {sideLabel && (
              <Typography className="ml-1" tag="span" size="xs" color="empty">
                {sideLabel}
              </Typography>
            )}
            {optional && (
              <Typography className="ml-1" tag="span" size="xs" color="empty">
                {'(optional)'}
              </Typography>
            )}
          </div>
          {subLabel && (
            <Typography tag="span" size="xs" color="empty" className="mb-1.5">
              {subLabel}
            </Typography>
          )}
        </div>
        <div className="relative flex-col w-full">
          {prepend && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">{prepend}</div>
          )}
          {(type === 'text' || type === 'password') && (
            <div className="relative">
              <input
                id={`${id}-input`}
                autoComplete="off"
                data-testid={`${id}-input`}
                className={`${className} ${prepend && '!pl-10'} px-[0.57rem] h-[42px] w-full ${
                  borderOnFocus
                    ? `border-transparent hover:border hover:border-solid hover:border-gray-300 focus:border focus:border-solid focus:border-gray-300 shadow-none`
                    : `border border-solid border-gray-300`
                } ${showError && '!border-red-300'} disabled:bg-neutral-25 disabled:text-neutral-75 focus:outline-none focus-visible:border-green-500 
              focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 
              focus-visible:ring-offset-green-300 rounded shadow-sm`}
                disabled={state.disabled}
                type={type}
                placeholder={placeholder}
                value={state.value}
                onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                required={required}
                ref={ref ?? textRef}
              />
              {append && <div className="absolute inset-y-0 right-0 pr-2 flex items-center">{append}</div>}
            </div>
          )}
          {type === 'search' && (
            <div className="relative">
              <input
                id={`${id}-input`}
                autoComplete="off"
                data-testid={`${id}-input`}
                className={`${className} ${prepend && '!pl-10'} px-[0.57rem] h-[42px] w-full border border-solid ${
                  showError ? 'border-red-300' : 'border-gray-300'
                } disabled:bg-neutral-25 disabled:text-neutral-75 focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 
                focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded 
                shadow-sm`}
                disabled={state.disabled}
                type="text"
                placeholder={placeholder}
                value={state.value}
                onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                required={required}
                ref={ref}
              />
              {state.value.trim() === '' ? (
                <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon className="size-4" />
                </div>
              ) : (
                <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
                  <XMarkIcon
                    className="size-4 cursor-pointer"
                    onMouseUp={() => {
                      onChange('');
                      if (ref && 'current' in ref && ref.current) {
                        ref.current.blur();
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {type === 'textarea' && (
            <textarea
              id={`${id}-textarea`}
              autoComplete="off"
              data-testid={`${id}-textarea`}
              rows={3}
              className={`${className} ${prepend && '!pl-10'} w-full border border-solid ${
                showError ? 'border-red-300' : 'border-gray-300'
              } focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
              focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded shadow-sm block p-2.5
            `}
              disabled={state.disabled}
              placeholder={placeholder}
              value={state.value}
              onChange={(event: React.FormEvent<HTMLTextAreaElement>) => onChange(event.currentTarget.value)}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              required={required}
              ref={ref}
              onFocus={onFocus}
            />
          )}
          {type === 'chat' && (
            <textarea
              id={`${id}-chat`}
              autoComplete="off"
              data-testid={`${id}-chat`}
              rows={1}
              className={`${className} w-full flex flex-grow max-h-[150px] border border-solid border-neutral-100 placeholder:text-neutral-100 
              focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
              focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded-lg py-3.5 pl-5 pr-[54px] shadow-sm block resize-none no-scrollbar
            `}
              disabled={state.disabled}
              placeholder={placeholder}
              value={state.value}
              onChange={(event: React.FormEvent<HTMLTextAreaElement>) => onChange(event.currentTarget.value)}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              required={required}
              ref={chatBoxRef ?? ref}
              onFocus={onFocus}
            />
          )}
          {type === 'currency' && (
            <BaseCurrencyInput
              id={id}
              className={className}
              placeholder={placeholder}
              value={state.value}
              disabled={state.disabled}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onChange={onChange}
              required={required}
              showError={showError}
              decimalsLimit={decimalsLimit}
              ref={ref}
              includeDollarSign={includeDollarSign}
              onFocus={onFocus}
            />
          )}
          {type === 'percentage' && (
            <NumericFormat
              autoComplete="off"
              data-testid={`${id}-input`}
              className={`${className} ${prepend && '!pl-10'} ${
                showError && state.errorMessage.length > 0 ? '!border-red-300' : 'border-gray-300'
              } px-[0.57rem] h-[42px] w-full border border-solid disabled:bg-neutral-25 disabled:text-neutral-75 
                focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
                focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 rounded shadow-sm`}
              placeholder={placeholder}
              disabled={state.disabled}
              allowLeadingZeros
              allowNegative={false}
              value={state.value}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onValueChange={(values) => {
                onChange(values.value);
              }}
              suffix="%"
              required={required}
              getInputRef={ref}
              onFocus={onFocus}
            />
          )}
          {showError && state.errorMessage.length > 0 && (
            <p className="text-red-500 text-xs italic p-1" data-testid={`${id}-input-error`}>
              {state.errorMessage}
            </p>
          )}
        </div>
      </label>
    </div>
  ),
);

Input.displayName = 'Input';
export default Input;
