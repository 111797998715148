import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React, { useRef } from 'react';
import Typography from '~/components/Typography';
import '../ExpressionBuilder/styles.css';
import useClickOutside from '~/utils/hooks/useClickOutside';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

const BasicFormulaNodeView = ({ node, selected, updateAttributes }: NodeViewProps): React.ReactNode => {
  const { viewOnly } = useFormulaContext();
  const { label, formulaUuid } = node.attrs;
  const nodeRef = useRef<HTMLDivElement>(null);

  useClickOutside(nodeRef, () => {
    updateAttributes({ selected: false });
  });

  const classStyle = selected && !viewOnly ? 'border-green-400 bg-green-25 ' : 'border-neutral-100 bg-neutral-15';

  return (
    <NodeViewWrapper
      ref={nodeRef}
      className={`formula-node inline-flex items-center`}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        if (viewOnly) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div
        data-testid={`formula-node-${formulaUuid}`}
        className={`rounded-full whitespace-nowrap border ${classStyle} inline-flex items-center`}
      >
        <div className={`px-3 `}>
          <Typography className="inline" color={selected ? 'green' : 'primary'}>
            {label}
          </Typography>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default BasicFormulaNodeView;
