import { IStringDate } from './types';

/*
 * This will get the string date of the current date or the date passed in as perceived by the user's timezone
 * you can manually set the offset if there's a case you know you need to override the user's timezone
 */
export const getStringDate = (date?: Date, offset?: number): IStringDate => {
  date = date ?? new Date();
  offset = typeof offset === 'number' ? offset : date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() + offset * 60000);
  const year = adjustedDate.getFullYear();
  const month = (adjustedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = adjustedDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};
