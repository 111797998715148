import React, { ReactElement, useContext, useMemo } from 'react';
import BaseTable from '~/components/Table/Base/BaseTable';
import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import formatCurrency from '~/utils/formatCurrency';
import Typography from '~/components/Typography';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { IContractTableRow, IColumnId, IColumnIdEnum } from '../entity/types';
import { ContractsContext } from '../context/ContractsContext';
import { getBillingScheduleDisplay } from './getBillingScheduleDisplay';
import HoverPopover from '~/components/HoverPopover';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import parseCurrencyToNumber from '~/utils/parseCurrencyToNumber';
import { ZColumnId } from '../entity/schema';
import expenseModelEmptyState from '~/assets/expenseModelEmptyState.svg';
import * as stringDate from '~/utils/stringDate';
const isSortable = (columnId: IColumnId): boolean => {
  return ['customer', 'billingSchedule', 'annualValue', 'setupFee', 'invoiceDate', 'startDate', 'endDate'].includes(
    columnId,
  );
};

const DetailsTable = (): ReactElement => {
  const {
    filteredContracts,
    setShowNewContractModal,
    setShowDeleteContractModal,
    setShowDiscontinueContractModal,
    setTableSorting,
    tableSorting,
  } = useContext(ContractsContext);

  const { tableData, tableColumns } = useMemo(() => {
    const columns = [
      { id: 'customer', label: 'CUSTOMER' },
      { id: 'billingSchedule', label: 'BILLING SCHEDULE' },
      { id: 'annualValue', label: 'ANNUAL VALUE' },
      { id: 'setupFee', label: 'SETUP FEE' },
      { id: 'invoiceDate', label: 'INVOICE DATE' },
      { id: 'startDate', label: 'START DATE' },
      { id: 'endDate', label: 'END DATE' },
      { id: 'ellipsisDropdown', label: '' },
    ];

    const columnHelper = createColumnHelper<IContractTableRow>();

    const tableColumns = columns.map((col) =>
      columnHelper.accessor(col.id as IColumnId, {
        enableResizing: false,
        enablePinning: false,
        header: () =>
          col.id === 'invoiceDate' ? (
            <div className="flex items-center gap-1">
              <div className="text-xs font-normal text-neutral-200">{col.label}</div>
              <HoverPopover
                buttonContent={
                  <div className="text-left flex items-center gap-1">
                    <InformationCircleIcon className="size-4" />{' '}
                  </div>
                }
                panelContent={
                  <div className="py-3 px-4 bg-black max-w-[260px] text-left">
                    <Typography color="white" size="xs">
                      The date the invoice is issued, indicating when cash is expected.
                    </Typography>
                  </div>
                }
                panelClassName="shadow-md rounded-lg"
              />
            </div>
          ) : col.id === 'startDate' ? (
            <div className="flex items-center gap-1">
              <div className="text-xs font-normal text-neutral-200">{col.label}</div>
              <HoverPopover
                buttonContent={
                  <div className="text-left flex items-center gap-1">
                    <InformationCircleIcon className="size-4" />
                  </div>
                }
                panelContent={
                  <div className="py-3 px-4 bg-black max-w-[250px] text-left">
                    <Typography color="white" size="xs">
                      The date when revenue is recognized, reflecting when the service or product is delivered.
                    </Typography>
                  </div>
                }
                panelClassName="shadow-md rounded-lg"
              />
            </div>
          ) : (
            col.label.toUpperCase()
          ),
        cell: (info) => (
          <Typography id={`contract-${col.id}-${info.row.index}`}>{info.row.original[col.id as IColumnId]}</Typography>
        ),
        enableSorting: isSortable(col.id as IColumnId),
        sortingFn: (rowA, rowB, columnId) => {
          switch (columnId) {
            case IColumnIdEnum.Customer:
              return rowA.original.customer.localeCompare(rowB.original.customer);
            case IColumnIdEnum.BillingSchedule:
              return rowA.original.billingSchedule.localeCompare(rowB.original.billingSchedule);
            case IColumnIdEnum.AnnualValue:
              return (
                parseCurrencyToNumber(rowA.original.annualValue) - parseCurrencyToNumber(rowB.original.annualValue)
              );
            case IColumnIdEnum.SetupFee:
              return parseCurrencyToNumber(rowA.original.setupFee) - parseCurrencyToNumber(rowB.original.setupFee);
            case IColumnIdEnum.InvoiceDate:
              return new Date(rowA.original.invoiceDate).getTime() - new Date(rowB.original.invoiceDate).getTime();
            case IColumnIdEnum.StartDate:
              return new Date(rowA.original.startDate).getTime() - new Date(rowB.original.startDate).getTime();
            case IColumnIdEnum.EndDate:
              return new Date(rowA.original.endDate).getTime() - new Date(rowB.original.endDate).getTime();
            default:
              return 0;
          }
        },
      }),
    );

    const tableData = filteredContracts.map((contract) => {
      const { contractTitle, billingSchedule, annualValue, setupFee, invoiceDate, startDate, endDate, contractUuid } =
        contract;
      return {
        customer: contractTitle,
        billingSchedule: getBillingScheduleDisplay(billingSchedule),
        annualValue: formatCurrency(annualValue),
        setupFee: formatCurrency(setupFee ?? 0),
        invoiceDate: stringDate.format(invoiceDate, 'MMMM yyyy'),
        startDate: stringDate.format(startDate, 'MMMM yyyy'),
        endDate: endDate ? stringDate.format(endDate, 'MMMM yyyy') : '-',
        ellipsisDropdown: (
          <EllipsisDropdown
            id={`contract-ellipsis-dropdown-${contractUuid}`}
            options={[
              { label: 'Edit', onClick: () => setShowNewContractModal(contract) },
              {
                label: 'Discontinue',
                onClick: () => setShowDiscontinueContractModal(contract),
                className: 'text-red-500',
              },
              { label: 'Delete', onClick: () => setShowDeleteContractModal(contract), className: 'text-red-500' },
            ]}
          />
        ),
      };
    });

    return { tableData, tableColumns };
  }, [filteredContracts]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData as unknown as IContractTableRow[],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: (updaterOrValue) => {
      const newSorting =
        typeof updaterOrValue === 'function' ? updaterOrValue(table.getState().sorting) : updaterOrValue;
      if (newSorting.length > 0) {
        const { id: columnId } = newSorting[0];
        const newDesc = tableSorting.columnId === columnId ? !tableSorting.desc : false;
        const parsedColumnId = ZColumnId.parse(columnId);
        setTableSorting({ columnId: parsedColumnId, desc: newDesc });
      }
    },
  });

  const tableEmptyState = (
    <div className="flex flex-col items-center gap-2 py-16">
      <img src={expenseModelEmptyState} alt="Empty Tasks Illustration" className="w-64 h-auto" />
      <div className="flex flex-col items-center gap-4 max-w-[512px] text-center">
        <Typography color="empty" size="sm">
          This will assist your organization in managing contracts effectively, highlight the total value of contracts,
          and facilitate informed decision-making regarding your financial resources.
        </Typography>
        <Typography color="empty" size="sm">
          Click Add Contract to get started.
        </Typography>
      </div>
    </div>
  );

  if (!tableData.length) return tableEmptyState;

  return (
    <div className="w-full px-10 max-h-[100%] overflow-x-scroll overflow-y-scroll hide-scrollbar">
      <BaseTable
        id="contracts-table"
        // @ts-expect-error - BaseTable doesn't like typed rows
        table={table}
        styles={{
          table: 'w-full',
          th: 'py-2 text-xs font-normal text-neutral-200 text-nowrap',
          td: 'h-14  border-t border-b border-gray-200 text-nowrap',
        }}
        emptyState={tableEmptyState}
        showHeaderTitle={false}
      />
    </div>
  );
};

export default DetailsTable;
