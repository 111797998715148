import React, { useState } from 'react';
import Header from '~/components/Header';
import ActualsAndOverridesLegend from '~/components/ActualsAndOverridesLegend/ActualsAndOverridesLegend';
import UserDateRange from '~/components/UserDateRange';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import Modal from '~/components/Modal';
import ManageGroups from './ManageGroups';
import useFormulaContext from '../../../components/Formulas/context/useFormulaContext';
import PrebuiltComponentsModal from './PrebuiltComponents/PrebuiltComponentsModal';
import ShareLinksModal from '~/components/ShareLinksModal/ShareLinksModal';
import ScenarioDropdown from '~/components/ScenarioDropdown';
import PullAccountingActuals from '~/components/PullAccountingActuals';
import { IPrebuiltComponentType } from './PrebuiltComponents/PrebuiltOptions';

export interface IPrebuiltComponentModalState {
  isOpen: boolean;
  display: IPrebuiltComponentType;
}

const FinancialModelHeader = (): React.ReactElement => {
  const { csvExportData, manageGroupsModalOpen, setManageGroupsModalOpen, availableIntegration, refreshData } =
    useFormulaContext();
  const [prebuiltComponentModalState, setPrebuiltComponentModalState] = useState<IPrebuiltComponentModalState>({
    isOpen: false,
    display: IPrebuiltComponentType.Options,
  });
  const [showShareLinksModal, setShowShareLinksModal] = useState(false);

  const downloadCsv = (csvData: string): void => {
    const blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'financial_model_export.csv';
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <Header
        title="Financial Model"
        startChildren={
          <div className="flex flex-row gap-2">
            <ScenarioDropdown />
            <ActualsAndOverridesLegend />
          </div>
        }
        endChildren={
          <div className="flex items-center gap-2">
            {availableIntegration && <PullAccountingActuals refreshData={refreshData} />}
            <div className="flex w-full justify-end items-center">
              <UserDateRange pickerAlignment="right" />
            </div>
            <EllipsisDropdown
              options={[
                {
                  label: 'Manage Groups',
                  onClick: () => setManageGroupsModalOpen(true),
                },
                {
                  label: 'Export Values',
                  onClick: () => downloadCsv(csvExportData),
                },
                {
                  label: 'Add Pre-built Components',
                  onClick: () =>
                    setPrebuiltComponentModalState({
                      isOpen: true,
                      display: IPrebuiltComponentType.Options,
                    }),
                },
                {
                  label: 'Share',
                  onClick: () => setShowShareLinksModal(true),
                },
              ]}
            />
          </div>
        }
      />
      <Modal
        isOpen={manageGroupsModalOpen}
        onClose={() => setManageGroupsModalOpen(false)}
        title="Edit Groups"
        size="xxs"
      >
        <ManageGroups onClose={() => setManageGroupsModalOpen(false)} />
      </Modal>
      <PrebuiltComponentsModal
        prebuiltComponentModalState={prebuiltComponentModalState}
        setPrebuiltComponentModalState={setPrebuiltComponentModalState}
      />
      <ShareLinksModal showModal={showShareLinksModal} setShowModal={setShowShareLinksModal} />
    </>
  );
};

export default FinancialModelHeader;
