import React, { createContext, useMemo, ReactElement, useState } from 'react';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { ZDashboardLoader } from '../entity/schemas';
import useDashboardData from '../useDashboardData';
import { IGraphData } from '../entity/types';

interface IProps {
  children: React.ReactNode;
}

interface IDashboardPageContext {
  reports: IGraphData;
  queryParams: URLSearchParams | null;
  setQueryParams: (newParams: Record<string, string>) => void;
  reload: () => Promise<void>;
  pageLoading: boolean;
  userActiveIndex: number;
  setUserActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  scenarioStatusText: string;
  setScenarioStatusText: React.Dispatch<React.SetStateAction<string>>;
}

const initialContext: IDashboardPageContext = {
  reports: {} as IGraphData,
  queryParams: null,
  setQueryParams: () => {},
  reload: async () => {},
  pageLoading: true,
  userActiveIndex: -1,
  setUserActiveIndex: () => {},
  scenarioStatusText: 'Generating Scenario',
  setScenarioStatusText: () => {},
};

export const DashboardPageContext = createContext<IDashboardPageContext>(initialContext);

export const DashboardPageContextProvider = ({ children }: IProps): ReactElement => {
  const [queryParams, setQueryParams] = useQueryParams();
  const [userActiveIndex, setUserActiveIndex] = React.useState<number>(-1);
  const [scenarioStatusText, setScenarioStatusText] = useState<string>('Genearting Scenario');
  const { data, revalidate, loading } = useDashboardData();
  const { reportData } = useMemo(() => {
    if (!data)
      return {
        ...initialContext,
        reportData: {} as IGraphData,
      };
    return ZDashboardLoader.parse(data);
  }, [data]);

  return (
    <DashboardPageContext.Provider
      value={{
        reports: reportData,
        queryParams,
        setQueryParams,
        reload: revalidate,
        pageLoading: loading,
        userActiveIndex,
        setUserActiveIndex,
        scenarioStatusText,
        setScenarioStatusText,
      }}
    >
      {children}
    </DashboardPageContext.Provider>
  );
};
