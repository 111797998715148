import { z } from 'zod';
import request from '~/utils/request';
import { IExpense } from '../types';
import { ZExpense } from '../../entity/schemas';
import { IStringDate, ZStringDate } from '~/utils/stringDate/types';
import { IFormula, IRecipe, IRecipeVariables, ZRecipe } from '~/services/parallel/formulas.types';
import validateFormula from '~/components/Formulas/ExpressionBuilder/utils/validateFormula';
import { IUpdatedFormula } from '../../context/ExpensesContext';
interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export default async ({
  organizationUuid,
  scenarioUuid,
  expenseUuid,
  type,
  name,
  category,
  frequency,
  amount,
  departments,
  startDate,
  endDate,
  updatedFormula,
  variables,
  dataSourceUuids,
  successCallback,
  failureCallback,
  validateFormState,
  formulaList,
  setDisplayFormulaError,
  desiredCreationStatus,
}: {
  organizationUuid: string;
  scenarioUuid: string | null;
  expenseUuid: { uuid: string; expenseUuid: string; type: 'edit' | 'create' } | undefined;
  type: string | null;
  name: string;
  category: string | null;
  frequency: string | null;
  amount: string;
  departments: (string | null | undefined)[] | null;
  startDate: IStringDate | null;
  endDate: IStringDate | null;
  updatedFormula: IUpdatedFormula[];
  variables: IRecipeVariables;
  dataSourceUuids?: string[];
  formulaList: IFormula[];
  successCallback: ({ createdExpense }: { createdExpense: IExpense }) => void;
  failureCallback: () => void;
  validateFormState: () => boolean;
  setDisplayFormulaError?: React.Dispatch<
    React.SetStateAction<{
      isDisplayed: boolean;
      message: string;
    }>
  >;
  desiredCreationStatus?: string;
}): Promise<void> => {
  const isValid = validateFormState();

  if (!isValid) return;

  const ZValidateExpenseRequestData = z
    .object({
      organizationUuid: z.string(),
      scenarioUuid: z.string().nullable(),
      type: z.string(),
      name: z.string().min(1),
      category: z.string(),
      frequency: z.string(),
      creationStatus: z.string(),
      amountForExpense: z
        .string()
        .min(0)
        .pipe(z.coerce.number())
        .transform((val) => val * 100)
        .optional(),
      departments: z.array(z.string()),
      startDate: ZStringDate,
      endDate: ZStringDate.nullable(),
      recipe: ZRecipe.optional(),
      dataSourceUuids: z.array(z.string()).optional(),
    })
    .refine(
      (val) => {
        if (val.type !== 'custom' && !val.amountForExpense && val.amountForExpense !== 0) {
          return false;
        }
        if (val.type === 'headcountPercentCompensation' && val.amountForExpense && val.amountForExpense > 10000) {
          return false;
        }
        return true;
      },
      {
        message: 'Amount must not exceed 10,000 for the specified type.',
        path: ['amount'],
      },
    )
    .refine((val) => {
      if (val.type === 'custom') {
        if ((!val.recipe || Object.keys(val.recipe.variables).length === 0) && expenseUuid?.type === 'create') {
          return false;
        }
      }
      return true;
    })
    .refine(
      (val) => {
        if (val.category === 'Cost of Goods Sold') {
          return val.departments.length === 0;
        }
        return val.departments.length > 0;
      },
      {
        message:
          'If category is "Cost of Goods Sold", departments must be empty. Otherwise, it must have at least one string.',
        path: ['departments'],
      },
    );

  let recipe;
  if (type === 'custom' && updatedFormula.length > 0) {
    const expression = updatedFormula.map((segment) => segment.textValue).join('');
    const updatedFormulaList = formulaList.map((formula) => {
      if (formula.formulaUuid === expenseUuid?.uuid) {
        return { ...formula, expression };
      }
      return formula;
    });
    const { validated, errorMessage } = validateFormula({
      formulaList: updatedFormulaList,
      expression,
      recipeVariables: variables,
      formulaUuid: expenseUuid?.uuid,
    });
    if (!validated) {
      setDisplayFormulaError && setDisplayFormulaError({ isDisplayed: true, message: errorMessage });
      return;
    }
    recipe = {
      name: name,
      expression,
      variables,
    };
  }

  let amountForExpense;
  if (type !== 'custom') {
    amountForExpense = amount;
  }

  const objectToValidate = {
    organizationUuid,
    scenarioUuid,
    type,
    name,
    category,
    frequency,
    amountForExpense,
    startDate,
    endDate,
    recipe,
    departments,
    dataSourceUuids,
    creationStatus: desiredCreationStatus ?? 'created',
  };

  if (type === 'custom') {
    delete objectToValidate.amountForExpense;
  } else {
    delete objectToValidate.recipe;
  }

  const { data: parsedData, success: dataIsValid } = ZValidateExpenseRequestData.safeParse(objectToValidate);

  if (dataIsValid) {
    const updateBody: {
      name: string;
      context: {
        driver: string;
        tag: string;
        frequency: string;
        startDate: string | null;
        endDate: string | null;
        amount?: number;
        departments: string[];
      };
      dataSourceUuids?: string[];
      creationStatus: string;
      recipe?: IRecipe;
    } = {
      name: parsedData.name,
      context: {
        driver: parsedData.type,
        tag: parsedData.category,
        frequency: parsedData.frequency,
        startDate: parsedData.startDate,
        endDate: parsedData.endDate,
        departments: parsedData.departments,
      },
      creationStatus: parsedData.creationStatus,
    };

    if (parsedData.type !== 'custom') {
      updateBody.context.amount = parsedData.amountForExpense;
    }

    if (parsedData.type === 'custom' && updatedFormula.length > 0) {
      updateBody.recipe = parsedData.recipe;
    }

    if (parsedData.dataSourceUuids) {
      updateBody.dataSourceUuids = parsedData.dataSourceUuids;
    }

    let response;
    if (expenseUuid && expenseUuid.type === 'edit') {
      response = (await request({
        method: 'PATCH',
        url: `/expenses/${expenseUuid.expenseUuid}`,
        headers: { 'Organization-Uuid': organizationUuid },
        body: updateBody,
        params: { scenarioUuid: scenarioUuid, awaitCalculations: true },
      })) as IAPIResponse;
    } else {
      response = (await request({
        method: 'POST',
        url: `/expenses`,
        headers: { 'Organization-Uuid': organizationUuid },
        body: updateBody,
        params: { scenarioUuid: scenarioUuid, awaitCalculations: true },
      })) as IAPIResponse;
    }
    const parsedResponseData = ZExpense.parse(response.data.data);
    if ([200, 201].includes(response.status)) {
      successCallback({ createdExpense: parsedResponseData });
    } else {
      failureCallback();
    }
  }
};
