import { z } from 'zod';
import iso8601Validator from '~/utils/zodValidationHelpers/iso8601Validator';
import { IBillingScheduleEnum, IColumnIdEnum } from './types';
import { ZFormula } from '~/services/parallel/formulas.types';
import { ZStringDate } from '~/utils/stringDate/types';

export const ZContract = z.object({
  uuid: z.string().uuid(),
  contractUuid: z.string().uuid(),
  organizationUuid: z.string().uuid(),
  scenarioUuid: z.string().uuid().nullable(),
  contractTitle: z.string(),
  annualValue: z.union([z.number(), z.string().transform((val) => parseFloat(val))]),
  setupFee: z.union([z.number(), z.string().transform((val) => parseFloat(val))]).nullable(),
  invoiceDate: ZStringDate,
  startDate: ZStringDate,
  endDate: ZStringDate.nullable(),
  createdAt: iso8601Validator,
  createdBy: z.string().uuid().nullable(),
  planDate: iso8601Validator,
  deletedAt: iso8601Validator.nullable(),
  deletedBy: z.string().uuid().nullable(),
  billingSchedule: z.nativeEnum(IBillingScheduleEnum),
  formulas: z.array(ZFormula),
});

export const ZContractsLoader = z.object({
  contracts: z.array(ZContract),
});

export const ZColumnId = z.nativeEnum(IColumnIdEnum);
