import { z } from 'zod';
import request, { IRequest } from '~/utils/request';
import {
  IFormattingEnum,
  IFormula,
  IFormulaDependencyGraph,
  IFormulaSort,
  IFormulaTypeEnum,
  IPullAccountingActualsParams,
  IRecipe,
  IUpdateSortOrderParams,
  ZFormula,
  ZFormulaDependencyGraph,
  ZFormulaSort,
} from './formulas.types';
import { IAPIResponse } from '~/utils/types';
import { StatusCodes } from 'http-status-codes';
import { IStringDate } from '~/utils/stringDate/types';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import * as stringDate from '~/utils/stringDate';
import { IPrebuiltComponentType } from '~/pages/FinancialModel/components/PrebuiltComponents/PrebuiltOptions';

export const list = async ({
  organizationUuid,
  startDate,
  endDate,
  scenarioUuid,
  types,
  accessTokenOverride,
}: {
  organizationUuid: string;
  startDate: Date | IStringDate;
  endDate: Date | IStringDate;
  scenarioUuid?: string;
  types?: IFormulaTypeEnum[];
  accessTokenOverride?: string | null;
}): Promise<IFormula[]> => {
  const formulas = (await request({
    url: `/formulas`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    params: {
      startDate: typeof startDate === 'string' ? startDate : startDate.toISOString(),
      endDate: typeof endDate === 'string' ? endDate : endDate.toISOString(),
      includes: ['calculations'],
      scenarioUuid,
      types,
    },
    accessTokenOverride,
  })) as IAPIResponse;

  return z.array(ZFormula).parse(formulas.data.data);
};

export const getSortOrder = async ({
  organizationUuid,
  scenarioUuid,
  accessTokenOverride,
}: {
  organizationUuid: string;
  scenarioUuid?: string;
  accessTokenOverride?: string | null;
}): Promise<IFormulaSort> => {
  const sortOrder = (await request({
    url: `/formulas/sorting`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    params: { scenarioUuid },
    accessTokenOverride,
  })) as IAPIResponse;

  return ZFormulaSort.parse(sortOrder.data.data);
};

export const updateSortOrder = async ({
  organizationUuid,
  scenarioUuid,
  groups,
}: IUpdateSortOrderParams): Promise<IFormulaSort> => {
  const data = {
    url: `/formulas/sorting`,
    method: 'PATCH',
    headers: { 'Organization-Uuid': organizationUuid },
    body: {
      groups,
    },
    params: {},
  };

  if (scenarioUuid) {
    data.params = { scenarioUuid };
  }

  const updateSortOrder = (await request({
    ...data,
    method: 'PATCH' as const,
  })) as IAPIResponse;

  return ZFormulaSort.parse(updateSortOrder.data.data);
};

export interface IUpdateMonthValueParams {
  organizationUuid: string;
  formulaUuid: string;
  scenarioUuid?: string;
  recipe?: IRecipe;
  actuals?: { date: string | Date; value: number | null }[];
  overrides?: { date: string | Date; value: number | null }[];
  latestNeededDate?: IStringDate;
  idempotencyKey?: string;
  formatting?: IFormattingEnum;
  dataSourceUuids?: string[];
  createScenario?: boolean;
}

export const update = async ({
  organizationUuid,
  formulaUuid,
  actuals,
  overrides,
  recipe,
  latestNeededDate,
  idempotencyKey,
  formatting,
  dataSourceUuids,
  scenarioUuid,
  createScenario,
}: IUpdateMonthValueParams): Promise<void> => {
  const data: IRequest = {
    url: `/formulas/${formulaUuid}`,
    method: 'PATCH' as const,
    headers: { 'Organization-Uuid': organizationUuid },
    params: { scenarioUuid, createScenario },
  };

  if (actuals)
    data.body = {
      ...data.body,
      actuals: actuals.map((actual) => ({ ...actual, date: stringDate.getISO8601EndOfMonth(actual.date.toString()) })),
    };
  if (overrides)
    data.body = {
      ...data.body,
      overrides: overrides.map((override) => ({
        ...override,
        date: stringDate.getISO8601EndOfMonth(override.date.toString()),
      })),
    };
  if (recipe) data.body = { ...data.body, recipe };
  if (dataSourceUuids) data.body = { ...data.body, dataSourceUuids };
  if (formatting) data.body = { ...data.body, formatting };

  if (latestNeededDate) data.params = { ...data.params, latestNeededDate: latestNeededDate };
  if (scenarioUuid) data.params = { ...data.params, scenarioUuid };

  if (idempotencyKey) {
    data.headers = {
      ...data.headers,
      'Idempotency-Key': idempotencyKey,
    };
  }

  const response = (await request(data)) as IAPIResponse;
  if (createScenario) await handleCreateScenario({ response });
};

export const createAttribute = async ({
  organizationUuid,
  groupIndex,
  name,
  currentSortOrder,
  scenarioUuid,
}: {
  organizationUuid: string;
  groupIndex: number;
  name: string;
  currentSortOrder?: { name: string; sortOrder: string[] }[];
  scenarioUuid?: string;
}): Promise<IFormula> => {
  const response = (await request({
    url: `/formulas`,
    method: 'POST',
    headers: { 'Organization-Uuid': organizationUuid },
    body: {
      recipe: {
        name,
        expression: '$1',
        variables: {
          $1: { type: 'constant', formulaUuid: null, constantValue: 0, timeModifier: {}, calculationType: null },
        },
        roundingInstructions: null,
      },
      dataSourceUuids: [],
      formatting: 'number',
    },
    params: { scenarioUuid },
  })) as IAPIResponse;

  const createdAttribute = ZFormula.parse(response.data.data);

  if (currentSortOrder) {
    /**
     * Update the sort order to include the new attribute
     */
    const newSortOrder = currentSortOrder.map((group, index) => {
      if (groupIndex === index) {
        return { ...group, sortOrder: [createdAttribute.formulaUuid, ...group.sortOrder] };
      }
      return group;
    });

    await updateSortOrder({ organizationUuid, groups: newSortOrder });
  }

  return createdAttribute;
};

export const remove = async ({
  organizationUuid,
  formulaUuid,
  scenarioUuid,
}: {
  organizationUuid: string;
  formulaUuid: string;
  scenarioUuid?: string;
}): Promise<void> => {
  await request({
    url: `/formulas/${formulaUuid}`,
    method: 'DELETE',
    headers: { 'Organization-Uuid': organizationUuid },
    params: { scenarioUuid },
  });
};

export const getDependencyGraph = async ({
  organizationUuid,
  scenarioUuid,
  accessTokenOverride,
}: {
  organizationUuid: string;
  scenarioUuid?: string;
  accessTokenOverride?: string | null;
}): Promise<IFormulaDependencyGraph> => {
  const response = (await request({
    url: `/formulas/dependency-graph`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    params: { scenarioUuid },
    accessTokenOverride,
  })) as IAPIResponse;

  return ZFormulaDependencyGraph.parse(response.data.data);
};

export const pullAccountingActuals = async ({
  organizationUuid,
  startDate,
  endDate,
  scenarioUuid,
}: IPullAccountingActualsParams): Promise<void> => {
  const response = (await request({
    url: `/formulas/update-actuals`,
    method: 'POST',
    body: {
      startDate: startDate,
      endDate: endDate,
      scenarioUuid: scenarioUuid ?? undefined,
    },
    headers: {
      'Content-Type': 'application/json',
      'Organization-Uuid': organizationUuid,
    },
  })) as IAPIResponse;

  if (response.status !== StatusCodes.OK) throw new Error('Unable to update actuals');

  return;
};

export const createLoanSchedule = async ({
  organizationUuid,
  scenarioUuid,
  titlePrefix,
  loanAmount,
  annualInterestRate,
  loanTerm,
  startDate,
}: {
  organizationUuid: string;
  scenarioUuid?: string | null;
  titlePrefix: string;
  loanAmount: number;
  annualInterestRate: number;
  loanTerm: number;
  startDate: string;
}): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: `formulas/template/loan-schedule`,
    body: {
      titlePrefix,
      loanAmount,
      annualInterestRate,
      loanTerm,
      startDate,
    },
    params: {
      scenarioUuid,
    },
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  });

  if (response.status !== StatusCodes.CREATED) throw new Error('Failed to create loan amortization schedule');

  return;
};

export const createMarketingFunnel = async ({
  organizationUuid,
  scenarioUuid,
  titlePrefix,
  attributeUuid,
  percentOfSalesFromMarketing,
  averageCostPerLead,
  percentOfLeadsToDemos,
  percentOfDemosHeld,
  percentOfDemosClosed,
}: {
  organizationUuid: string;
  scenarioUuid?: string | null;
  titlePrefix: string;
  attributeUuid?: string;
  percentOfSalesFromMarketing: number;
  averageCostPerLead: number;
  percentOfLeadsToDemos: number;
  percentOfDemosHeld: number;
  percentOfDemosClosed: number;
}): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: `formulas/template/marketing-funnel`,
    body: {
      titlePrefix,
      attributeUuid,
      percentOfSalesFromMarketing,
      averageCostPerLead,
      percentOfLeadsToDemos,
      percentOfDemosHeld,
      percentOfDemosClosed,
    },
    params: {
      scenarioUuid,
    },
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  });

  if (response.status !== StatusCodes.CREATED) throw new Error('Failed to create marketing funnel');

  return;
};

export const createRepRamping = async ({
  organizationUuid,
  scenarioUuid,
  repTitle,
  attributeUuid,
  percentages,
}: {
  organizationUuid: string;
  scenarioUuid?: string | null;
  repTitle: string;
  attributeUuid?: string;
  percentages: number[];
}): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: `formulas/template/rep-ramping`,
    body: {
      repTitle,
      attributeUuid,
      percentages,
    },
    params: {
      scenarioUuid,
    },
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  });

  if (response.status !== StatusCodes.CREATED) throw new Error('Failed to create rep ramping');

  return;
};

export const createMonthlyPercentageAggregators = async ({
  organizationUuid,
  scenarioUuid,
  titlePrefix,
  attributeUuid,
  percentages,
  type,
}: {
  organizationUuid: string;
  scenarioUuid?: string | null;
  titlePrefix?: string;
  attributeUuid?: string;
  percentages: number[];
  type: IPrebuiltComponentType;
}): Promise<void> => {
  const response = await request({
    method: 'POST',
    url: `formulas/template/${type}`,
    body: {
      titlePrefix,
      attributeUuid,
      percentages,
    },
    params: {
      scenarioUuid,
    },
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  });

  if (response.status !== StatusCodes.CREATED) throw new Error('Failed to create monthly percentage aggregators');

  return;
};
