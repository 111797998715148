import React, { useMemo, useState } from 'react';
import Typography from '~/components/Typography';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { addScenarioUuid, removeScenarioUuid } from '~/store/scenarioSlice';

const ComparisonItem = ({ scenario }: { scenario: IScenario }): React.ReactNode => {
  const dispatch = useDispatch();
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector((state: State) => state.scenario);

  const [isHovered, setIsHovered] = useState(false);
  const isActive = activeScenarioUuid === scenario.uuid;

  const shouldBeDisabled = useMemo((): boolean => {
    if (activeScenarioUuid === scenario.uuid) {
      return true;
    } else if (selectedScenarioUuids.length < 3) {
      return false;
    } else if (selectedScenarioUuids.includes(scenario.uuid)) {
      return false;
    } else {
      return true;
    }
  }, [activeScenarioUuid, selectedScenarioUuids, scenario.uuid]);

  const handleSelectScenario = async (): Promise<void> => {
    const isAlreadySelected = selectedScenarioUuids.includes(scenario.uuid);

    if (isAlreadySelected) {
      dispatch(removeScenarioUuid(scenario.uuid));
    } else {
      dispatch(addScenarioUuid(scenario.uuid));
    }
  };

  const getScenarioTag = (scenario: IScenario): React.ReactNode => {
    if (scenario.type === 'static') {
      return (
        <div
          className={`bg-green-15 rounded-md py-1 px-2 flex items-center justify-center ${isActive ? 'opacity-50' : ''}`}
        >
          <Typography size="2xs" color="primaryGreen">
            Locked Plan
          </Typography>
        </div>
      );
    } else {
      return (
        <div
          className={`bg-blue-15 rounded-md py-1 !h-fit px-2 flex items-center justify-center ${isActive ? 'opacity-50' : ''}`}
        >
          <Typography size="2xs" color="mediumBlue">
            Scenario
          </Typography>
        </div>
      );
    }
  };

  return (
    <div
      className={`w-full flex px-6 justify-between items-center py-1.5 group rounded-md cursor-pointer ${
        shouldBeDisabled ? '' : isHovered ? 'bg-neutral-25' : 'hover:bg-neutral-25'
      } ${isActive && 'bg-neutral-25'}`}
      key={scenario.uuid}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!shouldBeDisabled) {
          handleSelectScenario();
        }
      }}
    >
      <div className="flex flex-row gap-2">
        {getScenarioTag(scenario)}
        <div
          className={`flex justify-start align-middle items-center gap-[8px] ${shouldBeDisabled ? 'cursor-default' : ' cursor-pointer '} flex-grow`}
        >
          <Typography
            color={shouldBeDisabled ? 'empty' : 'primary'}
            size="sm"
            className={`sm:group-hover:truncate max-w-[265px] h-[26px] flex items-center sm:truncate ${activeScenarioUuid === scenario.uuid && 'truncate !max-w-[200px]'}`}
          >
            {scenario.changeDescription}
          </Typography>
        </div>
      </div>
      {activeScenarioUuid === scenario.uuid ? (
        <div className="flex justify-center items-center text-nowrap">
          <Typography color="disabled">{`(In Progress)`}</Typography>
        </div>
      ) : (
        <label
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="inline-flex items-center cursor-pointer"
        >
          <input
            data-testid={`scenario-input-${scenario.uuid}`}
            value={`scenario-input-${scenario.uuid}`}
            id={`scenario-input-${scenario.uuid}`}
            type="checkbox"
            name="role"
            checked={selectedScenarioUuids.includes(scenario.uuid) || activeScenarioUuid === scenario.uuid}
            className="sr-only peer"
            disabled={shouldBeDisabled}
            onChange={() => {
              if (!shouldBeDisabled) {
                handleSelectScenario();
              }
            }}
          />
          <div
            className={`relative w-9 h-5 bg-neutral-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-400 ${
              shouldBeDisabled ? 'opacity-75 after:bg-green-25 after:border-green-25' : ''
            }`}
          ></div>
        </label>
      )}
    </div>
  );
};

export default ComparisonItem;
