import Input from '~/components/Input/Input';
import React, { ReactElement, useRef, forwardRef } from 'react';
import useInput from '~/components/Input/useInput';

interface Props {
  id: string;
  state: Types.InputState;
  setState: React.Dispatch<React.SetStateAction<Types.InputState>>;
  type?: 'text' | 'password' | 'currency' | 'percentage' | 'textarea' | 'search' | 'chat';
  placeholder?: string;
  className?: string;
  label?: string;
  subLabel?: string;
  sideLabel?: string;
  disabled?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  prepend?: React.ReactNode;
  onBlur?: () => void;
  required?: boolean;
  optional?: boolean;
  textRef?: React.RefObject<HTMLInputElement>;
  borderOnFocus?: boolean;
  chatBoxRef?: React.RefObject<HTMLTextAreaElement>;
  decimalsLimit?: number;
  includeDollarSign?: boolean;
  append?: React.ReactNode;
  onFocus?: () => void;
}

const InputWrapper = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      id,
      type = 'text',
      state,
      setState,
      className = '',
      label: labelValue = '',
      subLabel: subLabelValue = '',
      sideLabel: sideLabelValue,
      placeholder = '',
      onKeyDown = (): void => {},
      prepend,
      onBlur,
      required = false,
      optional = false,
      textRef,
      borderOnFocus,
      chatBoxRef,
      decimalsLimit,
      includeDollarSign,
      disabled,
      append,
      onFocus,
    },
    ref,
  ): ReactElement => {
    const placeholderChatBoxRef = useRef<HTMLTextAreaElement>(null);
    chatBoxRef = chatBoxRef ?? placeholderChatBoxRef;
    if (type === 'chat' && chatBoxRef.current) {
      if (state.value) {
        chatBoxRef.current.style.height = 'auto';
        // Chat height is 2 pixels larger than our text so we need to add it back here
        chatBoxRef.current.style.height = chatBoxRef.current.scrollHeight + 2 + 'px';
      } else {
        chatBoxRef.current.style.height = 'auto';
      }
    }
    const onChange = (value: string): void => {
      setState((prevState) => ({
        ...prevState,
        value: value || '',
        pristine: false,
        touched: value === '' ? true : prevState.touched,
        valid: state.validation.test(value),
      }));
    };
    const onInputBlur = (): void => {
      setState({
        ...state,
        touched: true,
      });
      if (onBlur) onBlur();
    };
    const onInputFocus = (): void => {
      if (onFocus) onFocus();
    };

    const showError = state.touched && !state.pristine && !state.valid;

    return (
      <Input
        id={id}
        className={className}
        state={state}
        type={type}
        placeholder={placeholder}
        label={labelValue}
        subLabel={subLabelValue}
        sideLabel={sideLabelValue}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        showError={showError}
        prepend={prepend}
        required={required}
        optional={optional}
        chatBoxRef={chatBoxRef}
        textRef={textRef ?? ref}
        borderOnFocus={borderOnFocus}
        decimalsLimit={decimalsLimit}
        ref={ref}
        includeDollarSign={includeDollarSign}
        disabled={disabled}
        append={append}
      />
    );
  },
);

InputWrapper.displayName = 'InputWrapper';
export { useInput };
export default InputWrapper;
