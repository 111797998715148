import React, { useContext, useEffect, useState } from 'react';
import Typography from '~/components/Typography';
import { PlusIcon } from '@heroicons/react/24/outline';
import Item from './Item';
import { ChatContext } from '../../context/ChatContext';
import { IConversation } from '~/pages/Dashboard/entity/types';
import { subDays, subYears, isWithinInterval, isToday, isYesterday } from 'date-fns';
import EmptyState from './EmptyState';

const History = (): React.ReactElement => {
  const { allConversations, setSelectedConversationUuid, isLoading } = useContext(ChatContext);
  const [todaysConversations, setTodaysConversations] = useState<IConversation[]>([]);
  const [yesterdayConversations, setYesterdayConversations] = useState<IConversation[]>([]);
  const [lastSevenDaysConversations, setLastSevenDaysConversations] = useState<IConversation[]>([]);
  const [previousThirtyDaysConversations, setPreviousThirtyDaysConversations] = useState<IConversation[]>([]);
  const [previousYearConversations, setPreviousYearConversations] = useState<IConversation[]>([]);

  useEffect(() => {
    setTodaysConversations(
      allConversations
        .filter((conversation) => isToday(new Date(conversation.createdAt)))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    );
    setYesterdayConversations(
      allConversations
        .filter((conversation) => isYesterday(new Date(conversation.createdAt)))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    );
    setLastSevenDaysConversations(
      allConversations
        .filter((conversation) =>
          isWithinInterval(new Date(conversation.createdAt), {
            start: subDays(new Date(), 7),
            end: subDays(new Date(), 2),
          }),
        )
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    );
    setPreviousThirtyDaysConversations(
      allConversations
        .filter((conversation) =>
          isWithinInterval(new Date(conversation.createdAt), {
            start: subDays(new Date(), 30),
            end: subDays(new Date(), 7),
          }),
        )
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    );
    setPreviousYearConversations(
      allConversations
        .filter((conversation) =>
          isWithinInterval(new Date(conversation.createdAt), {
            start: subYears(new Date(), 1),
            end: subDays(new Date(), 30),
          }),
        )
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    );
  }, [allConversations]);

  return (
    <div className="flex flex-col w-[275px] h-screen pt-6 px-6 border-l border-r border-1 border-green-50">
      <div className="flex items-center justify-between">
        <Typography size="sm" weight="semibold">
          Chat History
        </Typography>
        <div
          className="bg-green-15 py-1 px-2 rounded flex items-center gap-2 cursor-pointer group"
          onClick={() => setSelectedConversationUuid(null)}
        >
          <PlusIcon className="size-4 text-green-400 group-hover:text-green-500" />
          <Typography color="primaryGreen" className="group-hover:text-green-500">
            New
          </Typography>
        </div>
      </div>
      <div className="mt-6 w-full flex-1 overflow-y-auto no-scrollbar">
        {allConversations.length === 0 && !isLoading && <EmptyState />}
        {todaysConversations.length > 0 && (
          <div className="flex flex-col w-full">
            <Typography color="empty" size="sm" className="pl-2 mb-2">
              Today
            </Typography>
            {todaysConversations.map((conversation) => (
              <Item title={conversation.title} conversationUuid={conversation.uuid} key={conversation.uuid} />
            ))}
          </div>
        )}
        {yesterdayConversations.length > 0 && (
          <div className="flex flex-col w-full mt-4">
            <Typography color="empty" size="sm" className="pl-2 mb-2">
              Yesterday
            </Typography>
            {yesterdayConversations.map((conversation) => (
              <Item title={conversation.title} conversationUuid={conversation.uuid} key={conversation.uuid} />
            ))}
          </div>
        )}
        {lastSevenDaysConversations.length > 0 && (
          <div className="flex flex-col w-full mt-4">
            <Typography color="empty" size="sm" className="pl-2 mb-2">
              Previous 7 Days
            </Typography>
            {lastSevenDaysConversations.map((conversation) => (
              <Item title={conversation.title} conversationUuid={conversation.uuid} key={conversation.uuid} />
            ))}
          </div>
        )}
        {previousThirtyDaysConversations.length > 0 && (
          <div className="flex flex-col w-full mt-4">
            <Typography color="empty" size="sm" className="pl-2 mb-2">
              Previous 30 Days
            </Typography>
            {previousThirtyDaysConversations.map((conversation) => (
              <Item title={conversation.title} conversationUuid={conversation.uuid} key={conversation.uuid} />
            ))}
          </div>
        )}
        {previousYearConversations.length > 0 && (
          <div className="flex flex-col w-full mt-4">
            <Typography color="empty" size="sm" className="pl-2 mb-2">
              Previous Year
            </Typography>
            {previousYearConversations.map((conversation) => (
              <Item title={conversation.title} conversationUuid={conversation.uuid} key={conversation.uuid} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
