import React from 'react';
import Button from '~/components/Button';
import useInput from '~/components/Input/useInput';
import Input from '~/components/Input/InputWrapper';
import Typography from '~/components/Typography';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import useFormulaContext from '../../../../components/Formulas/context/useFormulaContext';
import DatePicker, { useDatePicker } from '~/components/DatePicker';
import * as api from '~/services/parallel/index';
import toast from 'react-hot-toast';

const LoanScheduleForm = ({ handleClose }: { handleClose: () => void }): React.ReactNode => {
  const { refreshData } = useFormulaContext();
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [titlePrefix, setTitlePrefix] = useInput({});
  const [loanAmount, setLoanAmount] = useInput({});
  const [annualInterestRate, setAnnualInterestRate] = useInput({});
  const [loanTerm, setLoanTerm] = useInput({});
  const [startDate, setStartDate] = useDatePicker({
    value: {
      startDate: null,
      endDate: null,
    },
  });

  const handleSave = async ({
    titlePrefix,
    loanAmount,
    annualInterestRate,
    loanTerm,
    startDate,
  }: {
    titlePrefix: string;
    loanAmount: string;
    annualInterestRate: string;
    loanTerm: string;
    startDate: string | null;
  }): Promise<void> => {
    if (titlePrefix && loanAmount && annualInterestRate && loanTerm && startDate) {
      try {
        await api.formulas.createLoanSchedule({
          organizationUuid,
          scenarioUuid: activeScenarioUuid,
          titlePrefix,
          loanAmount: Number(loanAmount),
          annualInterestRate: Number(annualInterestRate) / 100,
          loanTerm: Number(loanTerm),
          startDate: startDate,
        });

        handleClose();
        refreshData();
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
        toast.error('Failed to create loan schedule');
      }
    } else {
      setTitlePrefix((prev) => ({ ...prev, pristine: false, touched: true }));
      setAnnualInterestRate((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setLoanAmount((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setLoanTerm((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setStartDate((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
    }
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <Typography color="secondary" className="mb-2" size="xs">
        Visualize your loan repayment journey from start to finish, forecasting how each payment reduces your principal
        and interest while building equity over time.
      </Typography>
      <div className="flex flex-col gap-2">
        <Input
          label="Title Prefix"
          state={titlePrefix}
          setState={setTitlePrefix}
          id="title-prefix-input"
          placeholder=""
        />
        <CurrencyInput label="Loan Amount" state={loanAmount} setState={setLoanAmount} id="loan-amount-input" />
        <Input
          label="Annual Interest Rate"
          state={annualInterestRate}
          setState={setAnnualInterestRate}
          id="annual-interest-rate-input"
          type="percentage"
          placeholder="%"
        />
        <Input
          label="Loan Term"
          sideLabel="(Months)"
          state={loanTerm}
          setState={setLoanTerm}
          id="loan-term-input"
          placeholder="Months"
        />
        <DatePicker id="loan-start-date" label="Start Date" state={startDate} setState={setStartDate} />
      </div>
      <div className="flex items-center justify-between w-full mt-1">
        <div className="w-fit">
          <Button fill="clear" className="!w-fit !px-0" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div className="w-fit">
          <Button
            onClick={() =>
              handleSave({
                titlePrefix: titlePrefix.value,
                loanAmount: loanAmount.value,
                annualInterestRate: annualInterestRate.value,
                loanTerm: loanTerm.value,
                startDate: startDate.value.startDate,
              })
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoanScheduleForm;
