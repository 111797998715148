const abrToNumMap = {
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dec: '12',
};

export const monthAbrToNumber = (month: string): string => {
  if (!(month.trim().toLowerCase() in abrToNumMap)) {
    throw new Error('Invalid month abbreviation');
  }
  return abrToNumMap[month.trim().toLowerCase() as keyof typeof abrToNumMap];
};
