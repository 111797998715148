import React, { useContext, useEffect, useState } from 'react';
import Typography from '~/components/Typography';
import Checkbox from '~/components/Checkbox';
import Button from '~/components/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import request from '~/utils/request';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { HttpStatusCode } from 'axios';
import toast from 'react-hot-toast';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import * as stringDate from '~/utils/stringDate';

export const PullQuickbooksActuals = ({ onClose }: { onClose: () => void }): React.ReactElement => {
  const { revalidate } = useContext(FinancialModelContext);
  const { organization, scenario } = useSelector((state: State) => state);
  const [startPeriod, setStartPeriod] = usePeriodPicker({
    startDate: stringDate.subtractMonths(stringDate.getStringDate(), 1),
    endDate: null,
    mode: 'month',
  });
  const [endPeriod, setEndPeriod] = usePeriodPicker({
    startDate: stringDate.subtractMonths(stringDate.getStringDate(), 1),
    endDate: null,
    mode: 'month',
  });
  const [multipleMonths, setMultipleMonths] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!multipleMonths) {
      setEndPeriod({
        startDate: startPeriod.startDate ?? stringDate.getStringDate(),
        endDate: startPeriod.startDate ?? stringDate.getStringDate(),
        mode: 'month',
      });
    }
  }, [multipleMonths, startPeriod.startDate]);

  const handleCancel = (): void => {
    onClose();
  };

  const handlePullActuals = async (): Promise<void> => {
    if (
      startPeriod.startDate &&
      endPeriod.startDate &&
      multipleMonths &&
      stringDate.isAfter({ dateToCheck: startPeriod.startDate, comparison: endPeriod.startDate })
    ) {
      toast.error('End month must be after start month');
      return;
    }

    setIsLoading(true);

    const response = await request({
      url: `/formulas/update-actuals`,
      method: 'POST',
      body: {
        startDate: startPeriod.startDate,
        endDate: endPeriod.startDate,
        scenarioUuid: scenario.activeScenarioUuid ?? undefined,
      },
      headers: {
        'Content-Type': 'application/json',
        'Organization-Uuid': organization.uuid,
      },
    });

    if (response.status === HttpStatusCode.Ok) {
      onClose();
    } else {
      toast.error('Unable to update actuals');
    }
    revalidate();
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-start">
        <Typography size="lg" weight="bold" color="primary">
          Pull Actuals
        </Typography>
        <Button className="!w-auto !p-0" fill="clear" onClick={onClose}>
          <XMarkIcon className="size-6" />
        </Button>
      </div>
      <Typography color="secondary" size="xs">
        Overwrite past months data with accounting actuals
      </Typography>
      <div className="flex justify-start gap-2 items-center mt-3">
        <PeriodPicker
          id="pull-quickbooks-actuals-start-date"
          state={startPeriod}
          setState={setStartPeriod}
          beBefore={stringDate.subtractMonths(stringDate.getStringDate(), 1)}
          beAfter={organization.configuration.companyStartDate}
        />
        {multipleMonths && (
          <>
            <Typography color="empty">-</Typography>
            <PeriodPicker
              id="pull-quickbooks-actuals-end-date"
              state={endPeriod}
              setState={setEndPeriod}
              beBefore={stringDate.subtractMonths(stringDate.getStringDate(), 1)}
              beAfter={startPeriod.startDate}
            />
          </>
        )}
      </div>
      <Checkbox
        label="Multiple Months"
        checked={multipleMonths}
        toggleValue={() => setMultipleMonths(!multipleMonths)}
        className="mt-2"
      />
      <div className="flex justify-between mt-5">
        <Button loading={isLoading} className="!w-auto !px-4 !pl-0" fill="clear" onClick={handleCancel}>
          Cancel
        </Button>
        <Button className="!w-auto !px-4" onClick={handlePullActuals} loading={isLoading}>
          Pull Actuals
        </Button>
      </div>
    </div>
  );
};
