import { Row, SortingFn } from '@tanstack/react-table';
import { IExpenseTableRow } from '~/pages/Expenses/components/types';

export const sortAmountFn: SortingFn<IExpenseTableRow> = (
  rowA: Row<IExpenseTableRow>,
  rowB: Row<IExpenseTableRow>,
): number => {
  const numericAmountA = Number(rowA.original.amount.replace(/[$,%]/g, ''));
  const amountA = rowA.original.driver === 'Percent of Salary' ? numericAmountA / 10000 : numericAmountA;

  const numericAmountB = Number(rowB.original.amount.replace(/[$,%]/g, ''));
  const amountB = rowB.original.driver === 'Percent of Salary' ? numericAmountB / 10000 : numericAmountB;

  if (amountA < amountB) return 1;
  if (amountA > amountB) return -1;
  return 0;
};
