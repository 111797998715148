import { IExpense } from '../types';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { isExpenseAppliedInMonth } from '~/pages/Expenses/utils/isExpenseAppliedInMonth';
import { IStringDate } from '~/utils/stringDate/types';

export const grayOutByDate = ({
  expenses,
  lockedDate,
  positions,
}: {
  expenses: IExpense[];
  lockedDate: IStringDate;
  positions: IPositionDetails[];
}): IExpense[] => {
  return expenses.map((expense) => {
    return {
      ...expense,
      isGrayedOut: !isExpenseAppliedInMonth({
        expense,
        month: lockedDate,
        positions,
      }),
    };
  });
};
