import { addDays } from './addDays';
import { getLastDayOfMonth } from './getLastDayOfMonth';
import { IStringDate } from './types';

export const subtractDays = (date: IStringDate, days: number): IStringDate => {
  let [year, month, day] = date.split('-').map(Number);
  let remainingDays = days;

  if (remainingDays < 0) {
    return addDays(date, Math.abs(remainingDays));
  }

  while (remainingDays > 0) {
    if (remainingDays >= day) {
      remainingDays -= day;
      if (month === 1) {
        month = 12;
        year -= 1;
      } else {
        month -= 1;
      }
      day = Number(getLastDayOfMonth(`${year}-${String(month).padStart(2, '0')}-01`));
    } else {
      day -= remainingDays;
      remainingDays = 0;
    }
  }

  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
};
