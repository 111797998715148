import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { update } from '~/store/scenarioSlice';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import Typography from '../Typography';
import ScenarioModeToast from '../ScenarioMode/components/toasts/ScenarioToast';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const ScenarioIdleTimer = (): React.ReactNode => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { activeScenarioUuid, selectedScenarioUuids, scenarioMode, scenarios } = useSelector(
    (state: State) => state.scenario,
  );

  const idleTimeout = 30 * 60 * 1000; // 30 minutes

  const handleOnIdle = (): void => {
    if (activeScenarioUuid) {
      setShowModal(true);
    }
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
    startManually: true,
  });

  useEffect(() => {
    if (activeScenarioUuid) {
      idleTimer.start();
    } else {
      idleTimer.reset();
      idleTimer.pause();
    }

    return () => {
      idleTimer.pause();
    };
  }, [activeScenarioUuid]);

  const handleContinueScenario = (): void => {
    setShowModal(false);
    idleTimer.reset();
  };

  const handleSaveAndExit = (): void => {
    setShowModal(false);
    toast.custom(
      (t) => (
        <ScenarioModeToast
          message="Scenario has been saved"
          button={{
            text: 'View',
            callback: () => {
              if (activeScenarioUuid) {
                dispatch(
                  update({
                    activeScenarioUuid: null,
                    activeScenarioData: null,
                    activeScenarioHasChanges: false,
                    scenarioLoadingState: 'idle',
                    scenarioMode,
                    leverChanges: [],
                    cashBalanceLockedIndexes: [],
                    cashBalanceSelectedPoint: null,
                    selectedScenarioUuids: [activeScenarioUuid, ...selectedScenarioUuids],
                    scenarios,
                  }),
                );
              }
              navigate(`/dashboard`);
              toast.dismiss(t.id);
            },
          }}
        />
      ),
      {
        position: 'bottom-center',
        duration: 4000,
      },
    );
    dispatch(
      update({
        activeScenarioUuid: null,
        activeScenarioData: null,
        activeScenarioHasChanges: false,
        leverChanges: [],
        cashBalanceLockedIndexes: [],
        cashBalanceSelectedPoint: null,
        selectedScenarioUuids,
        scenarioLoadingState: 'exiting',
        scenarioMode,
        scenarios,
      }),
    );
    idleTimer.reset();
  };

  return (
    <>
      {showModal && (
        <Modal size="md" title="Continue Scenario Mode?" isOpen={showModal} onClose={handleContinueScenario}>
          <div>
            <Typography color="secondary">
              It looks like you left while still in scenario mode. Would you like to save your changes and exit, or
              continue editing this scenario?
            </Typography>
            <div className="mt-4 flex justify-end gap-4">
              <Button onClick={handleSaveAndExit} fill="outline" className="!w-fit !px-5">
                Save & Exit
              </Button>
              <Button onClick={handleContinueScenario} className="!w-fit !px-5">
                Continue Scenario
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ScenarioIdleTimer;
