import { IStringDate } from './types';

export const sortAsc = (a: IStringDate, b: IStringDate): number => {
  const aYear = Number(a.split('-')[0]);
  const bYear = Number(b.split('-')[0]);
  const aMonth = Number(a.split('-')[1]);
  const bMonth = Number(b.split('-')[1]);
  const aDay = Number(a.split('-')[2]);
  const bDay = Number(b.split('-')[2]);
  return aYear - bYear || aMonth - bMonth || aDay - bDay;
};
