import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Stage, Line } from 'react-konva';
import Typography from '../Typography';
import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';
import { generateGraphPoints } from './utils/generateGraphPoints';
import { findScaleMultiplier } from './utils/findScaleMultiplier';
import { IDraggableGraphPoint } from './utils/types';
import DraggableGraphTooltip from './components/DraggableGraphTooltip';
import { convertGraphValueToRawOverrideValue } from './utils/convertGraphValueToRawOverrideValue';
import isEqual from 'lodash.isequal';
import { formatYValue } from './utils/formatYValue';
import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { updateScenario } from './utils/updateScenario';
import toast from 'react-hot-toast';
import { updateScenarioLoadingState, updateScenarioMode } from '~/store/scenarioSlice';
import { DashboardPageContext } from '~/pages/Dashboard/context/DashboardContext';
import { generateOverridesAndActuals } from './utils/generateOverridesAndActuals';
import { convertRawValueToRounded } from './utils/convertRawValueToRounded';
import { getMonthHasScenarioOverrideOrActual } from './utils/getMonthHasScenarioOverrideOrActual';
import { getMinMaxDate } from './utils/getMinMaxDate';
import { getMinMaxValue } from './utils/getMinMaxValue';
import { getBoundAdjustedValues } from './utils/getBoundAdjustedValues';
import DraggableGraphCircle from './components/DraggableGraphCircle';
import GraphFooter from './components/GraphFooter';
import SideLabels from './components/SideLabels';
import logger from '~/utils/logger';
import DraggableGraphResetButton from './components/DraggableGraphResetButton';
import DraggableGraphInput from './components/DraggableGraphInput';
import * as stringDate from '~/utils/stringDate';

const DraggableGraph = ({
  consolidatedGraphData,
  formulaUuid,
  externalActiveIndex,
  setExternalActiveIndex,
  card,
}: {
  consolidatedGraphData: IConsolidatedGraphBody;
  formulaUuid: string;
  externalActiveIndex?: number;
  setExternalActiveIndex?: (value: number) => void;
  card: {
    title: string | React.ReactElement;
    month?: string;
    workingModel?: string;
    activeScenario?: string;
  };
}): React.ReactNode => {
  const dispatch = useDispatch();
  const scenarioState = useSelector((state: State) => state.scenario);
  const activeScenarioUuid = scenarioState.activeScenarioUuid;
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { reload } = useContext(DashboardPageContext);
  const graphContainerRef = useRef<HTMLDivElement>(null);
  const [graphDimensions, setGraphDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });
  const [xMin, setXMin] = useState<number>(0);
  const [xMax, setXMax] = useState<number>(0);
  const [yMin, setYMin] = useState<number>(0);
  const [yMax, setYMax] = useState<number>(0);
  const [multipliers, setMultipliers] = useState<{
    xMultiplier: number;
    yMultiplier: number;
  }>(
    findScaleMultiplier({
      numOfIndexes: consolidatedGraphData.data.length,
      minY: yMin,
      maxY: yMax,
      graphWidth: graphDimensions.width,
      graphHeight: graphDimensions.height,
    }),
  );
  const [graphPoints, setGraphPoints] = useState<{
    workingModel: IDraggableGraphPoint[];
    activeScenario: IDraggableGraphPoint[];
  }>(
    generateGraphPoints({
      consolidatedData: consolidatedGraphData.data,
      xMultiplier: multipliers.xMultiplier,
      yMultiplier: multipliers.yMultiplier,
      maxY: yMax,
    }),
  );
  const [workingModelPoints, setWorkingModelPoints] = useState(
    graphPoints.workingModel.reduce((acc: number[], point) => {
      return [...acc, point.x, point.y];
    }, []),
  );
  const [activeScenarioPoints, setActiveScenarioPoints] = useState(
    graphPoints.activeScenario.reduce((acc: number[], point) => {
      return [...acc, point.x, point.y];
    }, []),
  );
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [tooltipCoordinates, setTooltipCoordinates] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const [tooltipValues, setTooltipValues] = useState<(number | null)[]>([]);
  const [draggingValue, setDraggingValue] = useState<number | null>(null);
  const [todayIndex, setTodayIndex] = useState<number>(-1);
  const [dragEscaped, setDragEscaped] = useState<boolean>(false);
  const [hoveredCircleIndex, setHoveredCircleIndex] = useState<number>(-1);
  const [atBoundaryTimer, setAtBoundaryTimer] = useState<number>(0);
  const [graphHasDragged, setGraphHasDragged] = useState<boolean>(false);
  const [inputData, setInputData] = useState<{
    x: number | null;
    y: number | null;
    index: number | null;
  }>({ x: null, y: null, index: null });
  const escapeKeyHandlerRef = useRef<((e: KeyboardEvent) => void) | null>(null);
  const formatting = useMemo(
    () => consolidatedGraphData.formatting ?? IFormattingEnum.Number,
    [consolidatedGraphData.formatting],
  );
  const monthHasScenarioOverrideOrActual = useMemo(() => {
    return getMonthHasScenarioOverrideOrActual({
      xMin,
      xMax,
      consolidatedGraphData,
    });
  }, [xMin, xMax, consolidatedGraphData.dataActuals, consolidatedGraphData.dataOverrides]);

  useEffect(() => {
    const todayIndex = consolidatedGraphData.data.findIndex((item) =>
      stringDate.isSameMonth({
        date1: stringDate.getStringDate(new Date(item.date)),
        date2: stringDate.getStringDate(),
      }),
    );
    setTodayIndex(todayIndex);
  }, [consolidatedGraphData]);

  useEffect(() => {
    const { minDate, maxDate } = getMinMaxDate({ consolidatedGraphData });
    const { minValue, maxValue } = getMinMaxValue({
      consolidatedGraphData,
      formatting,
    });
    setYMin(minValue);
    setYMax(maxValue);
    setXMin(minDate);
    setXMax(maxDate);
  }, [consolidatedGraphData]);

  useEffect(() => {
    setMultipliers(
      findScaleMultiplier({
        numOfIndexes: consolidatedGraphData.data.length,
        minY: yMin,
        maxY: yMax,
        graphWidth: graphDimensions.width,
        graphHeight: graphDimensions.height,
      }),
    );
  }, [graphDimensions, xMin, xMax, yMin, yMax]);

  useEffect(() => {
    setGraphPoints(
      generateGraphPoints({
        consolidatedData: consolidatedGraphData.data,
        xMultiplier: multipliers.xMultiplier,
        yMultiplier: multipliers.yMultiplier,
        maxY: yMax,
      }),
    );
  }, [multipliers, consolidatedGraphData.data, yMax]);

  useEffect(() => {
    if (graphPoints.workingModel.length > 0) {
      setWorkingModelPoints(
        graphPoints.workingModel.reduce((acc: number[], point) => {
          return [...acc, point.x, point.y];
        }, []),
      );
    } else {
      setWorkingModelPoints([]);
    }
    setActiveScenarioPoints(
      graphPoints.activeScenario.reduce((acc: number[], point) => {
        return [...acc, point.x, point.y];
      }, []),
    );
  }, [graphPoints]);

  useEffect(() => {
    const handleResize = (): void => {
      if (graphContainerRef.current) {
        setGraphDimensions({
          width: graphContainerRef.current.clientWidth,
          height: graphContainerRef.current.clientHeight,
        });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (hoverIndex !== -1) {
      if (draggingValue !== null) {
        setTooltipValues([consolidatedGraphData.data[hoverIndex]?.workingModel, draggingValue]);
      } else if (isEqual(workingModelPoints, activeScenarioPoints)) {
        setTooltipValues([consolidatedGraphData.data[hoverIndex]?.workingModel]);
      } else {
        setTooltipValues([
          consolidatedGraphData.data[hoverIndex]?.workingModel,
          consolidatedGraphData.data[hoverIndex]?.activeScenario,
        ]);
      }
    } else {
      setTooltipValues([]);
    }
  }, [hoverIndex, consolidatedGraphData, draggingValue]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (draggingValue !== null && (draggingValue + 1 >= yMax || draggingValue - 1 <= yMin)) {
      timer = setTimeout(() => {
        setAtBoundaryTimer((prev) => prev + 100);
      }, 100);
    } else {
      setAtBoundaryTimer(0);
    }
    return () => clearTimeout(timer);
  }, [draggingValue, yMax, yMin]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (draggingValue !== null) {
      intervalId = setInterval(() => {
        adjustYBounds(draggingValue, yMax, yMin, atBoundaryTimer);
      }, 100);
    }
    return () => clearInterval(intervalId);
  }, [draggingValue, atBoundaryTimer]);

  const adjustYBounds = (draggingValue: number | null, max: number, min: number, millisecondsRan: number): void => {
    if (draggingValue === null) return;
    const boundAdjustedValues = getBoundAdjustedValues({
      min,
      max,
      draggingValue,
      formatting,
      millisecondsRan,
    });
    if (boundAdjustedValues.max) {
      setYMax(boundAdjustedValues.max);
      setDraggingValue(boundAdjustedValues.max);
      setGraphHasDragged(true);
    } else if (boundAdjustedValues.min) {
      setYMin(boundAdjustedValues.min);
      setDraggingValue(boundAdjustedValues.min);
      setGraphHasDragged(true);
    }
  };

  const handlePressEscape = ({
    e,
    initialDraggingValue,
    originalYMax,
    originalYMin,
    id,
  }: {
    e: KeyboardEvent;
    initialDraggingValue: number | null;
    originalYMax: number;
    originalYMin: number;
    id: string;
  }): void => {
    if (e.key === 'Escape' && initialDraggingValue !== null) {
      setDragEscaped(true);
      setDraggingValue(null);
      setYMax(originalYMax);
      setYMin(originalYMin);
      setGraphPoints((prev) => {
        return {
          ...prev,
          activeScenario: prev.activeScenario.map((point) => {
            if (point.id.toString() === id) {
              return {
                ...point,
                y: initialDraggingValue,
                isDragging: false,
              };
            }
            return point;
          }),
        };
      });
    }
  };

  const handleDragStart = (e: KonvaEventObject<DragEvent>): void => {
    const id = e.target.id();
    const initialDraggingValue = e.target.y();
    setHoverIndex(parseInt(id));
    const escapeKeyHandler = (e: KeyboardEvent): void => {
      handlePressEscape({
        e,
        initialDraggingValue,
        id,
        originalYMax: yMax,
        originalYMin: yMin,
      });
    };
    escapeKeyHandlerRef.current = escapeKeyHandler;
    document.addEventListener('keydown', escapeKeyHandler, { once: true });
  };

  const handleDragMove = (e: KonvaEventObject<DragEvent>): void => {
    const id = e.target.id();
    const node = e.target;
    const newY = node.y();
    const newDraggingValue = convertGraphValueToRawOverrideValue({
      graphValue: newY,
      yMultiplier: multipliers.yMultiplier,
      maxY: yMax,
      minY: yMin,
      activeScenarioValue: consolidatedGraphData.data[parseInt(id)].activeScenario,
      workingModelValue: consolidatedGraphData.data[parseInt(id)].workingModel,
    });
    setDraggingValue(newDraggingValue);
    setGraphPoints((prev) => {
      return {
        ...prev,
        activeScenario: prev.activeScenario.map((point, index) => {
          if (index === parseInt(id)) {
            return {
              ...point,
              isDragging: true,
            };
          }
          return point;
        }),
      };
    });
    setTooltipCoordinates({ x: e.evt.clientX, y: e.evt.clientY });
  };

  const handleDragEnd = async (e: KonvaEventObject<DragEvent>): Promise<void> => {
    try {
      if (draggingValue === null) return;
      if (Boolean(activeScenarioUuid) || tooltipValues[0] !== tooltipValues[1]) {
        if (!activeScenarioUuid) {
          dispatch(updateScenarioLoadingState('creating'));
          dispatch(updateScenarioMode('creating'));
        } else {
          dispatch(updateScenarioLoadingState('updating'));
        }
        const id = e.target.id();
        const scaledNumber = convertRawValueToRounded({
          value: draggingValue,
          formatting,
          yMax,
          yMin,
        });
        setDraggingValue(null);
        const changeDate = stringDate.endOfMonth(
          stringDate.getStringDate(new Date(consolidatedGraphData.data[parseInt(id)].date)),
        );
        const { overrides, actuals } = generateOverridesAndActuals({
          changeDate,
          value: scaledNumber,
          consolidatedGraphData,
          activeScenarioUuid,
        });
        await updateScenario({
          scenarioUuid: activeScenarioUuid,
          organizationUuid,
          formulaUuid: formulaUuid,
          overrides,
          actuals,
        });
        await reload();
      } else {
        setDraggingValue(null);
        setGraphPoints((prev) => {
          return {
            ...prev,
            activeScenario: prev.activeScenario.map((point) => {
              return {
                ...point,
                isDragging: false,
              };
            }),
          };
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      setDraggingValue(null);
      toast.error(`Failed to ${activeScenarioUuid ? 'update' : 'create'} scenario`);
    } finally {
      if (escapeKeyHandlerRef.current) {
        document.removeEventListener('keydown', escapeKeyHandlerRef.current);
      }
      dispatch(updateScenarioLoadingState('idle'));
      escapeKeyHandlerRef.current = null;
    }
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>): void => {
    setDragEscaped(false);
    const { clientX, clientY } = e.evt;
    const { x } = e.target.getStage()?.getPointerPosition() ?? {
      x: 0,
    };
    const xMultiplier = multipliers.xMultiplier;
    const xDivisions = Math.floor(x / xMultiplier);
    setHoverIndex(xDivisions);
    setExternalActiveIndex?.(
      xDivisions === Infinity || xDivisions > consolidatedGraphData.data.length - 1 ? -1 : xDivisions,
    );
    setTooltipCoordinates({ x: clientX, y: clientY });
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = (): void => {
    if (draggingValue === null) {
      setIsTooltipVisible(false);
      setTooltipCoordinates({ x: 0, y: 0 });
      setHoverIndex(-1);
      setExternalActiveIndex?.(-1);
    }
  };

  return (
    <div className="w-full h-[212px] bg-neutral-15 rounded-lg border border-neutral-50 p-4">
      <div className="flex justify-between items-center w-full mb-2">
        <div className="flex items-center justify-start gap-2 w-[55%]">
          <Typography weight="medium" className="max-w-[85%] truncate">
            {card.title}
          </Typography>
          <DraggableGraphResetButton
            dataOverrides={consolidatedGraphData.dataOverrides ?? {}}
            dataActuals={consolidatedGraphData.dataActuals ?? {}}
            formulaUuid={formulaUuid}
            reload={reload}
          />
        </div>
        <div className="flex items-center" data-testid={`${card.title}-month-value-lever`}>
          {card.workingModel && card.month && (
            <>
              <Typography color="disabled" size="xs" className="mr-1">
                {card.month}
              </Typography>
              <Typography weight="semibold">
                {formatYValue({
                  value: Number(card.workingModel),
                  formatType: formatting,
                  yMax: yMax,
                  yMin: yMin,
                })}
              </Typography>
              {card.activeScenario && (
                <>
                  <Typography size="xs" color="disabled" weight="thin" className="ml-2">
                    |
                  </Typography>
                  <Typography color="blue" weight="semibold" className="ml-2">
                    {formatYValue({
                      value: Number(card.activeScenario),
                      formatType: formatting,
                      yMax: yMax,
                      yMin: yMin,
                    })}
                  </Typography>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="w-full h-[70%] flex items-center justify-end gap-[1px]">
        <SideLabels
          yMax={yMax}
          yMin={yMin}
          formatting={formatting}
          consolidatedGraphData={consolidatedGraphData}
          graphHasDragged={graphHasDragged}
        />
        <div className="h-full w-[85%]" ref={graphContainerRef} onMouseLeave={handleMouseLeave}>
          <Stage width={graphDimensions.width} height={graphDimensions.height} onMouseMove={handleMouseMove}>
            <Layer>
              <Line
                points={activeScenarioPoints}
                stroke="#5A8496"
                strokeWidth={1.5}
                dash={[8, 4]}
                shadowForStrokeEnabled={false}
                shadowEnabled={false}
              />
              <Line
                points={workingModelPoints}
                stroke="black"
                strokeWidth={1.5}
                shadowForStrokeEnabled={false}
                shadowEnabled={false}
              />
              {graphDimensions.width > 0 &&
                graphDimensions.height > 0 &&
                graphPoints.activeScenario.map((point, index) => {
                  return (
                    <DraggableGraphCircle
                      graphPoints={graphPoints}
                      index={index}
                      hoverIndex={hoverIndex}
                      hoveredCircleIndex={hoveredCircleIndex}
                      point={point}
                      setHoveredCircleIndex={setHoveredCircleIndex}
                      handleDragStart={handleDragStart}
                      handleDragMove={handleDragMove}
                      handleDragEnd={handleDragEnd}
                      multipliers={multipliers}
                      yMax={yMax}
                      yMin={yMin}
                      draggingValue={draggingValue}
                      monthHasScenarioOverrideOrActual={monthHasScenarioOverrideOrActual}
                      dragEscaped={dragEscaped}
                      key={point.id}
                      consolidatedGraphData={consolidatedGraphData}
                      externalActiveIndex={externalActiveIndex}
                      inputIndex={inputData.index}
                      setInputData={setInputData}
                      todayIndex={todayIndex}
                    />
                  );
                })}
            </Layer>
          </Stage>
          {isTooltipVisible &&
            hoverIndex !== -1 &&
            consolidatedGraphData.data[hoverIndex]?.date &&
            tooltipValues.length && (
              <DraggableGraphTooltip
                label={consolidatedGraphData.data[hoverIndex]?.date ?? 0}
                values={tooltipValues}
                x={tooltipCoordinates.x}
                y={tooltipCoordinates.y}
                formatType={formatting}
                yMax={yMax}
                yMin={yMin}
              />
            )}
        </div>
      </div>
      <GraphFooter xMin={xMin} xMax={xMax} />
      {typeof inputData.x === 'number' && typeof inputData.y === 'number' && typeof inputData.index === 'number' && (
        <DraggableGraphInput
          consolidatedGraphData={consolidatedGraphData}
          formatting={formatting}
          changeDate={stringDate.getStringDate(
            new Date(consolidatedGraphData.data[inputData.index]?.date ?? new Date()),
          )}
          formulaUuid={formulaUuid}
          reload={reload}
          x={inputData.x}
          y={inputData.y}
          setInputData={setInputData}
        />
      )}
    </div>
  );
};

export default DraggableGraph;
