import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IPositionDetailsWithOrderedDates } from '../../entity/types';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import DeletePositionModal from '../DeletePositionModal';
import { State } from '~/store';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import Typography from '~/components/Typography';
import formatCurrency from '~/utils/formatCurrency';
import { TimelineContext } from '~/components/Timeline/TimelineContext';
import PositionToggle from '../PositionToggle';
import * as stringDate from '~/utils/stringDate';

const HeadcountTimelineNodeContent = ({
  position,
  createScenario,
  reloadDashboard,
}: {
  position: IPositionDetailsWithOrderedDates;
  createScenario?: boolean;
  reloadDashboard?: () => void;
}): React.ReactNode => {
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const {
    setEditPositionUuid,
    positionDataDict,
    deletePosition,
    positionActiveStateDict,
    setPayRateModalState,
    setTerminationModalState,
  } = useContext(HeadcountContext);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { selection } = useContext(TimelineContext);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [isActive, setIsActive] = useState(positionActiveStateDict[position.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[position.positionUuid]);
  }, [positionActiveStateDict[position.positionUuid]]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef.current, position]);

  const handleClickDelete = (): void => {
    if (activeScenarioUuid) {
      deletePosition({
        positionUuid: position.positionUuid,
      });
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const handlePayChange = (): void => {
    setPayRateModalState({
      isOpen: true,
      positionUuid: position.positionUuid,
      positionHireDate: position.currentPayRate.effectiveAt,
      payRateChangeHistory: position.payRates.map((payRate) => ({
        value: Number(payRate.value),
        effectiveAt: payRate.effectiveAt,
      })),
    });
  };

  const handleAddTerm = (): void => {
    setTerminationModalState({
      isOpen: true,
      positionUuid: position.positionUuid,
      positionHireDate: position.hireDate,
      currentTerminationDate: position.termDate,
    });
  };

  const isSelected = selection.selected.includes(position.positionUuid);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!positionDataDict[position.positionUuid]) {
    return null;
  }

  return (
    <>
      <div ref={containerRef} className="w-full flex group text-nowrap pl-3">
        <div className="flex-grow flex relative" style={{ maxWidth: containerWidth - 36 }}>
          <div className="left-0 flex-nowrap flex sticky gap-2.5">
            {stringDate.isAfter({ dateToCheck: position.hireDate, comparison: stringDate.getStringDate() }) &&
              activeScenarioUuid && (
                <div onClick={(e) => e.stopPropagation()} className="position-toggle">
                  <PositionToggle positionUuid={position.positionUuid} successCallback={reloadDashboard} />
                </div>
              )}
            {positionDataDict[position.positionUuid].title && (
              <Typography
                weight="bold"
                color={isActive || !activeScenarioUuid ? 'primary' : 'disabled'}
                className={`${!isActive && activeScenarioUuid && 'line-through'}`}
              >
                {positionDataDict[position.positionUuid].title}
              </Typography>
            )}
            {positionDataDict[position.positionUuid].employeeName && (
              <Typography
                color={isActive || !activeScenarioUuid ? 'primary' : 'disabled'}
                className={`${!isActive && activeScenarioUuid && 'line-through'}`}
              >
                {positionDataDict[position.positionUuid].employeeName}
              </Typography>
            )}
            {position.currentPayRate.value && !hideSensitiveData && (
              <Typography
                color={isActive || !activeScenarioUuid ? 'primary' : 'disabled'}
                className={`${!isActive && activeScenarioUuid && 'line-through'}`}
              >
                {formatCurrency(position.currentPayRate.value, false)}
              </Typography>
            )}
          </div>
        </div>
        <div className="sticky right-0 flex-none font-medium">
          <EllipsisDropdown
            className={`pr-1 ${isSelected ? '!bg-green-15' : 'bg-white'}`}
            options={[
              {
                label: 'Edit',
                onClick: () => setEditPositionUuid(position.positionUuid),
              },
              {
                label: 'Pay Change',
                onClick: handlePayChange,
              },
              {
                label: 'Term',
                onClick: handleAddTerm,
              },
              {
                label: 'Delete',
                onClick: () => handleClickDelete(),
                className: 'text-red-500',
              },
            ]}
            id={`${position.positionUuid}-ellipsis-dropdown`}
          />
        </div>
      </div>
      <DeletePositionModal
        id={`delete-position-modal-${position.positionUuid}`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          deletePosition({
            positionUuid: position.positionUuid,
            createScenario,
          })
        }
        deleteIsLoading={false}
        positionTitle={positionDataDict[position.positionUuid].title}
      />
    </>
  );
};

export default HeadcountTimelineNodeContent;
