import React from 'react';
import ParallelLogo from '~/assets/parallelLogoIcon.svg';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Markdown } from 'tiptap-markdown';
import Link from '@tiptap/extension-link';

const AssistantMessage = ({ message }: { message: string }): React.ReactElement => {
  const editor = useEditor({
    content: message,
    editable: false,
    enableInputRules: false,
    extensions: [StarterKit, Markdown, Link],
    editorProps: {
      attributes: {
        class: 'is-focused w-full',
      },
    },
  });

  return (
    <div className="w-full flex justify-start items-start gap-4">
      <div className="size-8 rounded-full bg-green-25 flex items-center justify-center">
        <img src={ParallelLogo} alt="parallel-logo" className="size-5" />
      </div>
      <div className="rounded flex-1 w-full is-focused">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default AssistantMessage;
