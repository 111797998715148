import React from 'react';
import HoverPopover from '~/components/HoverPopover';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const ErrorPopover = ({
  errorMessage,
  absolutePosition,
  zIndex = 'z-30',
}: {
  errorMessage: string;
  absolutePosition?: string;
  zIndex?: string;
}): React.ReactElement => {
  return (
    <div className={`absolute ${absolutePosition}`}>
      <HoverPopover
        buttonContent={<ExclamationCircleIcon className="size-6 text-red-500 bg-white rounded-full" />}
        panelContent={
          <div
            className={`bg-red text-white text-sm rounded whitespace-nowrap shadow-md px-3 py-2 flex items-center ${zIndex}`}
          >
            {errorMessage}
          </div>
        }
      />
    </div>
  );
};

export default ErrorPopover;
