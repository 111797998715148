import React from 'react';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import { TableProvider } from './contexts/TableContext';
import { IColumn } from './types';
import { DragEndEvent } from '@dnd-kit/core';
import { IFormulaData } from '~/components/Formulas/context/types';
import { IStringDate } from '~/utils/stringDate/types';
interface IProps {
  columns: IColumn[];
  data: IFormulaData[];
  groupComponent?: React.ComponentType<{
    uuid: string;
    name: string;
    isCollapsed: boolean;
    count: number;
    index: number;
  }>;
  onDragEnd: (result: DragEndEvent) => void;
  startDate?: IStringDate;
  endDate?: IStringDate;
  footerComponent?: React.ComponentType;
}

const FormulasTable = ({
  columns,
  data,
  groupComponent,
  onDragEnd,
  startDate,
  endDate,
  footerComponent: FooterComponent,
}: IProps): React.ReactElement => {
  return (
    <div className="w-full pb-5">
      <TableProvider value={{ onDragEnd, startDate, endDate }}>
        <TableHeaders columns={columns} />
        <TableBody columns={columns} tableData={data} groupComponent={groupComponent} />
        {FooterComponent && <FooterComponent />}
      </TableProvider>
    </div>
  );
};

export default FormulasTable;
