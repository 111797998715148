import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import InputWrapper, { useInput } from '~/components/Input/InputWrapper';
import { Editor } from '@tiptap/react';

interface Props {
  buttonVal?: string;
  updateCalculationModifier: (modifier: string) => void;
  editor: Editor;
  viewOnly?: boolean;
}

const CalculationModifier = ({ buttonVal, updateCalculationModifier, editor, viewOnly }: Props): React.ReactElement => {
  const [jobTitle, setJobTitle] = useInput({
    value: buttonVal ?? '',
  });
  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton
            as="div"
            className={`flex px-2 flex-row gap-x-1 items-center cursor-pointer !w-fit text-neutral-200 ${
              viewOnly ? '!cursor-default' : 'hover:text-neutral-400'
            }`}
            onClick={(e) => {
              if (viewOnly) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (!editor.isFocused) {
                editor.commands.focus();
              }
            }}
          >
            {buttonVal}
            <ChevronDownIcon className="size-3 stroke-2" />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            as="div"
            className={`${
              open ? 'block' : 'hidden'
            } absolute bg-white shadow-md rounded-md z-[15] border-none outline-none`}
          >
            <div className="py-3 px-4">
              <InputWrapper
                id="jobTitle"
                label="Job Title Includes"
                state={jobTitle}
                setState={setJobTitle}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    updateCalculationModifier(jobTitle.value);
                    editor.commands.focus();
                  }
                }}
                onBlur={() => {
                  updateCalculationModifier(jobTitle.value);
                  editor.commands.focus();
                }}
              />
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default CalculationModifier;
