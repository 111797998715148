import React, { useEffect, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import FullPageLoading from '~/components/FullPageLoading';
import { authSlice } from '~/store/authSlice';
import logger from '~/utils/logger';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import Unauthenticated from './components/Unauthenticated';
import Authenticated from './components/Authenticated';

const ShareLink = (): React.ReactNode => {
  const { shareLinkUuid } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [linkNotFound, setLinkNotFound] = useState<boolean>(false);
  const [linkExpired, setLinkExpired] = useState<boolean>(false);
  const [shareLinkAccessToken, setShareLinkAccessToken] = useState<string | null>(null);

  const authenticateShareLink = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = (await request({
        url: `/auth/share-link`,
        method: 'POST',
        body: {
          shareLinkUuid,
        },
      })) as IAPIResponse<{ accessToken: string; refreshToken: string }>;

      if (response.status === StatusCodes.OK) {
        setShareLinkAccessToken(response.data.data.accessToken);
        dispatch(authSlice.actions.login());
      } else if (response.status === StatusCodes.GONE) {
        setLinkExpired(true);
      } else if (response.status === StatusCodes.NOT_FOUND) {
        setLinkNotFound(true);
      } else {
        toast.error('An error occured while authenticating your link');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('An error occured while authenticating your link');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    authenticateShareLink();
  }, []);

  if (isLoading) return <FullPageLoading text="Authenticating..." isVisible color="green" />;
  if (linkNotFound) return <Unauthenticated type="notFound" />;
  if (linkExpired) return <Unauthenticated type="expired" />;
  return <Authenticated shareLinkAccessToken={shareLinkAccessToken} />;
};

export default ShareLink;
