import React, { createContext, useState, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DragEndEvent } from '@dnd-kit/core';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import objectHash from 'object-hash';
import * as api from '~/services/parallel';
import { State } from '~/store';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';
import { IUpdateMonthValueParams } from '~/services/parallel/formulas';
import logger from '~/utils/logger';
import { useInput } from '~/components/Input/InputWrapper';
import { convertModelToCsvData } from '~/pages/FinancialModel/utils/convertModelToCsvData';
import { IOrganizationState } from '~/store/organizationSlice';
import { ScenarioState } from '~/store/scenarioSlice';
import { UserState } from '~/store/userSlice';
import * as stringDate from '~/utils/stringDate';
import {
  IFormattingEnum,
  IFormula,
  IFormulaDependencyGraph,
  IFormulaTypeEnum,
  IRoundDirectionEnum,
  IUpdateSortOrderParams,
} from '~/services/parallel/formulas.types';
import { IIntegrationMapping, IIntegrationSources } from '~/services/parallel/integrations.types';
import { IFormulaData, IFormulaType, IGroupType, ILoadingFormulas, IMonthlyValues } from './types';
import { IStringDate } from '~/utils/stringDate/types';

export interface IFormulasContext {
  toggleGroupCollapse: (value: string | boolean) => void;
  onDragEnd: (value: DragEndEvent) => void;
  updateSortOrder: (updatedFormulasData: IGroupType[]) => Promise<void>;
  formulaDictionary: Record<string, IFormula>;
  selectedMonths: string[];
  updateFormulaMonthsValue: (params: {
    formulaUuid: string;
    months: stringDate.types.IStringDate[];
    values: (number | null)[];
    lastNeededDate: stringDate.types.IStringDate;
  }) => void;
  availableIntegration: IIntegrationSources | null;
  loadingFormulas: ILoadingFormulas[];
  updateFormulaName: (params: { formulaUuid: string; name: string }) => void;
  updateFormulaFormatting: (params: { formulaUuid: string; formatting: IFormattingEnum }) => void;
  updateFormulaRounding: (params: {
    formulaUuid: string;
    direction?: IRoundDirectionEnum;
    roundingPrecision?: number;
  }) => void;
  updateFormulaDataSource: (params: { formulaUuid: string; dataSourceUuids: string[] }) => void;
  updateFormulaMinMax: (params: { formula: IFormula; min: string | null; max: string | null }) => Promise<void>;
  dataSources: IIntegrationMapping[];
  allFormulasData: IFormulaData[];
  filteredFormulasData: IFormulaData[];
  searchFilter: Types.InputState;
  setSearchFilter: React.Dispatch<React.SetStateAction<Types.InputState>>;
  refreshData: () => Promise<void>;
  createNewAttribute: (params: { uuid: string }) => void;
  pendingAttributeData: {
    groupUuid: string;
  } | null;
  setPendingAttributeData: React.Dispatch<
    React.SetStateAction<{
      groupUuid: string;
    } | null>
  >;
  saveNewAttribute: (params: { groupIndex: number; name: string; tabIntoFormula: boolean }) => Promise<void>;
  deleteFormula: (formulaUuid: string) => Promise<void>;
  formulaUuidToFocus: string | null;
  setFormulaUuidToFocus: React.Dispatch<React.SetStateAction<string | null>>;
  pendingTabTarget: {
    columnIndex: number;
    formulaUuid: string;
  } | null;
  setPendingTabTarget: React.Dispatch<React.SetStateAction<{ columnIndex: number; formulaUuid: string } | null>>;
  csvExportData: string;
  viewOnly: boolean;
  manageGroupsModalOpen: boolean;
  setManageGroupsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dependencyGraph: IFormulaDependencyGraph;
  scrollEnabled: { x: boolean; y: boolean };
  setScrollEnabled: React.Dispatch<React.SetStateAction<{ x: boolean; y: boolean }>>;
  mode: IFormulaTypeEnum;
}

export const FormulasContext = createContext<IFormulasContext | undefined>(undefined);

interface FormulasProviderProps {
  children: ReactNode;
  defaultData?: {
    organization: IOrganizationState;
    scenario: ScenarioState;
    user: UserState;
  };
  viewOnly?: boolean;
  startDate?: IStringDate;
  endDate?: IStringDate;
  scenarioUuid?: string;
  mode: IFormulaTypeEnum;
  accessTokenOverride?: string | null;
}

export const FormulasProvider: React.FC<FormulasProviderProps> = ({
  children,
  defaultData,
  viewOnly = false,
  startDate,
  endDate,
  scenarioUuid,
  mode,
  accessTokenOverride,
}: FormulasProviderProps) => {
  const state = useSelector((state: State) => state);
  const isInitialRender = useRef<boolean>(true);
  const { organization, scenario, user } = defaultData ?? state;
  const { defaultGraphEndDate, defaultGraphStartDate } = user.preferences;
  const [allFormulasData, setAllFormulasData] = useState<IFormulaData[]>([]);
  const [formulaDictionary, setFormulaDictionary] = useState<Record<string, IFormula>>({});
  const [loadingFormulas, setLoadingFormulas] = useState<ILoadingFormulas[]>([]);
  const [searchFilter, setSearchFilter] = useInput({ validation: /.*/ });
  const [dataSources, setDataSources] = useState<IIntegrationMapping[]>([]);
  const [availableIntegration, setAvailableIntegration] = useState<IIntegrationSources | null>(null);
  const [pendingAttributeData, setPendingAttributeData] = useState<{
    groupUuid: string;
  } | null>(null);
  const [formulaUuidToFocus, setFormulaUuidToFocus] = useState<string | null>(null);
  const [pendingTabTarget, setPendingTabTarget] = useState<{
    columnIndex: number;
    formulaUuid: string;
  } | null>(null);
  const [manageGroupsModalOpen, setManageGroupsModalOpen] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState<{ x: boolean; y: boolean }>({ x: true, y: true });
  const [dependencyGraph, setDependencyGraph] = useState<IFormulaDependencyGraph>({});

  const csvExportData = useMemo(
    () =>
      convertModelToCsvData({
        modelData: allFormulasData,
        startDate: startDate ?? defaultGraphStartDate,
        endDate: endDate ?? defaultGraphEndDate,
      }),
    [allFormulasData, defaultGraphEndDate, defaultGraphStartDate, startDate, endDate],
  );

  const filteredFormulasData = useMemo(() => {
    if (mode === IFormulaTypeEnum.ModelBuilder) {
      return allFormulasData
        .map((group) => {
          if (group.type === IRecordTypeEnum.Group) {
            return {
              ...group,
              isCollapsed: searchFilter.value ? false : group.isCollapsed,
              formulas: group.formulas.filter(
                (formula) =>
                  formula.formula.recipe.name.toLowerCase().includes(searchFilter.value.toLowerCase()) ||
                  Object.values(formula.formula.recipe.variables).some(
                    (variable) =>
                      variable.formulaUuid &&
                      variable.formulaUuid in formulaDictionary &&
                      formulaDictionary[variable.formulaUuid].recipe.name
                        .toLowerCase()
                        .includes(searchFilter.value.toLowerCase()),
                  ),
              ),
            };
          }
          return group;
        })
        .filter((group) => (searchFilter.value && group.type === IRecordTypeEnum.Group ? group.formulas.length : true));
    } else {
      return allFormulasData.filter(
        (item) =>
          item.type === IRecordTypeEnum.Formula &&
          item.formula.recipe.name.toLowerCase().includes(searchFilter.value.toLowerCase()),
      );
    }
  }, [allFormulasData, searchFilter.value]);

  const selectedMonths = useMemo(() => {
    const startDateTime = startDate ?? user.preferences.defaultGraphStartDate;
    const endDateTime = endDate ?? user.preferences.defaultGraphEndDate;

    return stringDate
      .createMonthArrayBetweenDates({
        startDate: startDateTime,
        endDate: endDateTime,
      })
      .map((month) => stringDate.format(month, 'MMM yyyy'));
  }, [user.preferences.defaultGraphEndDate, user.preferences.defaultGraphStartDate]);

  const fetchData = async (): Promise<void> => {
    const formulasList = await api.formulas.list({
      organizationUuid: organization.uuid,
      startDate: startDate ?? defaultGraphStartDate,
      endDate: endDate ?? defaultGraphEndDate,
      scenarioUuid: scenarioUuid ?? scenario.activeScenarioData?.uuid ?? undefined,
      types:
        mode === IFormulaTypeEnum.ModelBuilder
          ? [
              IFormulaTypeEnum.ModelBuilder,
              IFormulaTypeEnum.ContractCashCollection,
              IFormulaTypeEnum.ContractRevenueRecognition,
              IFormulaTypeEnum.ContractSetupFee,
            ]
          : [IFormulaTypeEnum.Expense],
      accessTokenOverride,
    });

    const formulasDictionary = formulasList.reduce((output: Record<string, IFormula>, formula) => {
      output[formula.formulaUuid] = formula;
      return output;
    }, {});
    setFormulaDictionary(formulasDictionary);

    const integrationsList = !defaultData
      ? await api.integrations.list({
          organizationUuid: organization.uuid,
          accessTokenOverride,
        })
      : [];

    if (integrationsList.length) {
      setAvailableIntegration(integrationsList[0].source?.slug as IIntegrationSources);
    }

    let integrationMappings: IIntegrationMapping[] = [];
    if (integrationsList.length) {
      integrationMappings = await api.integrations.listMappings({
        organizationUuid: organization.uuid,
        integrationUuid: integrationsList[0].uuid,
        scope: mode,
        accessTokenOverride,
      });
    }

    setDataSources(integrationMappings);

    const integrationMappingsDictionary = integrationMappings.reduce(
      (output: Record<string, string>, integrationMapping) => {
        if (integrationMapping.uuid in output) return output;
        const integrationSlug = integrationsList.find(
          (integration) => integration.uuid === integrationMapping.integrationUuid,
        );
        if (integrationSlug) {
          output[integrationMapping.uuid] = integrationSlug.source?.slug ?? '';
        }
        return output;
      },
      {},
    );

    const dependencyGraph = await api.formulas.getDependencyGraph({
      organizationUuid: organization.uuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      accessTokenOverride,
    });
    setDependencyGraph(dependencyGraph);

    if (mode === IFormulaTypeEnum.ModelBuilder) {
      /** MANAGE SORT ORDER - START */
      const sortOrder = await api.formulas.getSortOrder({
        organizationUuid: organization.uuid,
        scenarioUuid: scenarioUuid ?? scenario.activeScenarioData?.uuid ?? undefined,
        accessTokenOverride,
      });

      const formulasNotInSortOrder = Object.entries(formulasDictionary)
        .filter(([, formula]) => formula.type !== IFormulaTypeEnum.ModelBuilder)
        .filter(
          ([formulaUuid, formula]) =>
            !sortOrder.groups.some((group) => group.sortOrder.includes(formulaUuid)) &&
            !['expense', 'contractCashCollection', 'contractSetupFee', 'contractRevRecognition'].includes(formula.type),
        )
        .reduce((acc: string[], array) => {
          acc.push(array[0]);
          return acc;
        }, []);

      if (formulasNotInSortOrder.length) {
        const ungroupedAttributesGroup = sortOrder.groups.find((group) => group.name === 'Ungrouped Attributes');
        if (!ungroupedAttributesGroup) {
          sortOrder.groups.push({
            name: 'Ungrouped Attributes',
            sortOrder: [...formulasNotInSortOrder],
          });
        } else {
          sortOrder.groups
            .find((group) => group.name === 'Ungrouped Attributes')
            ?.sortOrder.push(...formulasNotInSortOrder);
        }

        /**
         * Taking this out for now
         * const sortOrderParams: IUpdateSortOrderParams = {
         *   organizationUuid: organization.uuid,
         *   scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
         *   groups: sortOrder.groups,
         * };
         * await api.formulas.updateSortOrder(sortOrderParams);
         */
      }
      /** MANAGE SORT ORDER - END */

      setAllFormulasData((prevState) => {
        return sortOrder.groups.map((group): IGroupType => {
          const formulas = group.sortOrder
            .filter((formulaUuid) => formulaUuid in formulasDictionary)
            .map((formulaUuid): IFormulaType => {
              const isReferencedElsewhere = formulasList.some((f) =>
                Object.values(f.recipe.variables).some((variable) => variable.formulaUuid === formulaUuid),
              );
              const formula = formulasDictionary[formulaUuid];

              const actualsDict = formula.actuals.reduce<Record<string, number>>((output, actual) => {
                output[stringDate.format(actual.date, 'MMM yyyy')] = actual.value;
                return output;
              }, {});

              const overridesDict = formula.overrides.reduce<Record<string, number>>((output, override) => {
                output[stringDate.format(override.date, 'MMM yyyy')] = override.value;
                return output;
              }, {});

              const calculationsDict = formula.calculations.reduce<Record<string, number>>((output, calculation) => {
                output[stringDate.format(calculation.date, 'MMM yyyy')] = calculation.value ?? 0;
                return output;
              }, {});

              const monthlyValues = selectedMonths.reduce<IMonthlyValues>((output, month) => {
                output[month] = {
                  calculatedValue: calculationsDict[month] ?? 0,
                  overrideValue: overridesDict[month] ?? null,
                  actualValue: actualsDict[month] ?? null,
                  formatting: formula.formatting ?? null,
                  roundingInstructions: formula.recipe.roundingInstructions ?? null,
                };
                return output;
              }, {});

              return {
                type: IRecordTypeEnum.Formula,
                formulaUuid: formula.formulaUuid,
                relatedResourceUuid: null,
                label: {
                  name: formula.recipe.name,
                  integration:
                    (formula.dataSourceUuids.length && integrationMappingsDictionary[formula.dataSourceUuids[0]]) ||
                    null,
                  isIsolated: !isReferencedElsewhere,
                  isProtected: formula.isProtected,
                },
                formula: {
                  recipe: formula.recipe,
                  isProtected: formula.isProtected,
                },
                monthlyValues,
              };
            });

          let isCollapsed = false;
          if (
            prevState.find((g): g is IGroupType => g.type === IRecordTypeEnum.Group && g.name === group.name)
              ?.isCollapsed !== undefined
          ) {
            isCollapsed =
              prevState.find((g): g is IGroupType => g.type === IRecordTypeEnum.Group && g.name === group.name)
                ?.isCollapsed ?? false;
          }

          return {
            type: IRecordTypeEnum.Group,
            uuid: objectHash(group),
            name: group.name,
            isCollapsed,
            formulas,
          };
        });
      });
    } else {
      setAllFormulasData(
        Object.entries(formulasDictionary).map(([formulaUuid, formula]): IFormulaType => {
          const isReferencedElsewhere = formulasList.some((f) =>
            Object.values(f.recipe.variables).some((variable) => variable.formulaUuid === formulaUuid),
          );

          const actualsDict = formula.actuals.reduce<Record<string, number>>((output, actual) => {
            output[stringDate.format(actual.date, 'MMM yyyy')] = actual.value;
            return output;
          }, {});

          const overridesDict = formula.overrides.reduce<Record<string, number>>((output, override) => {
            output[stringDate.format(override.date, 'MMM yyyy')] = override.value;
            return output;
          }, {});

          const calculationsDict = formula.calculations.reduce<Record<string, number>>((output, calculation) => {
            output[stringDate.format(calculation.date, 'MMM yyyy')] = calculation.value ?? 0;
            return output;
          }, {});

          const monthlyValues = selectedMonths.reduce<IMonthlyValues>((output, month) => {
            output[month] = {
              calculatedValue: calculationsDict[month] ?? 0,
              overrideValue: overridesDict[month] ?? null,
              actualValue: actualsDict[month] ?? null,
              formatting: formula.formatting ?? null,
              roundingInstructions: formula.recipe.roundingInstructions ?? null,
            };
            return output;
          }, {});

          return {
            type: IRecordTypeEnum.Formula,
            formulaUuid: formula.formulaUuid,
            relatedResourceUuid: formula.recipe.relatedResourceUuid ?? null,
            label: {
              name: formula.recipe.name,
              integration:
                (formula.dataSourceUuids.length && integrationMappingsDictionary[formula.dataSourceUuids[0]]) || null,
              isIsolated: !isReferencedElsewhere,
              isProtected: formula.isProtected,
            },
            formula: {
              recipe: formula.recipe,
              isProtected: formula.isProtected,
            },
            monthlyValues,
          };
        }),
      );
    }
  };

  useEffect(() => {
    if (organization.uuid) {
      fetchData();
    }
  }, [
    defaultGraphEndDate,
    defaultGraphStartDate,
    organization.uuid,
    startDate,
    endDate,
    scenario.activeScenarioData?.uuid,
  ]);

  const toggleGroupCollapse = (value: string | boolean): void => {
    if (mode !== IFormulaTypeEnum.ModelBuilder) throw new Error('Unable to access group. Type not ModelBuilder');
    if (typeof value === 'string') {
      // Toggle individual group
      setAllFormulasData((prev) =>
        prev.map((group) => {
          if (group.type !== IRecordTypeEnum.Group) return group;
          return group.uuid === value ? { ...group, isCollapsed: !group.isCollapsed } : group;
        }),
      );
    } else {
      // Toggle all groups at once
      setAllFormulasData((prev) =>
        prev.map((group) => {
          if (group.type !== IRecordTypeEnum.Group) return group;
          return { ...group, isCollapsed: value };
        }),
      );
    }
  };

  const onDragEnd = (value: DragEndEvent): void => {
    if (mode !== IFormulaTypeEnum.ModelBuilder)
      throw new Error('Sorting is not supported outside of ModelBuilder type');

    if (viewOnly) return;
    const { active, over } = value;
    if (!over) return;

    // Verify all items in allFormulasData are of type IGroup
    if (!allFormulasData.every((group): group is IGroupType => group.type === IRecordTypeEnum.Group)) {
      throw new Error('Invalid data structure: allFormulasData must contain only Group type records');
    }

    const clonedGroups = cloneDeep(allFormulasData);
    // Find the group and formula of the active item
    const sourceGroup = clonedGroups.find((group) =>
      group.formulas.some((formula) => formula.formulaUuid === active.id.toString()),
    );

    const targetGroup = clonedGroups.find(
      (group) =>
        group.formulas.some((formula) => formula.formulaUuid === over.id.toString()) ||
        group.uuid === over.id.toString(),
    );

    if (sourceGroup && targetGroup) {
      const sourceIndex = sourceGroup.formulas.findIndex((f) => f.formulaUuid === active.id.toString());
      const targetIndex = targetGroup.formulas.findIndex((f) => f.formulaUuid === over.id.toString());

      // If moving within the same group, reorder
      if (sourceGroup.name === targetGroup.name) {
        const [movedFormula] = sourceGroup.formulas.splice(sourceIndex, 1);

        if (sourceIndex > targetIndex) {
          // Moving down the list
          targetGroup.formulas.splice(targetIndex + 1, 0, movedFormula);
        } else {
          // Moving up the list
          targetGroup.formulas.splice(targetIndex, 0, movedFormula);
        }
      } else {
        // Remove the item from its original position
        const [movedFormula] = sourceGroup.formulas.splice(
          sourceGroup.formulas.findIndex((f) => f.formulaUuid === active.id.toString()),
          1,
        );
        if (targetGroup.isCollapsed) {
          // If moving to a closed group, add it to the beginning of the target group
          targetGroup.formulas.unshift(movedFormula);
        } else {
          // Add to after the target index
          targetGroup.formulas.splice(targetIndex + 1, 0, movedFormula);
        }
      }
    }

    updateSortOrder(clonedGroups);
  };

  const updateSortOrder = async (updatedFormulasData: IGroupType[]): Promise<void> => {
    if (viewOnly) return;
    setAllFormulasData(updatedFormulasData);

    const sortOrder: IUpdateSortOrderParams = {
      organizationUuid: organization.uuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      groups: updatedFormulasData.map((group) => ({
        name: group.name,
        sortOrder: group.formulas.map((formula) => formula.formulaUuid),
      })),
    };

    await api.formulas.updateSortOrder(sortOrder);
  };

  const getEffectedMonths = (month: stringDate.types.IStringDate): stringDate.types.IStringDate[] => {
    const months = selectedMonths.filter((m) =>
      stringDate.isSameMonthOrAfter({ comparison: month, dateToCheck: stringDate.MMMyyyyToStringDate(m) }),
    );
    return months;
  };

  const getEffectedFormulas = (formulaUuid: string, processedUuids: Set<string> = new Set()): string[] => {
    // If we've already processed this UUID, return empty array to prevent circular recursion
    if (processedUuids.has(formulaUuid)) {
      return [];
    }

    // Add current UUID to processed set
    processedUuids.add(formulaUuid);

    const uuids = Object.values(formulaDictionary)
      .filter((formula) => {
        const recipeVariables = formula.recipe.variables;
        const variables = Object.values(recipeVariables);
        return variables.some((variable) => variable.formulaUuid === formulaUuid);
      })
      .map((formula) => formula.formulaUuid);

    if (uuids.length) {
      const chainedFormulaUuids = uuids.flatMap((uuid) => getEffectedFormulas(uuid, processedUuids));
      return Array.from(new Set([formulaUuid, ...uuids, ...chainedFormulaUuids]));
    }
    return [formulaUuid];
  };

  const handleLoadingMonths = (params: {
    requestUuid: string;
    formulaUuid: string;
    month: stringDate.types.IStringDate;
  }): void => {
    const effectedMonths = getEffectedMonths(params.month);
    const effectedFormulas = getEffectedFormulas(params.formulaUuid);
    setLoadingFormulas((prev) => [
      ...prev,
      {
        requestUuid: params.requestUuid,
        formulaUuids: [params.formulaUuid, ...effectedFormulas],
        months: effectedMonths,
      },
    ]);
  };

  const resolveLoadingFormulas = (params: { requestUuid: string }): void => {
    setLoadingFormulas((prev) => prev.filter((request) => request.requestUuid !== params.requestUuid));
  };

  const updateFormulaMonthsValue = async (params: {
    formulaUuid: string;
    months: stringDate.types.IStringDate[];
    values: (null | number)[];
    lastNeededDate: stringDate.types.IStringDate;
  }): Promise<void> => {
    if (viewOnly) return;
    if (!params.values.length || !params.months.length) return;

    const requestUuid = uuid();
    handleLoadingMonths({ requestUuid, formulaUuid: params.formulaUuid, month: params.months[0] });

    try {
      const formula = formulaDictionary[params.formulaUuid];
      const startOfMonthDate = stringDate.startOfMonth(stringDate.getStringDate());

      const request: IUpdateMonthValueParams = {
        organizationUuid: organization.uuid,
        formulaUuid: params.formulaUuid,
        latestNeededDate: params.lastNeededDate,
        idempotencyKey: uuid(),
        scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      };

      // Create maps to track which months to remove/add for both overrides and actuals
      const monthsToUpdate = new Map<string, { value: number | null; isOverride: boolean }>();

      // Process all months at once and determine if they are overrides or actuals
      params.months.forEach((month, index) => {
        const value = params.values[index];
        const isOverride = stringDate.isSameMonthOrAfter({ comparison: startOfMonthDate, dateToCheck: month });
        monthsToUpdate.set(stringDate.endOfMonth(month), { value, isOverride });
      });

      const newOverrides = formula.overrides.filter((override) => {
        return !monthsToUpdate.has(override.date);
      });

      const newActuals = formula.actuals.filter((actual) => {
        return !monthsToUpdate.has(actual.date);
      });

      // Add new values
      monthsToUpdate.forEach((update, date) => {
        if (update.value === null) return;

        if (update.isOverride) {
          newOverrides.push({ date, value: update.value });
        } else {
          newActuals.push({ date, value: update.value });
        }
      });

      request.overrides = newOverrides.map((override) => ({
        ...override,
        date: stringDate.getISO8601EndOfMonth(override.date),
      }));
      request.actuals = newActuals.map((actual) => ({
        ...actual,
        date: stringDate.getISO8601EndOfMonth(actual.date),
      }));

      await api.formulas.update(request);

      await fetchData();
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
    } finally {
      resolveLoadingFormulas({ requestUuid });
    }
  };

  const updateFormulaName = async (params: { formulaUuid: string; name: string }): Promise<void> => {
    if (viewOnly) return;
    const formula = formulaDictionary[params.formulaUuid];

    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      recipe: {
        ...formula.recipe,
        name: params.name,
      },
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaFormatting = async (params: {
    formulaUuid: string;
    formatting: IFormattingEnum;
  }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      formatting: params.formatting,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaMinMax = async (params: {
    formula: IFormula;
    min: string | null;
    max: string | null;
  }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formula.formulaUuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      recipe: {
        ...params.formula.recipe,
        min: params.min?.length ? params.min : null,
        max: params.max?.length ? params.max : null,
      },
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaRounding = async (params: {
    formulaUuid: string;
    direction?: IRoundDirectionEnum;
    roundingPrecision?: number;
  }): Promise<void> => {
    if (viewOnly) return;
    const recipe = formulaDictionary[params.formulaUuid].recipe;

    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      recipe: {
        ...recipe,
        roundingInstructions:
          params.direction && params.roundingPrecision
            ? {
                direction: params.direction,
                precision: params.roundingPrecision,
              }
            : null,
      },
    };

    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaDataSource = async (params: { formulaUuid: string; dataSourceUuids: string[] }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      dataSourceUuids: params.dataSourceUuids,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
    };

    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const createNewAttribute = ({ uuid }: { uuid: string }): void => {
    if (viewOnly) return;
    setPendingAttributeData({
      groupUuid: uuid,
    });
  };

  const saveNewAttribute = async (params: {
    groupIndex: number;
    name: string;
    tabIntoFormula: boolean;
  }): Promise<void> => {
    if (viewOnly) return;

    let currentSortOrder;

    if (mode === IFormulaTypeEnum.ModelBuilder) {
      currentSortOrder = allFormulasData.map((group) => {
        if (group.type !== IRecordTypeEnum.Group) throw new Error('Group must be top level data type');
        return {
          name: group.name,
          sortOrder: group.formulas.map((formula) => formula.formulaUuid),
        };
      });
    }

    const createdAttribute = await api.formulas.createAttribute({
      organizationUuid: organization.uuid,
      groupIndex: params.groupIndex,
      name: params.name,
      scenarioUuid: scenarioUuid ?? scenario.activeScenarioData?.uuid ?? undefined,
      currentSortOrder,
    });

    await fetchData();

    setPendingAttributeData(null);

    if (params.tabIntoFormula && createdAttribute.formulaUuid) {
      setFormulaUuidToFocus(createdAttribute.formulaUuid);
    }
  };

  const deleteFormula = async (formulaUuid: string): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
    };
    await api.formulas.remove(request);
    await fetchData();
  };

  useEffect(() => {
    const handleClick = (): void => {
      if (pendingTabTarget) setPendingTabTarget(null);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (!state.integrations.isPulling) {
      fetchData();
    }
  }, [state.integrations.isPulling]);

  const value = {
    allFormulasData,
    formulaDictionary,
    dataSources,
    availableIntegration,
    toggleGroupCollapse,
    onDragEnd,
    updateSortOrder,
    selectedMonths,
    updateFormulaMonthsValue,
    loadingFormulas,
    updateFormulaName,
    updateFormulaFormatting,
    updateFormulaRounding,
    updateFormulaDataSource,
    updateFormulaMinMax,
    filteredFormulasData,
    searchFilter,
    setSearchFilter,
    createNewAttribute,
    pendingAttributeData,
    setPendingAttributeData,
    saveNewAttribute,
    deleteFormula,
    formulaUuidToFocus,
    setFormulaUuidToFocus,
    refreshData: fetchData,
    pendingTabTarget,
    setPendingTabTarget,
    csvExportData,
    viewOnly,
    manageGroupsModalOpen,
    setManageGroupsModalOpen,
    dependencyGraph,
    scrollEnabled,
    setScrollEnabled,
    mode,
  };

  return <FormulasContext.Provider value={value}>{children}</FormulasContext.Provider>;
};
