import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Editor } from '@tiptap/react';

interface Props {
  editor: Editor;
  timeseriesValue?: string;
  updateTimeModifier: (modifier: string) => void;
  viewOnly?: boolean;
}

const NUM_OF_MONTHS = 13;

const TimeseriesModifier = ({ editor, timeseriesValue, updateTimeModifier, viewOnly }: Props): React.ReactElement => {
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <PopoverButton
            as="div"
            className={`flex px-2 flex-row gap-x-1 items-center cursor-pointer !w-fit text-neutral-200 ${
              viewOnly ? '!cursor-default' : ''
            }`}
            onClick={(e) => {
              if (viewOnly) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (!editor.isFocused) {
                editor.commands.focus();
              }
            }}
          >
            {timeseriesValue}
            <ChevronDownIcon className="size-3 stroke-2" />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            as="div"
            className={`${
              open ? 'block' : 'hidden'
            } absolute bg-white shadow-md rounded-md z-[15] border-none outline-none`}
          >
            <div className="flex flex-col" data-testid="timeseries-options">
              {Array.from({ length: NUM_OF_MONTHS }, (_, i) => (
                <div
                  key={i}
                  className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateTimeModifier(`previous-${i}`);
                    if (!editor.isFocused) {
                      editor.commands.focus();
                    }
                    close();
                  }}
                >
                  {i === 0 ? 'This Month' : i === 1 ? 'Last Month' : `${i} Months Ago`}
                </div>
              ))}
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default TimeseriesModifier;
