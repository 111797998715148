import { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { differenceInCalendarMonths } from 'date-fns/differenceInCalendarMonths';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStringDate } from '~/utils/stringDate/types';
import { State } from '~/store';

export interface ITableContext {
  columnWidths: number[];
  handleColumnResize: (e: React.MouseEvent, columnIndex: number) => void;
  draggingId: string | null;
  draggingOverId: string | null;
  handleDragStart: (event: DragStartEvent) => void;
  handleDragOver: (event: DragOverEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  startDate?: IStringDate;
  endDate?: IStringDate;
  sortingDisabled?: boolean;
}

export const TableContext = createContext<ITableContext | undefined>(undefined);

export const TableProvider: React.FC<{
  children: ReactNode;
  value: Partial<ITableContext> & { onDragEnd?: (event: DragEndEvent) => void };
}> = ({ children, value: initialValue }) => {
  const { defaultGraphEndDate, defaultGraphStartDate } = useSelector((state: State) => state.user.preferences);
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [draggingOverId, setDraggingOverId] = useState<string | null>(null);
  const [columnWidths, setColumnWidths] = useState<number[]>([250, 500]);

  useEffect(() => {
    const numberOfMonthsToRender = differenceInCalendarMonths(
      initialValue.endDate ?? defaultGraphEndDate,
      initialValue.startDate ?? defaultGraphStartDate,
    );
    const MONTH_WIDTH = 125;
    // +1 to account for including the first month
    const monthsWidthsArray = Array.from({ length: numberOfMonthsToRender + 1 }, () => MONTH_WIDTH);
    setColumnWidths((prevState) => [prevState[0], prevState[1], ...monthsWidthsArray]);
  }, [defaultGraphEndDate, defaultGraphStartDate]);

  const handleColumnResize = (e: React.MouseEvent, columnIndex: number): void => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = columnWidths[columnIndex];

    const handleMouseMove = (moveEvent: MouseEvent): void => {
      const deltaX = moveEvent.clientX - startX;
      const newWidth = Math.max(startWidth + deltaX, 100);
      const newWidths = [...columnWidths];
      newWidths[columnIndex] = newWidth;
      setColumnWidths(newWidths);
    };

    const handleMouseUp = (): void => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleDragStart = (event: DragStartEvent): void => {
    setDraggingId(event.active.id.toString());
  };

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    const activeId = active.id;
    const overId = over?.id;

    if (!overId || activeId === overId) return;

    setDraggingOverId(overId.toString());
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    setDraggingId(null);
    setDraggingOverId(null);
    initialValue.onDragEnd?.(event);
  };

  return (
    <TableContext.Provider
      value={{
        columnWidths,
        draggingId,
        draggingOverId,
        handleDragStart,
        handleDragOver,
        handleDragEnd,
        handleColumnResize,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
