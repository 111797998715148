import { getLastDayOfMonth } from './getLastDayOfMonth';
import { IStringDate } from './types';

export const preciseDifferenceInMonths = ({
  startDate,
  endDate,
}: {
  startDate: IStringDate;
  endDate: IStringDate;
}): number => {
  let [startYear, startMonth, startDay] = startDate.split('-').map(Number);
  let [endYear, endMonth, endDay] = endDate.split('-').map(Number);

  if (
    startYear > endYear ||
    (startYear === endYear && startMonth > endMonth) ||
    (startYear === endYear && startMonth === endMonth && startDay > endDay)
  ) {
    [startYear, startMonth, startDay, endYear, endMonth, endDay] = [
      endYear,
      endMonth,
      endDay,
      startYear,
      startMonth,
      startDay,
    ];
  }

  const fullMonths = (endYear - startYear) * 12 + (endMonth - startMonth);

  const daysInEndMonth = Number(getLastDayOfMonth(endDate));
  const remainingDays = endDay - startDay;

  return fullMonths + remainingDays / daysInEndMonth;
};
