import React, { useContext, useState } from 'react';
import Modal from '~/components/Modal';
import { ExpensesPageContext } from '../context/ExpensesContext';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { StatusCodes } from 'http-status-codes';
import toast from 'react-hot-toast';
import useFormulaContext from '~/components/Formulas/context/useFormulaContext';

const DeleteExpenseModal = (): React.ReactElement => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { deleteExpense, setDeleteExpense, expenses, setExpenses, revalidateFilteredExpensesReport, setFormulaList } =
    useContext(ExpensesPageContext);
  const { refreshData } = useFormulaContext();
  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);

  const handleDeleteExpense = async (): Promise<void> => {
    setDeleteRequestLoading(true);
    const response = await request({
      method: 'DELETE',
      url: `/expenses/${deleteExpense?.expenseUuid}`,
      params: {
        scenarioUuid: activeScenarioUuid,
      },
      headers: { 'Organization-Uuid': organizationUuid },
    });

    if (response.status === StatusCodes.NO_CONTENT) {
      setDeleteExpense(undefined);
      setExpenses(expenses.filter((expense) => expense.expenseUuid !== deleteExpense?.expenseUuid));
      revalidateFilteredExpensesReport();
      setFormulaList((prev) =>
        prev.filter((formula) => {
          if ('resourceUuid' in formula.recipe) {
            return formula.recipe.resourceUuid !== deleteExpense?.expenseUuid;
          }
          return true;
        }),
      );
      refreshData();
    } else {
      toast.error('Failed to delete expense');
    }
    setDeleteRequestLoading(false);
  };

  return (
    <Modal
      title={`Delete ${deleteExpense?.expenseTitle ?? 'Expense'} & Purge History`}
      isOpen={!!deleteExpense}
      onClose={() => setDeleteExpense(undefined)}
      size="xs"
      id="delete-expense-modal"
    >
      <div className="flex flex-col gap-4">
        <div>
          <Typography color="secondary">
            Deleting this expense will remove all related data, including all historical impact.
          </Typography>
        </div>
        <div className="flex w-full justify-between">
          <div className="w-fit">
            <Button fill="clear" className="!w-fit !px-0" onClick={() => setDeleteExpense(undefined)}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button
              fill="destructive"
              loading={deleteRequestLoading || !deleteExpense}
              onClick={() => handleDeleteExpense()}
            >
              Confirm, Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteExpenseModal;
