import { z } from 'zod';

export const ZStringDate = z
  .string()
  .transform((val) => {
    return val.split('T')[0];
  })
  .refine((val) => /^\d{4}-\d{2}-\d{2}$/.test(val), { message: 'Invalid date format. Expected YYYY-MM-DD.' });

export type IStringDate = z.infer<typeof ZStringDate>;
