import { IFormulaData } from '~/components/Formulas/context/types';
import { roundCalculatedValue } from '~/utils/roundCalculatedValue';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';
import { IRecordTypeEnum } from '~/components/Formulas/FormulasTable/TableBody';

export const convertModelToCsvData = ({
  modelData,
  startDate,
  endDate,
}: {
  modelData: IFormulaData[];
  startDate: IStringDate;
  endDate: IStringDate;
}): string => {
  const monthsArray = stringDate.createMonthArrayBetweenDates({
    startDate,
    endDate,
  });
  const headers = ['Attribute Title', ...monthsArray.map((month) => stringDate.format(month, 'MMM yyyy'))];
  const rows: string[][] = [];

  modelData.forEach((item) => {
    if (item.type === IRecordTypeEnum.Group) {
      // Handle group
      rows.push([item.name, ...Array<string>(monthsArray.length).fill('')]);
      item.formulas.forEach((formula) => {
        const formulaRow = [
          formula.label.name,
          ...Object.values(formula.monthlyValues).map((value) => {
            if (value.actualValue !== null) {
              return roundCalculatedValue({
                value: value.actualValue,
                roundingInstructions: value.roundingInstructions,
              }).toString();
            }
            if (value.overrideValue !== null) {
              return roundCalculatedValue({
                value: value.overrideValue,
                roundingInstructions: value.roundingInstructions,
              }).toString();
            }
            return roundCalculatedValue({
              value: value.calculatedValue / CENTS_PER_DOLLAR,
              roundingInstructions: value.roundingInstructions,
              maxDecimalPlaces: 2,
            }).toString();
          }),
        ];
        rows.push(formulaRow);
      });
    } else {
      // Handle single formula
      const formulaRow = [
        item.label.name,
        ...Object.values(item.monthlyValues).map((value) => {
          if (value.actualValue !== null) {
            return roundCalculatedValue({
              value: value.actualValue,
              roundingInstructions: value.roundingInstructions,
            }).toString();
          }
          if (value.overrideValue !== null) {
            return roundCalculatedValue({
              value: value.overrideValue,
              roundingInstructions: value.roundingInstructions,
            }).toString();
          }
          return roundCalculatedValue({
            value: value.calculatedValue / CENTS_PER_DOLLAR,
            roundingInstructions: value.roundingInstructions,
            maxDecimalPlaces: 2,
          }).toString();
        }),
      ];
      rows.push(formulaRow);
    }
  });

  return [headers, ...rows].map((row) => row.join(',')).join('\n');
};
