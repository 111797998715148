import React, { useEffect, useState } from 'react';
import Typography from '../Typography';
import { IDataArrayDictionary } from './entity/types';
import formatToLetterAbbreviatedNumber from '~/utils/formatToLetterAbbreviatedNumber';

interface IProps {
  xFormatter: (value: number | null) => string;
  yFormatter: (value: number | null) => string;
  active?: boolean;
  label?: number;
  data: IDataArrayDictionary[];
  activeIndex: number;
  timeboundGoalHovered: {
    x: number;
    y: number;
    goal: number;
  } | null;
}

const getTextColor = (
  stroke: string,
): 'blue' | 'orange' | 'purple' | 'green' | 'turquoise' | 'disabled' | undefined => {
  if (stroke === 'activeScenario') return 'blue';
  if (stroke === 'scenario1') return 'orange';
  if (stroke === 'scenario2') return 'purple';
  if (stroke === 'workingModel') return 'green';
  if (stroke === 'scenario3') return 'turquoise';
  if (stroke === 'temporaryStaticScenario') return 'disabled';

  return undefined;
};

const LineGraphTooltip = ({
  xFormatter,
  yFormatter,
  active,
  label,
  data,
  activeIndex,
  timeboundGoalHovered,
}: IProps): React.ReactElement | null => {
  const [activeData, setActiveData] = useState<IDataArrayDictionary | null>(null);

  useEffect(() => {
    if (data[activeIndex]) {
      setActiveData(data[activeIndex]);
    } else {
      setActiveData(null);
    }
  }, [data, activeIndex]);

  if (!activeData || !active) return null;

  if (timeboundGoalHovered) {
    return (
      <div className="bg-green-15 rounded-xl border-[1px] border-green-75 py-1 px-3 w-[150px] h-[90px]">
        <Typography color="primaryGreen" size="xs" weight="extralight">
          {xFormatter(label ?? null)}
        </Typography>
        <div className="flex items-center justify-between w-full">
          <Typography color="green" size="sm" weight="extralight">
            Goal
          </Typography>
          <Typography color="green" size="sm" weight="bold">
            {yFormatter(timeboundGoalHovered.goal)}
          </Typography>
        </div>
        <div className="flex items-center justify-between w-full">
          <Typography color="secondary" size="sm" weight="extralight">
            Current
          </Typography>
          <Typography color="secondary" size="sm" weight="bold">
            {yFormatter(activeData.workingModel)}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white flex flex-row ml-2 rounded-lg border border-neutral-75">
      <div className="flex flex-col py-1 px-3 justify-center">
        <Typography color="secondary" size="xs">
          {xFormatter(label ?? null)}
        </Typography>
        <div className="flex items-center justify-between">
          {Object.keys(activeData).map((key, index) => {
            if (key !== 'date') {
              return (
                <React.Fragment key={key}>
                  <Typography size="sm" weight="bold" color={getTextColor(key)} className="mr-1">
                    {yFormatter(activeData[key])}
                  </Typography>
                  {index !== Object.keys(activeData).length - 1 && (
                    <Typography size="sm" color="disabled" className="mr-1">
                      |
                    </Typography>
                  )}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      {Object.entries(activeData).filter(([key]) => key !== 'date').length === 2 && (
        <div className="mx-0 my-0">
          {((): React.ReactNode => {
            let workingModelValue = activeData.workingModel ?? 0;
            const scenarioValue =
              (activeData.activeScenario || activeData.scenario1 || activeData.temporaryStaticScenario) ?? 0;
            const difference = scenarioValue - workingModelValue;
            if (difference !== 0 && workingModelValue === 0) {
              workingModelValue = 1;
            }
            let percentChange;
            if (difference === 0 && workingModelValue === 0) {
              percentChange = 0;
            } else {
              percentChange = ((difference / Math.abs(workingModelValue)) * 100).toFixed(2);
            }
            return (
              <div className="flex flex-col bg-neutral-25 items-center justify-center h-full py-2 px-3 rounded-r-lg">
                <Typography size="xs" color="secondary">
                  {`${formatToLetterAbbreviatedNumber({ value: difference })}`}
                </Typography>
                <Typography size="xs" color="secondary">
                  {`${Number(percentChange).toFixed(1)}%`}
                </Typography>
              </div>
            );
          })()}
        </div>
      )}
    </div>
  );
};

export default LineGraphTooltip;
