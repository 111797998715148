import { IDataArrayDictionary } from '~/components/LineGraph/entity/types';
import { ExpenseFrequencyEnum, IExpense } from '../types';
import { isExpenseAppliedInMonth } from '~/pages/Expenses/utils/isExpenseAppliedInMonth';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { calculateExpenseAmount } from '~/pages/Expenses/utils/calculateExpenseAmount';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

export const graphDataByType = ({
  expenses,
  startDate,
  endDate,
  positions,
}: {
  expenses: IExpense[];
  startDate: IStringDate;
  endDate: IStringDate;
  positions: IPositionDetails[];
}): {
  typeData: IDataArrayDictionary[];
  categoryData: IDataArrayDictionary[];
} => {
  const typeData: IDataArrayDictionary[] = [];
  const categoryData: IDataArrayDictionary[] = [];
  const monthArray = stringDate.createMonthArrayBetweenDates({
    startDate,
    endDate,
  });
  monthArray.forEach((month) => {
    const time = new Date(month).getTime();
    const monthTypeData: Record<string, number> = {
      date: time,
      oneTime: 0,
      monthly: 0,
      quarterly: 0,
      annual: 0,
      onHire: 0,
    };
    const monthCategoryData: Record<string, number> = {
      date: time,
      software: 0,
      other: 0,
      peopleAndFacilities: 0,
      cogs: 0,
      marketing: 0,
    };

    expenses.forEach((expense) => {
      const expenseIsApplied = isExpenseAppliedInMonth({
        expense,
        month,
        positions,
      });

      if (expenseIsApplied) {
        switch (expense.context.frequency) {
          case ExpenseFrequencyEnum.OneTime:
            monthTypeData.oneTime += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.Monthly:
            monthTypeData.monthly += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.Quarterly:
            monthTypeData.quarterly += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.Annually:
            monthTypeData.annual += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case ExpenseFrequencyEnum.OnHire:
            monthTypeData.onHire += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          default:
            break;
        }

        switch (expense.context.tag) {
          case 'Software':
            monthCategoryData.software += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case 'Other':
            monthCategoryData.other += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case 'People & Facilities':
            monthCategoryData.peopleAndFacilities += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case 'Cost of Goods Sold':
            monthCategoryData.cogs += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          case 'Marketing':
            monthCategoryData.marketing += calculateExpenseAmount({
              expense,
              month,
              positions: positions,
            });
            break;
          default:
            break;
        }
      }
    });

    typeData.push(monthTypeData);
    categoryData.push(monthCategoryData);
  });

  return { typeData, categoryData };
};
