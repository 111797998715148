import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { ExpenseFrequencyEnum, IExpense } from '../components/types';
import { IStringDate } from '~/utils/stringDate/types';
import * as stringDate from '~/utils/stringDate';

export const isExpenseAppliedInMonth = ({
  expense,
  month,
  positions,
}: {
  expense: IExpense;
  month: IStringDate;
  positions: IPositionDetails[];
}): boolean => {
  const expenseStartDate = expense.context.startDate;
  const expenseEndDate = expense.context.endDate ?? null;

  switch (expense.context.frequency) {
    case ExpenseFrequencyEnum.OneTime:
      return stringDate.isSameMonth({ date1: expenseStartDate, date2: month });
    case ExpenseFrequencyEnum.Monthly:
      return (
        stringDate.isSameMonthOrBefore({
          comparison: month,
          dateToCheck: expenseStartDate,
        }) &&
        (!expenseEndDate ||
          stringDate.isSameMonthOrAfter({
            comparison: month,
            dateToCheck: expenseEndDate,
          }))
      );
    case ExpenseFrequencyEnum.Quarterly: {
      const validMonths = [
        expenseStartDate,
        stringDate.addMonths(expenseStartDate, 3),
        stringDate.addMonths(expenseStartDate, 6),
        stringDate.addMonths(expenseStartDate, 9),
      ];
      return (
        stringDate.isSameMonthOrBefore({
          comparison: month,
          dateToCheck: expenseStartDate,
        }) && validMonths.some((validMonth) => stringDate.getMonth(validMonth) === stringDate.getMonth(month))
      );
    }
    case ExpenseFrequencyEnum.Annually: {
      const startYear = stringDate.getYear(expenseStartDate);
      return (
        stringDate.getMonth(expenseStartDate) === stringDate.getMonth(month) &&
        startYear <= stringDate.getYear(month) &&
        (!expenseEndDate ||
          stringDate.isAfter({
            comparison: month,
            dateToCheck: expenseEndDate,
          }))
      );
    }
    case ExpenseFrequencyEnum.OnHire: {
      return positions.some((position) => {
        const hireDate = position.hireDate;
        return (
          stringDate.isSameMonth({
            date1: hireDate,
            date2: month,
          }) &&
          stringDate.isSameYear({
            date1: month,
            date2: hireDate,
          })
        );
      });
    }
    default:
      return false;
  }
};
