import React from 'react';
import Modal from '~/components/Modal';
import PrebuiltOptions, { IPrebuiltComponentType } from './PrebuiltOptions';
import RepRampingForm from './RepRampingForm';
import MonthlyPercentageAggregatorForm from './MonthlyPercentageAggregatorForm';
import MarketingFunnelForm from './MarketingFunnelForm';
import LoanScheduleForm from './LoanScheduleForm';

interface Props {
  prebuiltComponentModalState: {
    isOpen: boolean;
    display: IPrebuiltComponentType;
  };
  setPrebuiltComponentModalState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      display: IPrebuiltComponentType;
    }>
  >;
}

const PrebuiltComponentsModal = ({
  prebuiltComponentModalState,
  setPrebuiltComponentModalState,
}: Props): React.ReactNode => {
  const handleClose = async (): Promise<void> => {
    setPrebuiltComponentModalState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    setTimeout(() => {
      setPrebuiltComponentModalState({
        isOpen: false,
        display: IPrebuiltComponentType.Options,
      });
    }, 300);
  };
  const modalContent = (prebuiltComponentType: IPrebuiltComponentType): React.ReactNode => {
    switch (prebuiltComponentType) {
      case IPrebuiltComponentType.CashCollections:
        return (
          <MonthlyPercentageAggregatorForm handleClose={handleClose} type={IPrebuiltComponentType.CashCollections} />
        );
      case IPrebuiltComponentType.RevenueRecognition:
        return (
          <MonthlyPercentageAggregatorForm handleClose={handleClose} type={IPrebuiltComponentType.RevenueRecognition} />
        );
      case IPrebuiltComponentType.MarketingFunnel:
        return <MarketingFunnelForm handleClose={handleClose} />;
      case IPrebuiltComponentType.LoanSchedule:
        return <LoanScheduleForm handleClose={handleClose} />;
      case IPrebuiltComponentType.RepRamping:
      default:
        return <RepRampingForm handleClose={handleClose} />;
    }
  };

  const typeToTitleMap: Record<string, string> = {
    'cash-collections': 'Cash Collections',
    'revenue-recognition': 'Revenue Recognition',
    'marketing-funnel': 'Marketing Funnel',
    'loan-schedule': 'Loan Schedule',
    'rep-ramping': 'Rep Ramping',
  };

  return prebuiltComponentModalState.display === 'options' ? (
    <Modal
      key="options"
      size="xxxs"
      title="Pre-built Components"
      isOpen={prebuiltComponentModalState.isOpen}
      onClose={handleClose}
      showClose
    >
      <PrebuiltOptions setPrebuiltComponentModalState={setPrebuiltComponentModalState} />
    </Modal>
  ) : (
    <Modal
      key={prebuiltComponentModalState.display}
      size="sm"
      title={typeToTitleMap[prebuiltComponentModalState.display]}
      isOpen={prebuiltComponentModalState.isOpen}
      onClose={handleClose}
    >
      {modalContent(prebuiltComponentModalState.display)}
    </Modal>
  );
};

export default PrebuiltComponentsModal;
