import React, { useContext, useEffect, useState } from 'react';
import Typography from '~/components/Typography';
import NewExpenseMappingsTable from './NewExpenseMappingsTable';
import { ExpensesPageContext } from '../../context/ExpensesContext';
import { IIntegrationMapping } from '~/services/parallel/integrations.types';
import Button from '~/components/Button';

const NewExpenseList = (): React.ReactNode => {
  const { expenseIntegrationMappings } = useContext(ExpensesPageContext);
  const [unusedExpenseMappings, setUnusedExpenseMappings] = useState<IIntegrationMapping[]>([]);
  const [showUnusedExpenseMappings, setShowUnusedExpenseMappings] = useState(false);

  useEffect(() => {
    if (expenseIntegrationMappings.length) {
      setUnusedExpenseMappings(
        expenseIntegrationMappings.filter((mapping) => {
          return !mapping.currentlyInUse;
        }),
      );
    }
  }, [expenseIntegrationMappings]);

  return (
    <div className="relative flex flex-col mx-10 my-4 px-10 py-3 bg-green-15 rounded-2xl">
      <div className="absolute top-1 right-0">
        <Button
          fill="clear"
          onClick={() => {
            setShowUnusedExpenseMappings(!showUnusedExpenseMappings);
          }}
        >
          {showUnusedExpenseMappings ? 'Hide' : 'View/Connect'}
        </Button>
      </div>
      <div
        className={`transition-all duration-500 ease-in-out ${
          showUnusedExpenseMappings ? 'max-h-[660px] opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <div className="flex flex-col py-5">
          <Typography size="md" weight="bold">
            Connect Data Sources to Expense
          </Typography>
          <Typography color="secondary">
            Choose to add new expenses to your forecast, merge them with an existing forecasted expense, or exclude it
            from future projections
          </Typography>

          <div className="max-h-[550px] overflow-auto no-scrollbar my-2 rounded-lg border border-neutral-50">
            <NewExpenseMappingsTable unusedExpenseMappings={unusedExpenseMappings} />
          </div>
        </div>
      </div>
      {!showUnusedExpenseMappings && (
        <div>
          <Typography className="!text-green-400">{unusedExpenseMappings.length} Unlinked Data Sources</Typography>
        </div>
      )}
    </div>
  );
};

export default NewExpenseList;
