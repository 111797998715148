import React, { useState } from 'react';
import Button from '~/components/Button';
import PullActualsModal from './PullActualsModal';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const PullAccountingActuals = (): React.ReactElement => {
  const isPulling = useSelector((state: State) => state.integrations.isPulling);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button fill="outline" className="!px-4" onClick={() => setIsModalOpen(true)} loading={isPulling}>
        Pull Actuals
      </Button>
      <PullActualsModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default PullAccountingActuals;
