import { IAPIResponse } from './types';
import store from '~/store';
import { update } from '~/store/scenarioSlice';
import { fetchScenario } from '~/services/parallel/scenarios';

export const handleCreateScenario = async ({ response }: { response: IAPIResponse }): Promise<void> => {
  const dispatch = store.dispatch;
  const scenarioState = store.getState().scenario;
  const organizationUuid = store.getState().organization.uuid;
  const userUuid = store.getState().user.uuid;
  if (scenarioState.activeScenarioUuid) return;
  const scenarioUuidHeader =
    typeof response.headers['scenario-uuid'] === 'string' ? response.headers['scenario-uuid'] : null;
  if (!scenarioUuidHeader) return;

  const scenario = await fetchScenario({
    organizationUuid,
    scenarioUuid: scenarioUuidHeader,
  });

  dispatch(
    update({
      ...scenarioState,
      activeScenarioUuid: scenarioUuidHeader,
      activeScenarioHasChanges: true,
      scenarioLoadingState: 'creating',
      activeScenarioData: {
        type: 'dynamic',
        uuid: scenarioUuidHeader,
        organizationUuid,
        changeDescription: 'Untitled Scenario',
        createdBy: userUuid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        markedAsStaleAt: null,
      },
      scenarios: [scenario, ...scenarioState.scenarios],
    }),
  );
};
